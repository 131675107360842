import React, { useState, useCallback, memo } from 'react'
import { Modal } from 'antd'

import { SearchInfo, DataKey, DataValue } from 'app/types/search'
import SearchRow from './SearchRow'
import './overrideStyle.css'

interface SearchModal {
  visible: boolean
  searchInfo: SearchInfo
  onCancel: Function
  onOk: (searchData: { index: number; itemId: string; value: string }[]) => void
}

const SearchModal = ({ visible, searchInfo, onCancel, onOk }: SearchModal): JSX.Element => {
  const initialState = {
    index: 0,
    itemId: '',
    value: '',
  }
  const [searchData, setSearchData] = useState([initialState])
  const [indexCount, setIndexCount] = useState(0)

  const changeSearchData = useCallback(
    (index: number, key: DataKey, value: DataValue): void =>
      setSearchData(
        searchData.map((item) => {
          if (item.index === index) {
            return {
              ...item,
              [key]: value,
            }
          }
          return item
        }),
      ),
    [searchData],
  )

  const addRow = useCallback((): void => {
    setSearchData([...searchData, { ...initialState, index: indexCount + 1 }])
    setIndexCount(indexCount + 1)
  }, [initialState, searchData, indexCount])

  const deleteRow = useCallback(
    (index: number): void => {
      if (searchData.length === 1) {
        return
      }
      setSearchData(searchData.filter((item) => item.index !== index))
    },
    [searchData],
  )
//2020 09 17どうもボタンの配置が固定らしいのでキャンセルとOKボタンの入替を追加
  return (
    <Modal
      visible={visible}
      onCancel={(): void => {
        onOk(searchData)
        setSearchData([initialState])
        onCancel()
      }}
      onOk={(): void => {
        setSearchData([initialState])
        setIndexCount(0)
        onCancel()
      }}
      title={searchInfo.title}
      cancelText="検索"
      okText="取消"
      okButtonProps={{type:'danger'}}
      cancelButtonProps={{type:'primary'}}
      width={'800px'}
      closable={false}
      maskClosable={false}
      centered
    >
      {searchData.map(
        (item, idx: number): JSX.Element => {
          return (
            <SearchRow
              key={idx}
              rowData={item}
              searchOptions={searchInfo.options}
              changeData={changeSearchData}
              addRow={(): void => addRow()}
              deleteRow={(): void => deleteRow(item.index)}
            />
          )
        },
      )}
    </Modal>
  )
}

export default memo(SearchModal)
