import { Workspace, Project } from './types'

export interface SystemState {
  workspaces_list: Array<Workspace>
  project_list: Array<Project>
  current_workspace_id: string
  current_project_id: string
  loading: boolean
  error: string
}

export const initialState: SystemState = {
  workspaces_list: new Array<Workspace>(),
  project_list: new Array<Project>(),
  current_workspace_id: '',
  current_project_id: '',
  loading: false,
  error: '',
}
