import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Form, Button, Card, Typography, Input } from 'antd'

import { ROUTE_PATH } from 'app/constants/router'

const { Text } = Typography
interface LoginProps {
  userToken: string
  loginRequest: (email: string, password: string) => void
}

const validateMessages = {
  required: '入力必須項目です',
  email: 'メールアドレスの形式に誤りがあります',
}

const LoginPage = (props: LoginProps): JSX.Element => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [, forceUpdate] = useState()

  const onFinish = (values): void => {
    props.loginRequest(values.email, values.password)
  }

  useEffect(() => {
    // To disable submit button at the beginning.
    forceUpdate({})
    // eslint-disable-next-line
  }, [])

  return (
    <div style={{ background: '#F3F3F3' }}>
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Row justify="center">
          <Col span={50}>
            <Card className="loginForm">
              <Row
                justify="center"
                style={{
                  fontSize: '20px',
                  color: '#1589d6',
                  marginTop: '26px',
                  marginBottom: '25px',
                }}
              >
                ログイン
              </Row>
              <Form form={form} onFinish={onFinish} validateMessages={validateMessages} layout={'vertical'}>
                <Form.Item
                  label="メールアドレス"
                  name="email"
                  rules={[
                    { type: 'email', message: 'メールアドレスの入力形式に誤りがあります' },
                    { required: true, message: 'メールアドレスの入力は必須です' },
                  ]}
                >
                  <Input size="large" placeholder="メールアドレス" style={{ width: '400px' }} />
                </Form.Item>
                <Form.Item
                  label="パスワード"
                  name="password"
                  rules={[{ required: true, message: 'パスワードの入力は必須です' }]}
                >
                  <Input.Password size="large" placeholder="パスワード" />
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                  {(): JSX.Element => (
                    <Row justify="center" style={{ marginTop: '15px' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                        style={{ width: '300px' }}
                        disabled={
                          !form.isFieldsTouched(true) ||
                          form.getFieldsError().filter(({ errors }) => errors.length).length
                            ? true
                            : false
                        }
                      >
                        ログイン
                      </Button>
                    </Row>
                  )}
                </Form.Item>
              </Form>
              <Row justify="center" style={{ fontSize: '11px' }}>
                <Button
                  type="link"
                  onClick={(): void => history.push(ROUTE_PATH.FORGOT_PASSWORD)}
                  style={{ width: '300px' }}
                >
                  <Text underline style={{ color: 'gray' }}>
                    パスワードを忘れた方はこちら
                  </Text>
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default LoginPage
