import moment from 'moment'
import { PayloadAction, Action, Observable } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ofType } from 'redux-observable'
import { of, zip, EMPTY } from 'rxjs'
import { catchError, switchMap, mergeMap, concatMap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators'

import { QuotationDetailsActions, UsersSelectors, SystemActions } from '..'

import { HEXA_ID, DATASTORE_ID, DATAREPORTS_ID } from 'app/constants/hexabase'
import {
  QUOTATION_ID_LABEL,
  EMPTY_FLAT_AND_RISE_RESOURCE,
  QUOTATION_FORM_NAME,
} from 'app/constants/components/quotation'

import { getIdTextByDatastoreId, getTargetStateByDatatoreId } from 'app/services/store/utils/quotationDetails'
import { convertItemToChanges, setConstructionVolume } from 'app/utils/components/quotation/quotation'

import * as linkerApi from 'app/services/store/utils/linkerApis'
import * as Types from './types'
import { ROUTE_PATH } from 'app/constants/router'
import HttpService from 'app/services/httpService/httpService'

// 見積DBと工法DBの更新
const updateQuotationAndConstructionMethodEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.updateQuotationAndConstruction),
    switchMap((action: PayloadAction<Types.UpdateQuotationAndConstructionAction>) => {
      const applicationId = HEXA_ID.APPLICATION
      const { datastoreId, tabId, itemId, item } = action.payload
      const changes = convertItemToChanges(item)
      const targetState = getTargetStateByDatatoreId(datastoreId)
      const conditions = [
        {
          id: QUOTATION_ID_LABEL.I_ID,
          search_value: [itemId],
          exact_match: true,
          include_null: false,
        },
      ]

      return linkerApi.itemList({ applicationId, datastoreId, conditions }).pipe(
        switchMap((response) => {
          const revNo = Number(response.data.items[0].rev_no)
          return linkerApi.updateItem({ applicationId, datastoreId, itemId, changes, revNo }).pipe(
            switchMap(() => {
              return linkerApi.itemList({ applicationId, datastoreId, conditions }).pipe(
                switchMap((resp) => {
                  return of(
                    QuotationDetailsActions.updateQuotationAndConstructionSuccess({
                      targetState,
                      tabId,
                      result: resp.data.items[0],
                    }),
                  )
                }),
                catchError((error: string) => {
                  return of(QuotationDetailsActions.updateQuotationAndConstructionFailed({ error }))
                }),
              )
            }),
            catchError((error: string) => {
              return of(QuotationDetailsActions.updateQuotationAndConstructionFailed({ error }))
            }),
          )
        }),
        catchError((error: string) => {
          return of(QuotationDetailsActions.updateQuotationAndConstructionFailed({ error }))
        }),
        catchError((error: string) => {
          return of(QuotationDetailsActions.updateQuotationAndConstructionFailed({ error }))
        }),
      )
    }),
  )

// 見積DB以外のアイテム作成処理
// TODO: rename
const createEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.createRequest),
    switchMap(
      (action: { payload: { datastoreId: string; item: { [key: string]: any }; userId: string; tabId?: string } }) => {
        const applicationId = HEXA_ID.APPLICATION
        const { datastoreId, tabId } = action.payload
        const idText = getIdTextByDatastoreId(datastoreId)
        const targetState = getTargetStateByDatatoreId(datastoreId)
        const item: { [key: string]: any } = {
          ...action.payload.item,
          userId: action.payload.userId,
        }

        return linkerApi
          .createItem({
            applicationId,
            datastoreId,
            item,
          })
          .pipe(
            switchMap((createResponse) => {
              const itemId = createResponse.data.item_id as string
              const condition = {
                id: QUOTATION_ID_LABEL.I_ID,
                search_value: [itemId],
                exact_match: true,
                include_null: false,
              }
              return linkerApi.itemList({ applicationId, datastoreId, conditions: [condition] }).pipe(
                switchMap((listResponse) => {
                  const items = listResponse.data.items[0]
                  const id = idText.slice(0, -4)
                  const changes = [
                    {
                      id: idText,
                      value: listResponse.data.items[0][id],
                    },
                  ]
                  return linkerApi
                    .updateItem({
                      applicationId,
                      datastoreId,
                      itemId,
                      changes,
                    })
                    .pipe(
                      switchMap(() => {
                        return of(QuotationDetailsActions.createSuccess({ items, targetState, tabId }))
                      }),
                      catchError((error: string) => {
                        return of(QuotationDetailsActions.createFailed({ error }))
                      }),
                    )
                }),
                catchError((error: string) => {
                  return of(QuotationDetailsActions.createFailed({ error }))
                }),
              )
            }),
            catchError((error: string) => {
              return of(QuotationDetailsActions.createFailed({ error }))
            }),
          )
      },
    ),
  )

// 見積DBへのアイテム作成処理
const createQuotationEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.createQuotation),
    switchMap((action: { payload: { item: { [key: string]: any } } }) => {
      const applicationId = HEXA_ID.APPLICATION
      const userId = UsersSelectors.getUserId(state$.value)

      const datastoreId = DATASTORE_ID.EMPLOYEE
      const myMailAddress = UsersSelectors.getUserEmail(state$.value)
      const UserResult = UsersSelectors.getUserData(myMailAddress)
      let logeddata = {}
      UserResult.then(function(result_data) {
        logeddata["name"] = result_data["employeeName"]
        logeddata["tel"] = result_data["employeeTel"]
        logeddata["fax"] = result_data["employeeFax"]
        logeddata["deps"] = result_data["employeeDepartment"]
        logeddata["address"] = result_data["employeeDepartmentAddress"]
      }, function(e) {
        // not called
      });
      const conditions = [
        {
          id: 'employeeMail',
          search_value: [myMailAddress],
          exact_match: true,
          include_null: false,
        },
      ]
      return linkerApi.itemList({ applicationId, datastoreId, conditions }).pipe(
        switchMap((searchResp) => {
          const employeeCode = searchResp.data.items[0]['employeeCode']
          const today = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`
          const branchKey = `${employeeCode}_${today}_`
          const datastoreId = DATASTORE_ID.QUOTATION
          const fieldId = QUOTATION_FORM_NAME.QUOTATION_ID
          return linkerApi.getAutoNumber({ datastoreId, fieldId, branchKey, zeroPadding: true, digit: 2 }).pipe(
            switchMap((resp) => {
              console.log("resp")
              console.log(resp)
              const item: { [key: string]: any } = {
                ...action.payload.item,
                userId,
                quotationNum: resp.data.result.value,
                personInChargeName: logeddata["name"],
                departmentName: logeddata["deps"],
                departmentAddress: logeddata["address"],
                departmentTell: logeddata["tel"],
                departmentFax: logeddata["fax"],
              }
              return linkerApi
                .createItem({
                  applicationId,
                  datastoreId: DATASTORE_ID.QUOTATION,
                  item,
                })
                .pipe(
                  switchMap((createResponse) => {
                    const itemId = createResponse.data.item_id as string
                    const condition = {
                      id: QUOTATION_ID_LABEL.I_ID,
                      search_value: [itemId],
                      exact_match: true,
                      include_null: false,
                    }
                    return linkerApi.itemList({ applicationId, datastoreId, conditions: [condition] }).pipe(
                      switchMap((listResponse) => {
                        const items = listResponse.data.items[0]
                        const changes = [
                          {
                            id: QUOTATION_FORM_NAME.QUOTATION_ID_TEXT,
                            value: listResponse.data.items[0][QUOTATION_FORM_NAME.QUOTATION_ID],
                          },
                        ]
                        return linkerApi
                          .updateItem({
                            applicationId,
                            datastoreId,
                            itemId,
                            changes,
                          })
                          .pipe(
                            switchMap(() => {
                              return of(QuotationDetailsActions.createQuotationSuccess({ items }))
                            }),
                            catchError((error: string) => {
                              return of(QuotationDetailsActions.createQuotationFailed({ error }))
                            }),
                          )
                      }),
                      catchError((error: string) => {
                        return of(QuotationDetailsActions.createQuotationFailed({ error }))
                      }),
                    )
                  }),
                  catchError((error: string) => {
                    return of(QuotationDetailsActions.createQuotationFailed({ error }))
                  }),
                )
            }),
            catchError((error: string) => {
              return of(QuotationDetailsActions.createQuotationFailed({ error }))
            }),
          )
        }),
        catchError((error: string) => {
          return of(QuotationDetailsActions.createQuotationFailed({ error }))
        }),
      )
    }),
  )

// 工法DBのアイテム削除（関連DBも併せて削除）
const deleteConstructionMethodFromDBEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.deleteConstructionMethodFromDB),
    switchMap((action: { payload: { datastoreId: string; itemId: string; tabId: string } }) => {
      const datastoreId = action.payload.datastoreId
      const itemId = action.payload.itemId
      const tabId = action.payload.tabId

      return linkerApi
        .deleteItem({
          datastoreId,
          itemId,
          deleteLinkedItems: true,
          targetDatastores: [DATASTORE_ID.FLAT, DATASTORE_ID.RISE, DATASTORE_ID.ELSE],
        })
        .pipe(
          switchMap(() => {
            return of(QuotationDetailsActions.deleteConstructionMethodFromDBSuccess({ tabId }))
          }),
          catchError((error: string) => {
            return of(QuotationDetailsActions.deleteConstructionMethodFromDBFailed({ error }))
          }),
        )
    }),
  )

const getFlatRiseMasterDataEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.getFlatRiseMasterData),
    switchMap(
      (action: {
        payload: {
          constructionMethod: string
          specificationCodeFlat: string
          specificationCodeRise: string
          constructionVolumeFlat: number
          constructionVolumeRise: number
          system: string
          tabId: string
        }
      }) => {
        const applicationId = HEXA_ID.APPLICATION
        const datastoreId = DATASTORE_ID.CONSTRUCTION_METHOD_MASTER

        const {
          constructionMethod,
          specificationCodeFlat,
          specificationCodeRise,
          constructionVolumeFlat,
          constructionVolumeRise,
          system,
          tabId,
        } = action.payload
        const conditions = [
          {
            id: QUOTATION_ID_LABEL.SPECIFICATION_CODE,
            search_value: [
              specificationCodeFlat ? specificationCodeFlat : '',
              specificationCodeRise ? specificationCodeRise : '',
            ],
            exact_match: true,
            include_null: false,
          },
          {
            id: QUOTATION_ID_LABEL.CONSTRUCTION_METHOD,
            search_value: [constructionMethod ? constructionMethod : ''],
            exact_match: true,
            include_null: false,
          },
          {
            id: QUOTATION_ID_LABEL.SYSTEM,
            search_value: [system ? system : ''],
            exact_match: true,
            include_null: false,
          },
        ]

        return linkerApi.itemList({ applicationId, datastoreId, conditions }).pipe(
          switchMap((response) => {
            const flatItems: any = []
            const riseItems: any = []

            response.data.items.forEach((item) => {
              if (item['tabType'] === '平場') {
                flatItems.push({ ...EMPTY_FLAT_AND_RISE_RESOURCE, ...item })
              } else if (item['tabType'] === '立上り') {
                riseItems.push({ ...EMPTY_FLAT_AND_RISE_RESOURCE, ...item })
              }
            })

            const flat = constructionVolumeFlat ? setConstructionVolume(flatItems, constructionVolumeFlat) : flatItems
            const rise = constructionVolumeRise ? setConstructionVolume(riseItems, constructionVolumeRise) : riseItems
            return of(QuotationDetailsActions.getFlatRiseMasterDataSuccess({ flat, rise, tabId, isSaved: false }))
          }),
          catchError((error: string) => {
            return of(QuotationDetailsActions.getFlatRiseMasterDataFailed({ error }))
          }),
        )
      },
    ),
  )

const customerSearchEpic = (action$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.customerSearch),
    map((payload) => payload),
    debounceTime(400),
    distinctUntilChanged(),
    switchMap((payload: { payload: { customerName } }) => {
      const customerName = payload.payload.customerName

      const applicationId = HEXA_ID.APPLICATION
      const datastoreId = DATASTORE_ID.CUSTOMER_AND_PERSONINCHARGE_MASTER
      const conditions = [
        {
          id: QUOTATION_FORM_NAME.CUSTOMER_NAME,
          search_value: [customerName as string],
          exact_match: false,
          include_null: false,
        },
      ]
      return linkerApi.itemList({ applicationId, datastoreId, conditions }).pipe(
        switchMap((response) => {
          const customers = response.data.items.map((item) => {
            return {
              [QUOTATION_FORM_NAME.CUSTOMER_CODE]: item[QUOTATION_FORM_NAME.CUSTOMER_CODE],
              [QUOTATION_FORM_NAME.CUSTOMER_NAME]: item[QUOTATION_FORM_NAME.CUSTOMER_NAME],
              [QUOTATION_FORM_NAME.CUSTOMER_MAIL]: item[QUOTATION_FORM_NAME.CUSTOMER_MAIL],
            }
          })
          return of(
            QuotationDetailsActions.customerSearchSuccess({
              customerSearchResult: customers as Types.CustomerSearchResult[],
            }),
          )
        }),
        catchError((error: string) => {
          return of(QuotationDetailsActions.customerSearchFailed({ error }))
        }),
      )
    }),
    catchError((error: string) => {
      return of(QuotationDetailsActions.customerSearchFailed({ error }))
    }),
  )

const genQuotationNumberEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.genQuotationNumber),
    switchMap(() => {
      const applicationId = HEXA_ID.APPLICATION
      const datastoreId = DATASTORE_ID.EMPLOYEE
      const myMailAddress = UsersSelectors.getUserEmail(state$.value)
      const conditions = [
        {
          id: 'employeeMail',
          search_value: [myMailAddress],
          exact_match: true,
          include_null: false,
        },
      ]
      return linkerApi.itemList({ applicationId, datastoreId, conditions }).pipe(
        switchMap((searchResp) => {
          const employeeCode = searchResp.data.items[0]['employeeCode']
          const today = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`
          const branchKey = `${employeeCode}_${today}_`
          const datastoreId = DATASTORE_ID.QUOTATION
          const fieldId = QUOTATION_FORM_NAME.QUOTATION_ID
          return linkerApi.getAutoNumber({ datastoreId, fieldId, branchKey, zeroPadding: true, digit: 2 }).pipe(
            switchMap((resp) => {
              return of(QuotationDetailsActions.genQuotationNumberSuccess({ quotationNumber: resp.data.result.value }))
            }),
            catchError((error: string) => {
              return of(QuotationDetailsActions.genQuitationNumberFailed({ error }))
            }),
          )
        }),
        catchError((error: string) => {
          return of(QuotationDetailsActions.genQuitationNumberFailed({ error }))
        }),
      )
    }),
    catchError((error: string) => {
      return of(QuotationDetailsActions.genQuitationNumberFailed({ error }))
    }),
  )

// 見積削除（関連するDBのアイテムも一括削除）
const deleteQuotationEpic = (action$, state$) =>
  action$.pipe(
    ofType(QuotationDetailsActions.deleteQuotation),
    switchMap((action: PayloadAction<Types.QuotationDeleteAction>) => {
      const { itemId } = action.payload

      return linkerApi
        .deleteItem({
          itemId,
          datastoreId: DATASTORE_ID.QUOTATION,
          deleteLinkedItems: true,
          targetDatastores: [DATASTORE_ID.CONSTRUCTION_METHOD, DATASTORE_ID.FLAT, DATASTORE_ID.RISE, DATASTORE_ID.ELSE],
        })
        .pipe(
          switchMap(() => {
            return of(
              QuotationDetailsActions.deleteQuotationSuccess({ itemId }),
              SystemActions.navigate({ path: ROUTE_PATH.QUOTATION_LIST }),
            )
          }),
          catchError((error: string) => {
            return of(QuotationDetailsActions.deleteQuotationFailed({ error }))
          }),
        )
    }),
  )

const getLowestPriceEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.getLowestPriceRequest),
    mergeMap((action: PayloadAction<Types.GetLowestPriceRequest>) => {
      const { target, reportId, conditions, type } = action.payload
      return linkerApi.getReportDataByConditions({ reportId, conditions }).pipe(
        concatMap((response) => {
          let datareportResult = response.data
          if (datareportResult && datareportResult.report_results.length > 1) {
            const lowerResult = datareportResult.report_results.reduce((a, b) =>
              a['purchase'] === 0 ? b : a['purchase'] < b['purchase'] ? a : b,
            )
            datareportResult = {
              ...datareportResult,
              report_results: [lowerResult],
            }
          }

          const dsDeterminer = () => {
            if (type === '平場') {
              return DATASTORE_ID.FLAT
            }
            if (type === '立上り') {
              return DATASTORE_ID.RISE
            }
            return DATASTORE_ID.ELSE
          }
          return linkerApi
            .itemList({
              applicationId: HEXA_ID.APPLICATION,
              datastoreId: dsDeterminer(),
              conditions: [
                {
                  id: 'constIdText',
                  search_value: [datareportResult.report_results[0].constIdText],
                  exact_match: true,
                  include_null: false,
                },
                {
                  id: 'productCode',
                  search_value: [datareportResult.report_results[0].productCode],
                  exact_match: true,
                  include_null: false,
                },
                {
                  id: 'purchase',
                  search_value: ['"1",null'],
                  include_null: false,
                },
                {
                  id: 'sales',
                  search_value: ['"1",null'],
                  include_null: false,
                },
              ],
            })
            .pipe(
              concatMap((searchResponse) => {
                const resp = searchResponse.data.items
                const sales = resp.filter(
                  (item) => Number(item.purchase) === Number(datareportResult.report_results[0].purchase),
                )

                datareportResult.report_results.forEach((item) => {
                  delete item.items
                  item.sales = sales[0] ? sales[0].sales : undefined
                })
                return of(QuotationDetailsActions.getLowestPriceSuccess({ target, datareportResult }))
              }),
            )
        }),
        catchError((error: AxiosError) => of(QuotationDetailsActions.getLowestPriceFailed({ error: error.message }))),
      )
    }),
    catchError((error: AxiosError) => of(QuotationDetailsActions.getLowestPriceFailed({ error: error.message }))),
  )

  const getLowestSalesEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.getLowestSalesRequest),
    mergeMap((action: PayloadAction<Types.GetLowestSalesRequest>) => {
      const { target, reportId, conditions, type } = action.payload
      return linkerApi.getReportDataByConditions({ reportId, conditions }).pipe(
        concatMap((response) => {
          let datareportResult = response.data
          if (datareportResult && datareportResult.report_results.length > 1) {
            const lowerResult = datareportResult.report_results.reduce((a, b) =>
              a['sales'] === 0 || a['sales'] === undefined ? b : a['sales'] < b['sales'] ? a : b,
            )
            datareportResult = {
              ...datareportResult,
              report_results: [lowerResult],
            }
          }

          const dsDeterminer = () => {
            if (type === '平場') {
              return DATASTORE_ID.FLAT
            }
            if (type === '立上り') {
              return DATASTORE_ID.RISE
            }
            return DATASTORE_ID.ELSE
          }
          return linkerApi
            .itemList({
              applicationId: HEXA_ID.APPLICATION,
              datastoreId: dsDeterminer(),
              conditions: [
                {
                  id: 'constIdText',
                  search_value: [datareportResult.report_results[0].constIdText],
                  exact_match: true,
                  include_null: false,
                },
                {
                  id: 'productCode',
                  search_value: [datareportResult.report_results[0].productCode],
                  exact_match: true,
                  include_null: false,
                },
                {
                  id: 'purchase',
                  search_value: ['"1" , null'],
                  include_null: false,
                },
                {
                  id: 'sales',
                  search_value: ['"1" , null'],
                  include_null: false,
                },
              ],
            })
            .pipe(
              concatMap((searchResponse) => {
                const resp = searchResponse.data.items
                const sales = resp.filter(
                  (item) => Number(item.purchase) === Number(datareportResult.report_results[0].purchase),
                )

                datareportResult.report_results.forEach((item) => {
                  delete item.items
                  item.sales = sales[0] ? sales[0].sales : undefined
                })
                return of(QuotationDetailsActions.getLowestSalesSuccess({ target, datareportResult }))
              }),
            )
        }),
        catchError((error: AxiosError) => of(QuotationDetailsActions.getLowestSalesFailed({ error: error.message }))),
      )
    }),
    catchError((error: AxiosError) => of(QuotationDetailsActions.getLowestSalesFailed({ error: error.message }))),
  )



// TODO: リネーム
// 工法・平場・立上り・その他の新規保存・更新処理
const saveFlatRiseElse = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.saveFlatRiseElse),
    switchMap((action: PayloadAction<Types.SaveFlatRizeElseAction>) => {
      const { tabId, relatedDsItems, constIdText, itemId, item } = action.payload
      return linkerApi
        .updateItem({
          applicationId: HEXA_ID.APPLICATION,
          datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD,
          item,
          itemId,
          relatedDsItems,
        })
        .pipe(
          switchMap(() => {
            return zip(
              linkerApi.itemList({
                applicationId: HEXA_ID.APPLICATION,
                datastoreId: DATASTORE_ID.FLAT,
                conditions: [
                  {
                    id: 'constIdText',
                    search_value: [constIdText],
                    exact_match: true,
                    include_null: false,
                  },
                ],
              }),
              linkerApi.itemList({
                applicationId: HEXA_ID.APPLICATION,
                datastoreId: DATASTORE_ID.RISE,
                conditions: [
                  {
                    id: 'constIdText',
                    search_value: [constIdText],
                    exact_match: true,
                    include_null: false,
                  },
                ],
              }),
              linkerApi.itemList({
                applicationId: HEXA_ID.APPLICATION,
                datastoreId: DATASTORE_ID.ELSE,
                conditions: [
                  {
                    id: 'constIdText',
                    search_value: [constIdText],
                    exact_match: true,
                    include_null: false,
                  },
                ],
              }),
              linkerApi.itemList({
                applicationId: HEXA_ID.APPLICATION,
                datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD,
                conditions: [
                  {
                    id: 'constIdText',
                    search_value: [constIdText],
                    exact_match: true,
                    include_null: false,
                  },
                ],
              }),
            ).pipe(
              switchMap((listResps) => {
                const flatItems = (listResps[0].data.items as unknown) as Types.FlatItem[]
                const riseItems = (listResps[1].data.items as unknown) as Types.RiseItem[]
                const elseItems = (listResps[2].data.items as unknown) as Types.ElseItem[]
                const constMethod = (listResps[3].data.items as unknown) as Types.ConstructionMethod['items']
                return of(
                  QuotationDetailsActions.saveFlatRiseElseSuccess({
                    tabId,
                    flatItems: flatItems as Types.FlatItem[],
                    riseItems: riseItems as Types.RiseItem[],
                    elseItems: elseItems as Types.ElseItem[],
                    constMethod: constMethod[0] as Types.ConstructionMethod['items'],
                  }),
                  // TODO: 型の不具合解消とerrorHandling
                )
              }),
              catchError((error: AxiosError) =>
                of(QuotationDetailsActions.saveFlatRiseElseFailed({ error: error.message })),
              ),
            )
          }),
          catchError((error: AxiosError) =>
            of(QuotationDetailsActions.saveFlatRiseElseFailed({ error: error.message })),
          ),
        )
    }),
  )

  const saveOneItem = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.saveOneItem),
    concatMap((action: PayloadAction<Types.SaveOneItemAction>) => {
      const { datastoreId, targetIid, savedValues } = action.payload
      const applicationId = HEXA_ID.APPLICATION
      let changes = convertItemToChanges(savedValues)
      console.log("saveoneitem_changes")
      console.log(changes)
      return linkerApi.updateItem({ 
        applicationId: applicationId,
          datastoreId: datastoreId,
          itemId: targetIid,
          changes: changes,
        }
      ).pipe(
        concatMap((listResps) => {
          console.log("savetrue")
          console.log(listResps)
          return of(QuotationDetailsActions.saveOneItemSuccess({ datastoreId, targetIid, savedValues }))
        }),
        catchError((error: string) => {
          console.log("savefalse")
          return of(QuotationDetailsActions.saveOneItemFailed({ error: error }))
        })
      )
    }),catchError((error: AxiosError) => of(QuotationDetailsActions.saveOneItemFailed({ error: error.message })))
  )

  const saveAddItem = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.saveAddItem),
    concatMap((action: PayloadAction<Types.SaveAddItemAction>) => {
      const { datastoreId,  insertValues } = action.payload
      const applicationId = HEXA_ID.APPLICATION
      const item: { [key: string]: any } = {
        ...action.payload.insertValues,
      }

      return linkerApi.createItem({ 
          applicationId: applicationId,
          datastoreId: datastoreId,
          item: item,
        }
      ).pipe(
        concatMap((listResps) => {
          console.log("saveadd")
          console.log(listResps)
          return of(QuotationDetailsActions.saveAddItemSuccess({ datastoreId,  insertValues }))
        }),
        catchError((error: string) => {
          console.log("savefalse")
          return of(QuotationDetailsActions.saveAddItemFailed({ error: error }))
        })
      )
    }),catchError((error: AxiosError) => of(QuotationDetailsActions.saveAddItemFailed({ error: error.message })))
  )



  const savelowestpriceFlatRiseElse = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.savelowestpriceFlatRiseElse),
    concatMap((action: PayloadAction<Types.SavelowestpriceFlatRizeElseAction>) => {
      const { productCode,conditions,lowestSales,lowestPurchase, updatedValues } = action.payload
      const applicationId = HEXA_ID.APPLICATION
      let changes = convertItemToChanges(updatedValues)
      return zip(
        linkerApi.bulkUpdateItem({
          applicationId: applicationId,
          datastoreId: DATASTORE_ID.FLAT,
          actionId: "Flatmenu",
          conditions: conditions,
          changes: changes,
        }),
        linkerApi.bulkUpdateItem({
          applicationId: applicationId,
          datastoreId: DATASTORE_ID.RISE,
          actionId: "Risemenu",
          conditions: conditions,
          changes: changes,
        }),
        linkerApi.bulkUpdateItem({
          applicationId: applicationId,
          datastoreId: DATASTORE_ID.ELSE,
          actionId: "Elsemenu",
          conditions: conditions,
          changes: changes,
        }),
      ).pipe(
        concatMap((listResps) => {
          return of(QuotationDetailsActions.savelowestpriceFlatRiseElseSuccess({ productCode,conditions,lowestSales,lowestPurchase, updatedValues }))
        }),
        catchError((error: string) => {
          return of(QuotationDetailsActions.savelowestpriceFlatRiseElseFailed({ error: error }))
        })
      )
    }),catchError((error: AxiosError) => of(QuotationDetailsActions.savelowestpriceFlatRiseElseFailed({ error: error.message })))
  )


const executeActionEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.executeAction),
    switchMap((action: PayloadAction<{ itemId: string; datastoreId: string; actionId: string }>) => {
      const { datastoreId, itemId, actionId } = action.payload
      return linkerApi.executeAction({ datastoreId, actionId, itemId }).pipe(
        switchMap(() => {
          return of(QuotationDetailsActions.executeActionSuccess({ actionId }))
        }),
        catchError((error: AxiosError) => of(QuotationDetailsActions.executeActionFailed({ error: error.message }))),
      )
    }),
  )

// 工法DBのフィールドを取得
const getFields = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.getConstructionMethodFields),
    switchMap((action: PayloadAction<{ datastoreId: string }>) => {
      const { datastoreId } = action.payload
      return linkerApi.getDatastoreFields(datastoreId).pipe(
        switchMap((resp) => {
          return of(QuotationDetailsActions.getConstructionMethodFieldsSuccess({ fields: resp.data.fields }))
        }),
        catchError((error: AxiosError) =>
          of(QuotationDetailsActions.getConstructionMethodFieldsFailed({ error: error.message })),
        ),
      )
    }),
  )

// 見積編集画面の初期値を生成
const getInitStateForEditEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.genInitStateForEditContainer),
    switchMap((action: PayloadAction<{ itemId: string }>) => {
      const itemId = action.payload.itemId
      return linkerApi
        .itemList({
          applicationId: HEXA_ID.APPLICATION,
          datastoreId: DATASTORE_ID.QUOTATION,
          conditions: [
            {
              id: QUOTATION_ID_LABEL.I_ID,
              search_value: [itemId],
              exact_match: true,
              include_null: false,
            },
          ],
        })
        .pipe(
          switchMap((quotationResp) => {
            const quotation = quotationResp.data.items[0]
            return linkerApi
              .itemList({
                applicationId: HEXA_ID.APPLICATION,
                datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD,
                conditions: [
                  {
                    id: 'quotationIdText', // TODO: constants利用
                    search_value: [quotation['quotationIdText']],
                    exact_match: true,
                    include_null: false,
                  },
                ],
              })
              .pipe(
                switchMap((constResp) => {
                  const constructionMethodItems = constResp.data.items
                  return of(
                    QuotationDetailsActions.genInitStateForEditContainerSuccess({
                      quotation,
                      constructionMethodItems,
                    }),
                  )
                }),
                catchError((error: AxiosError) =>
                  of(QuotationDetailsActions.genInitStateForEditContainerFailed({ error: error.message })),
                ),
              )
          }),
          catchError((error: AxiosError) =>
            of(QuotationDetailsActions.genInitStateForEditContainerFailed({ error: error.message })),
          ),
        )
    }),
  )

// 工法タブをクリックした際に、平場・工法・その他タブの情報を取得する
const getFlatRiseElseEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.getFlatRiseElse),
    mergeMap((action: PayloadAction<Types.GetFlatRiseElseAction>) => {
      const { tabId, constIdText } = action.payload
      const applicationId = HEXA_ID.APPLICATION
      return zip(
        linkerApi.itemList({
          applicationId,
          datastoreId: DATASTORE_ID.FLAT,
          conditions: [
            {
              id: 'constIdText', // TODO: constants利用
              search_value: [constIdText],
              exact_match: true,
              include_null: false,
            },
          ],
        }),
        linkerApi.itemList({
          applicationId,
          datastoreId: DATASTORE_ID.RISE,
          conditions: [
            {
              id: 'constIdText', // TODO: constants利用
              search_value: [constIdText],
              exact_match: true,
              include_null: false,
            },
          ],
        }),
        linkerApi.itemList({
          applicationId,
          datastoreId: DATASTORE_ID.ELSE,
          conditions: [
            {
              id: 'constIdText', // TODO: constants利用
              search_value: [constIdText],
              exact_match: true,
              include_null: false,
            },
          ],
        }),
      ).pipe(
        switchMap((resp) => {
          const flatItems = (resp[0].data.items as unknown) as Types.FlatItem[]
          const riseItems = (resp[1].data.items as unknown) as Types.RiseItem[]
          const elseItems = (resp[2].data.items as unknown) as Types.ElseItem[]
          return of(QuotationDetailsActions.getFlatRiseElseSuccess({ tabId, flatItems, riseItems, elseItems }))
        }),
        catchError((error: AxiosError) => of(QuotationDetailsActions.getFlatRiseElseFailed({ error: error.message }))),
      )
    }),
  )

  const downloadPDFEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.downloadPdf),
    switchMap((action: PayloadAction<{ itemId: string }>) => {
      const { itemId } = action.payload
      return HttpService.PostAsync<{ i_id: string }, any>('', { i_id: itemId }, 'generatepdf', {
        responseType: 'arraybuffer',
      }).pipe(
        map((resp) => {
           const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }))
          link.download = `file_svf_${moment().format('YYYYMMDDhhmmss')}`
          link.click()
          document.body.removeChild(link)

          return QuotationDetailsActions.downloadPdfSuccess()
        }),
        catchError((error: AxiosError) => of(QuotationDetailsActions.downloadPdfFailed({ error: error.message }))),
      )
    }),
  )

  function valtoarray(record, username, usertel, userfax, userdeps, useraddress,record_type){
    let tmp_array  = {}
    tmp_array['quotationId']=record.quotationId
    tmp_array['quotationIdText']=record.quotationIdText
    tmp_array['reports_1']=record.reports_1
    tmp_array['reports_2']=record.reports_2
    tmp_array['reports_3']=record.reports_3
    tmp_array['reports_4']=record.reports_4
    tmp_array['reports_5']=record.reports_5
    tmp_array['reports_6']=record.reports_6
    tmp_array['reports_7']=record.reports_7
    tmp_array['reports_8']=record.reports_8
    tmp_array['reports_9']=record.reports_9
    tmp_array['reports_10']=record.reports_10
    tmp_array['reports_11']=record.reports_11
    tmp_array['reports_12']=record.reports_12
    tmp_array['reports_13']=record.reports_13
    tmp_array['reports_14']=record.reports_14
    tmp_array['reports_15']=record.reports_15
    tmp_array['reports_16']=record.reports_16
    tmp_array['reports_17']=record.reports_17
    tmp_array['reports_18']=record.reports_18
    /*
    tmp_array['reports_14']=useraddress
    tmp_array['reports_15']=userdeps
    tmp_array['reports_16']=username
    tmp_array['reports_17']=usertel
    tmp_array['reports_18']=userfax
    */
    tmp_array['reports_14']=useraddress
    tmp_array['reports_15']=userdeps
    tmp_array['reports_16']=username
    tmp_array['reports_17']=usertel
    tmp_array['reports_18']=userfax
    tmp_array['reports_19']=record.reports_19
    tmp_array['reports_20']=record.reports_20
    tmp_array['reports_21']=record.reports_21
    tmp_array['reports_22']=record.reports_22
    tmp_array['reports_23']=record.reports_23
    tmp_array['reports_24']=record.reports_24
    tmp_array['reports_25']=record.reports_25
    tmp_array['reports_26']=record.reports_26
    tmp_array['reports_27']=record.reports_27
    tmp_array['reports_28']=record.reports_28
    tmp_array['reports_29']=record.reports_29
    tmp_array['reports_30']=record.reports_30
    tmp_array['reports_31']=record.reports_31
    tmp_array['reports_32']=record.reports_32
    tmp_array['reports_33']=record.reports_33
    tmp_array['reports_34']=record.reports_34
    tmp_array['reports_35']=record.reports_35
    tmp_array['reports_36']=record.reports_36
    tmp_array['reports_37']=record.reports_37
    tmp_array['reports_38']=record.reports_38
    tmp_array['reports_39']=record.reports_39
    tmp_array['reports_40']=record.reports_40
    tmp_array['reports_41']=record.reports_41
    tmp_array['reports_42']=record.reports_42
    tmp_array['reports_43']=record.reports_43
    tmp_array['reports_44']=record.reports_44
    tmp_array['reports_45']=record.reports_45
    tmp_array['reports_46']=record.reports_46
    tmp_array['reports_47']=record.reports_47
    tmp_array['reports_48']=record.reports_48
    tmp_array['reports_49']=record.reports_49
    tmp_array['reports_50']=record.reports_50
    tmp_array['reports_51']=record.reports_51
    tmp_array['reports_52']=record.reports_52
    tmp_array['reports_53']=record.reports_53
    tmp_array['reports_54']=record.reports_54
    tmp_array['reports_55']=record.reports_55    
    tmp_array['reports_56']=record_type
    return tmp_array
  }

  //CSVダウンロード用
  const downloadCSVRequestEpic = (action$,state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.downloadCsvRequest),
    mergeMap((action: PayloadAction<{ itemId: string }>) => {
      const {itemId} = action.payload
      const userMail = UsersSelectors.getUserEmail(state$.value)
      console.log("userMail")
      console.log(state$)
      console.log(userMail)
      const UserResult = UsersSelectors.getUserData(userMail)
      let logeddata = {}
      UserResult.then(function(result_data) {
        logeddata["name"] = result_data["employeeName"]
        logeddata["tel"] = result_data["employeeTel"]
        logeddata["fax"] = result_data["employeeFax"]
        logeddata["deps"] = result_data["employeeDepartment"]
        logeddata["address"] = result_data["employeeDepartmentAddress"]
      }, function(e) {
        // not called
      });

      return linkerApi
        .itemList({
          applicationId: HEXA_ID.APPLICATION,
          datastoreId: DATASTORE_ID.QUOTATION,
          conditions: [
            {
              id: QUOTATION_ID_LABEL.I_ID,
              search_value: [itemId],
              exact_match: true,
              include_null: false,
            },
          ],
        })
        .pipe(
          switchMap((quotationResp) => {
            const quotation = quotationResp.data.items[0]
            const conditions = [
            {
              id: 'quotationIdText',
              search_value: [quotation.quotationIdText],
              exact_match: false,
            },
            ]
            console.log("debug0")
      return linkerApi.getReportDataByConditions({ reportId:DATAREPORTS_ID.REPORT_CSV_FLAT, conditions: conditions }).pipe(
        concatMap((response) => {
          
          let datareportResult = response.data
          const csvdata = datareportResult
          const csvdata_title = datareportResult.report_fields
          const csvdata_results = datareportResult.report_results
          let csv=""
          let field_name=""
          let csv_array_title : any = {}
          let csv_array : any = {}
          let csv_array_data : any = []

          {Object.values(csvdata_title).map(key => (
            field_name=key.display_id,
            csv_array_title[field_name]=key.title
          ))}

          csv_array_data.push(csv_array_title)
          {Object.values(csvdata_results).map(val => (
            csv_array = valtoarray(val,logeddata["name"], logeddata["tel"], logeddata["fax"], logeddata["deps"],logeddata["address"],"平場"),
            csv_array_data.push(csv_array)
          ))}

          return linkerApi.getReportDataByConditions({ reportId:DATAREPORTS_ID.REPORT_CSV_RISE, conditions: conditions }).pipe(
            concatMap((response) => {
              let datareportResult = response.data
              const csvdata_rise_results = datareportResult.report_results

              {Object.values(csvdata_rise_results).map(val => (
                csv_array = valtoarray(val,logeddata["name"], logeddata["tel"], logeddata["fax"], logeddata["deps"],logeddata["address"],"立上"),
                csv_array_data.push(csv_array)
              ))}
                
              return linkerApi.getReportDataByConditions({ reportId:DATAREPORTS_ID.REPORT_CSV_ELSE, conditions: conditions }).pipe(
                concatMap((response) => {
                  let datareportResult = response.data
                  const csvdata_else_results = datareportResult.report_results
    
                  {Object.values(csvdata_else_results).map(val => (
                    csv_array = valtoarray(val,logeddata["name"], logeddata["tel"], logeddata["fax"], logeddata["deps"],logeddata["address"],"その他"),
                    csv_array_data.push(csv_array)
                  ))}
        
                  csv_array_data.forEach(function (key) {
                    Object.keys(key).forEach(function (key2) {
                      csv+='"'+key[key2]+'",'
                    })
                    csv=csv.slice(0,-1)
                    csv+="\r\n"
                  })
        
                  csv = csv.replace(/undefined/g,'')
                  let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
                  let blob = new Blob([bom, csv], {type: 'text/csv'});
        
                  const link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.download = `file_csv_${moment().format('YYYYMMDDhhmmss')}.csv`
                  link.click()
                  document.body.removeChild(link)
        
                  return of(QuotationDetailsActions.downloadCsvSuccess( {csvdata} ))
            
                }),
                catchError((error: AxiosError) => of(QuotationDetailsActions.downloadCsvFailed({ error: error.message }))),
              )      
    
            }),
            catchError((error: AxiosError) => of(QuotationDetailsActions.downloadCsvFailed({ error: error.message }))),
          )      
        }),
        catchError((error: AxiosError) => of(QuotationDetailsActions.downloadCsvFailed({ error: error.message }))),
      )
    }),
    catchError((error: AxiosError) => of(QuotationDetailsActions.downloadCsvFailed({ error: error.message }))),
    )
    }),
  )


const deleteElseRowEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(QuotationDetailsActions.deleteElseRow),
    switchMap((action: PayloadAction<{ tabId: string; iId: string; isSaved: boolean }>) => {
      const { tabId, iId, isSaved } = action.payload
      if (isSaved) {
        const res = linkerApi.deleteItem({ datastoreId: DATASTORE_ID.ELSE, itemId: iId })
        return res.pipe(
          switchMap(() => {
            return of(QuotationDetailsActions.deleteElseRowSuccess({ tabId, iId }))
          }),
        )
      } else {
        return of(QuotationDetailsActions.deleteElseRowSuccess({ tabId, iId }))
      }
    }),
    catchError((error: AxiosError) => of(QuotationDetailsActions.deleteElseRowFailed({ error: error.message }))),
  )

export default [
  updateQuotationAndConstructionMethodEpic,
  createEpic,
  deleteConstructionMethodFromDBEpic,
  createQuotationEpic,
  deleteQuotationEpic,
  getFlatRiseMasterDataEpic,
  getFlatRiseElseEpic,
  customerSearchEpic,
  genQuotationNumberEpic,
  getLowestPriceEpic,
  getLowestSalesEpic,
  saveFlatRiseElse,
  saveOneItem,
  saveAddItem,
  savelowestpriceFlatRiseElse,
  executeActionEpic,
  getFields,
  getInitStateForEditEpic,
  downloadPDFEpic,
  downloadCSVRequestEpic,
  deleteElseRowEpic,
]
