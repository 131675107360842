import { AxiosError } from 'axios'
import { Observable } from 'redux'
import { PayloadAction, Action } from '@reduxjs/toolkit'

import { of, zip, EMPTY } from 'rxjs'
import { ofType } from 'redux-observable'
import { map, catchError, switchMap, concatMap, mergeMap } from 'rxjs/operators'

import { APP_CONSTANT } from 'app/constants/app'
import HttpService from 'app/services/httpService/httpService'
import * as apiModels from './epic.types'
import ApiHelper from 'app/utils/apiHelper'
import { ItemDetailMode } from '../itemDetails/types'
import {
  ItemsActions,
  SystemActions,
  DataStoreSelectors,
  ItemsDetailsActions,
  UsersSelectors,
  SystemSelectors,
} from '..'

import { HEXA_ID } from 'app/constants/hexabase'
import { SearchConditions } from './types'
import * as linkerApi from 'app/services/store/utils/linkerApis'

/**
 * @action INIT
 * @in [PERSIST-STORE] REHYDRATE
 *
 * @description This is an init action called automatically  when the
 * store have been rehydrated. Here used to refresh the item list when loaded
 * If you want to know why this is called : https://github.com/b-eee/hexalite/wiki/Store-Architecture (check EPIC subpart)
 *
 * @outSuccess [Project] getProjectsRequest
 * @outFailed [EMPTY] EMPTY
 */
const initEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(APP_CONSTANT.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      const userLogged = UsersSelectors.isUserLogged(state$.value)
      const projectId = SystemSelectors.getCurrentProjectId(state$.value)
      const datastoreId = DataStoreSelectors.getCurrentDatastoreId(state$.value)
      if (userLogged && projectId && datastoreId) {
        return of(ItemsActions.getItemsListRequest({ projectId, datastoreId }))
      } else {
        return EMPTY
      }
    }),
  )

/**
 * @action HTTP
 * @in [Items] getItemsListRequest
 * @param {string} projectId the project id containing the list
 * @param {string} datastoreId the datastore id containing the list
 *
 * @description Load all items for the selected datastore, build the entries
 * and add the data to the items store
 *
 * @outSuccess [Items] getItemsListSuccess
 *
 * @outFailed [Items] getItemsListFailed
 */
const getItemsListEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.getItemsListRequest),
    switchMap((action: PayloadAction<{ projectId: string; datastoreId: string }>) => {
      const datastore_id = action.payload.datastoreId
      const project_id = action.payload.projectId
      return HttpService.PostAsync<
        apiModels.api_aggregated_api_datastore_items_request,
        apiModels.api_aggregated_api_datastore_items_response
      >(
        `aggregated_api_datastore_items`,
        {
          get_datastore_settings: {
            datastore_id,
            project_id,
          },
          get_item_list_personalize: {
            d_id: DataStoreSelectors.getCurrentDatastoreId(state$.value),
          },
          get_sf_new_action_settings: {
            datastore_id,
            project_id,
          },
          get_datastore_colinfo: {
            datastore_id,
            project_id,
          },
          postget_paginate_items_with_search: {
            datastore_id,
            project_id,
            conditions: [],
            per_page: 1000,
          },
        },
        HttpService.HexaLinkBasePath,
      ).pipe(
        map((result) => {
          const response = result.data
          const entries = ApiHelper.buildEntries(
            response.postget_paginate_items_with_search.items,
            response.postget_paginate_items_with_search.fields,
          )
          return ItemsActions.getItemsListSuccess({
            entries: entries,
            columns: response.get_datastore_colinfo,
            fields: response.postget_paginate_items_with_search.fields,
            new_actions: response.get_sf_new_action_settings,
            settings: response.get_datastore_settings,
            personalization: response.get_item_list_personalize,
            searchItems: {
              items: [],
              fields: {},
              totalItems: 0,
              searchConditions: [],
            },
          })
        }),
        catchError((error: AxiosError) => {
          return of(ItemsActions.getItemsListFailed({ error: error.message }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @param {string} actionId the action by which we delete the item
 * @param {string} itemId the id of the item we want to delete
 * @in [Items] deleteItemRequest
 *
 * @description Delete an item using an action from the server, and close the
 * item details page if opened
 *
 * @outSuccess [Items] deleteItemSuccess
 * @outSuccess [ItemsDetails] setMode
 *
 * @outFailed [Items] getItemsListFailed
 */
const deleteItemEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.deleteItemRequest),
    switchMap(
      (
        action: PayloadAction<{
          datastoreId: string
          itemId: string
          deleteLinkedItems?: boolean
          targetDatastores?: string[]
        }>,
      ) => {
        const { datastoreId, itemId, deleteLinkedItems, targetDatastores } = action.payload
        return linkerApi
          .deleteItem({
            itemId,
            datastoreId,
            deleteLinkedItems,
            targetDatastores,
          })
          .pipe(
            switchMap(() => {
              return [
                ItemsActions.deleteItemSuccess({ itemId: action.payload.itemId }),
                ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }),
              ]
            }),
            catchError((error: AxiosError) => of(ItemsActions.getItemsListFailed({ error: error.message }))),
          )
      },
    ),
  )

/**
 * @action LINEAR
 * @in [Items] setCurrentItemId
 * @param {string} itemId
 * @description Select the item from the item list and open it
 * in the item details window. If no itemId is passed then close the itemDetails
 * window
 *
 * @outSuccess [ItemsDetails] getItemDetailsRequest
 * @outSuccess [ItemsDetails] setMode
 * @outSuccess [ItemsDetails] reset
 */
const setCurrentItemIdEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.setCurrentItemId),
    switchMap((action: PayloadAction<{ itemId: string }>) => {
      const selectingItemId = action && action.payload.itemId
      if (selectingItemId) {
        return [
          ItemsDetailsActions.getItemDetailsRequest({ itemId: action.payload.itemId }),
          ItemsDetailsActions.setMode({ mode: ItemDetailMode.DISPLAY }),
        ]
      } else {
        return [ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }), ItemsDetailsActions.reset()]
      }
    }),
  )

const itemsSearchEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.itemsSearchRequest),
    switchMap((action: { payload: { datastoreId: string; conditions: SearchConditions; resultPagePath: string } }) => {
      const applicatinID = HEXA_ID.APPLICATION
      const datastoreID = action.payload.datastoreId

      return zip(
        HttpService.GetAsync<null, apiModels.get_datastore_fields_response>(
          `applications/${applicatinID}/datastores/${datastoreID}/fields`,
          null,
          HttpService.LinkerAPIBasePath,
        ),
        HttpService.PostAsync<apiModels.items_search_request, apiModels.items_search_response>(
          `applications/${applicatinID}/datastores/${datastoreID}/items/search`,
          {
            conditions: action.payload.conditions,
            use_field_display_id: true,
            use_display_id: true,
            include_links: true,
            per_page: 0,
            page: 1,
          },
          HttpService.LinkerAPIBasePath,
        ),
      ).pipe(
        mergeMap((result) => {
          return of(
            ItemsActions.itemsSearchSuccess({
              items: result[1].data.items,
              fields: result[0].data.fields,
              totalItems: result[1].data.totalItems,
              searchConditions: action.payload.conditions,
            }),
            SystemActions.navigate({ path: action.payload.resultPagePath }),
          )
        }),
        catchError((error: string) => {
          return of(ItemsActions.itemsSearchFaild({ error }))
        }),
      )
    }),
  )

const executeBulkActionEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.executeBulkActionRequest),
    switchMap((action: { payload: { searchValue: string[]; datastoreId: string; actionId: string } }) => {
      const projectId = SystemSelectors.getCurrentProjectId(state$.value)

      return HttpService.PostAsync<apiModels.execute_bulk_action_request, apiModels.execute_bulk_action_response>(
        `applications/${projectId}/datastores/${action.payload.datastoreId}/items/bulkaction/${action.payload.actionId}`,
        {
          conditions: [
            {
              id: 'i_id',
              search_value: action.payload.searchValue,
            },
          ],
          max_items: 300,
          use_display_id: true,
          continue_proc: true,
        },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        concatMap(() => {
          return of(
            ItemsActions.executeBulkActionSuccess({ searchValue: action.payload.searchValue }),
            ItemsActions.getItemsListRequest({ projectId, datastoreId: action.payload.datastoreId }),
          )
        }),
        catchError((error: AxiosError) =>
          of(
            ItemsActions.executeBulkActionFailed({ error: error.message }),
            ItemsActions.getItemsListRequest({ projectId, datastoreId: action.payload.datastoreId }),
          ),
        ),
      )
    }),
  )
const deleteItemFromSearchResultEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.deleteItemFromSearchResultRequest),
    switchMap((action: PayloadAction<{ datastoreId: string; itemId: string }>) => {
      return HttpService.DeleteAsync<{}, apiModels.post_delete_dt_item_response>(
        `applications/${HEXA_ID.APPLICATION}/datastores/${action.payload.datastoreId}/items/delete/${action.payload.itemId}`,
        {},
        HttpService.LinkerAPIBasePath,
      ).pipe(
        switchMap(() => {
          return [
            ItemsActions.deleteItemFromSearchResultSuccess({ itemId: action.payload.itemId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }),
          ]
        }),
        catchError((error: AxiosError) => of(ItemsActions.deleteItemFromSearchResultFaild({ error: error.message }))),
      )
    }),
  )

export default [
  initEpic,
  getItemsListEpic,
  setCurrentItemIdEpic,
  deleteItemEpic,
  itemsSearchEpic,
  executeBulkActionEpic,
  deleteItemFromSearchResultEpic,
]
