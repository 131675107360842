import { AxiosError } from 'axios'
import { PayloadAction, Action } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { of, Observable, EMPTY, concat, forkJoin } from 'rxjs'
import { map, catchError, switchMap, concatMap, first, mergeMap } from 'rxjs/operators'

import {
  SystemSelectors,
  SystemActions,
  ItemsDetailsActions,
  ItemsSelectors,
  ItemDetailsSelectors,
  ItemsActions,
  UsersSelectors,
  DatastoreActions,
  DataStoreSelectors,
} from '..'
import { ItemDetailsState } from 'app/services/store/itemDetails/state'

import HttpService from 'app/services/httpService/httpService'
import * as apiModels from './epic.types'
import ApiHelper from 'app/utils/apiHelper'
import { ItemDetailMode, LinkedAllDbsItems } from './types'
import ActionHelper from 'app/utils/actionHelper'

/**
 * @action HTTP
 * @in [ItemsDetails] getItemDetailsRequest
 *
 * @description Load the item details from the API, build the entries and
 * set all in the store
 *
 * @outSuccess [ItemsDetails] getItemDetailsSuccess
 * @outFailed [ItemsDetails] getItemDetailsFailed
 */
const getItemDetailsEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getItemDetailsRequest),
    switchMap((action: PayloadAction<{ itemId: string }>) => {
      const datastore_id = DataStoreSelectors.getCurrentDatastoreId(state$.value)
      const project_id = SystemSelectors.getCurrentProjectId(state$.value)
      return HttpService.PostAsync<
        apiModels.api_aggregated_api_itemdetails_request,
        apiModels.api_aggregated_api_itemdetails_response
      >(
        `aggregated_api_itemdetails`,
        {
          get_datastore_item_details: {
            datastore_id,
            item_id: action.payload.itemId,
          },
          get_item_posts: {
            datastore_id,
            project_id,
            item_id: action.payload.itemId,
            from_item_index: 0,
            to_item_index: 20,
          },
        },
        HttpService.HexaLinkBasePath,
      ).pipe(
        map((result) => {
          const items = [result.data.get_datastore_item_details.itemDetails]
          const fields = Object.values(result.data.get_datastore_item_details.fields)
          const entry = ApiHelper.buildEntries(items, fields)[0]
          return ItemsDetailsActions.getItemDetailsSuccess({
            entry: entry,
            labels: result.data.get_datastore_item_details.labels,
            post: result.data.get_item_posts,
            fields: result.data.get_datastore_item_details.fields,
            layout: result.data.get_datastore_item_details.field_layout,
            statusActions: result.data.get_datastore_item_details.actions,
            relations: result.data.get_datastore_item_details.related_datastores,
            actions: result.data.get_datastore_item_details.stateflowActions,
            statuses: {
              statuses: result.data.get_datastore_item_details.statuses,
              statusOrderSettings: result.data.get_datastore_item_details.statusOrderSettings,
            },
            titles: result.data.get_datastore_item_details.titles,
            mode: ItemDetailMode.DISPLAY,
          })
        }),
        catchError((error: AxiosError) => {
          return of(ItemsDetailsActions.getItemDetailsFailed({ error: error.message }))
        }),
      )
    }),
  )

/**
 * @action LINEAR
 * @in [ItemsDetails] selectAction
 * @param {string} actionId the id of the action to select
 * @description Depending on the type of datastore action, dispatch the approriated
 * action to the store
 *
 * @outADD [ItemsDetails] newItemModeRequest
 * @outADD [ItemsDetails] getActionSettingsRequest
 * @outADD [ItemsDetails] setMode
 *
 * @outEDIT [ItemsDetails] getActionSettingsRequest
 * @outEDIT [ItemsDetails] setMode
 *
 * @outDELETE [ItemsDetails] deleteItemRequest
 *
 * @outCOPY [ItemsDetails] newItemModeRequest
 * @outCOPY [ItemsDetails] getActionSettingsRequest
 * @outCOPY [ItemsDetails] setMode
 */
const selectActionEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.selectAction),
    concatMap((action: PayloadAction<{ actionId: string }>) => {
      const sAction = ItemDetailsSelectors.getAnyActionById(state$.value)(action.payload.actionId)
      if (!sAction) {
        return EMPTY
      }
      switch (sAction.operation) {
        case ActionHelper.actionTypes.ADD:
          return [
            ItemsDetailsActions.newItemModeRequest({ keepFieldsValue: false }),
            ItemsDetailsActions.getActionSettingsRequest({ actionId: action.payload.actionId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.NEW }),
          ]
        case ActionHelper.actionTypes.EDIT:
          return [
            ItemsDetailsActions.getActionSettingsRequest({ actionId: action.payload.actionId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.UPDATE }),
          ]
        // TODO: 修正
        // case ActionHelper.actionTypes.DELETE:
        //   return [
        //     ItemsActions.deleteItemRequest({
        //       itemId: ItemsSelectors.getCurrentItemId(state$.value),
        //       actionId: action.payload.actionId,
        //     }),
        //   ]
        case ActionHelper.actionTypes.COPY:
          return [
            ItemsDetailsActions.newItemModeRequest({ keepFieldsValue: true }),
            ItemsDetailsActions.getActionSettingsRequest({ actionId: action.payload.actionId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.COPY }),
          ]
        default:
          return EMPTY
      }
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] newItemModeRequestEpic
 * @param {boolean} keepFieldsValue flag if we wish or not to keep the field from the itemDetails
 * or reset them
 *
 * @description Request to enter in new item mode. This will retrieve an new itemId from the API and
 * use the ItemDetails in new item Mode
 *
 * @outSuccess [ItemsDetails] newItemModeSuccess
 *
 * @outFailed [ItemsDetails] newItemModeFailed
 */
const newItemModeRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.newItemModeRequest),
    switchMap((action: PayloadAction<{ keepFieldsValue: boolean }>) => {
      const newFieldsValues: { [k: string]: any } = {}
      const fields = ItemDetailsSelectors.getFields(state$.value)
      const entry = ItemDetailsSelectors.getEntry(state$.value)
      const keepFields = action.payload.keepFieldsValue
      for (const key in fields) {
        if (Object.prototype.hasOwnProperty.call(fields, key)) {
          newFieldsValues[key] = keepFields ? entry.fields[key] : ''
        }
      }
      return HttpService.GetAsync<null, { item_id: string }>(`get_new_item_id`).pipe(
        map((result) => {
          return ItemsDetailsActions.newItemModeSuccess({
            newItemId: result.data.item_id,
            fields: newFieldsValues,
          })
        }),
        catchError((error: AxiosError) => {
          return of(ItemsDetailsActions.newItemModeFailed({ error: error.message }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] getActionSettingsRequest
 * @param {string} actionId the id of the action we wish to get the settings from
 *
 * @description Execute an API call in order to retrieve the settings of an action
 *
 * @outSuccess [ItemsDetails] getActionSettingsSuccess
 *
 * @outFailed [ItemsDetails] getActionSettingsFailed
 */
const getActionSettingsRequestEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getActionSettingsRequest),
    switchMap((action: { payload: { actionId: string } }) => {
      return HttpService.GetAsync<
        apiModels.get_action_and_field_settings_request,
        apiModels.get_action_and_field_settings_response
      >(`get_action_and_field_settings`, {
        action_id: action.payload.actionId,
      }).pipe(
        map((result) => {
          return ItemsDetailsActions.getActionSettingsSuccess({
            action: {
              ...result.data.action,
              action_field_settings: result.data.action_field_settings,
            },
          })
        }),
        catchError((error: AxiosError) => {
          return of(ItemsDetailsActions.getActionSettingsFailed({ error: error.message }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] newItemRequestEpic
 * @param {string} actionId the id of the action we wish to get the settings from
 * @param {{[k: string]: any}} fields the new items fields
 *
 * @description Execute an API call to create a new item, add this entry to the items list
 * and close the item details
 *
 * @outSuccess [Items] addEntry
 * @outSuccess [ItemsDetails] setMode
 *
 * @outFailed [ItemsDetails] getActionSettingsFailed
 */
const newItemRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.newItemRequest),
    switchMap((action: PayloadAction<{ actionId: string; fields: { [k: string]: any } }>) => {
      const datastoreId = DataStoreSelectors.getCurrentDatastoreId(state$.value)
      const projectId = SystemSelectors.getCurrentProjectId(state$.value)
      const workspaceId = SystemSelectors.getCurrentWorkspaceId(state$.value)
      const itemId = ItemDetailsSelectors.getEntryId(state$.value)
      const userId = UsersSelectors.getUserId(state$.value)
      const username = UsersSelectors.getUsername(state$.value)
      return HttpService.PostAsync<apiModels.new_item_record_request, apiModels.new_item_record_response>(
        `new_item_record`,
        {
          a_id: action.payload.actionId,
          d_id: datastoreId,
          p_id: projectId,
          item: {
            d_id: datastoreId,
            p_id: projectId,
            ...action.payload.fields,
          },
          item_id: itemId,
          user_id: userId,
          w_id: workspaceId,
        },
      ).pipe(
        switchMap((result) => {
          const buildChanges = ApiHelper.getFieldsAsArray(action.payload.fields)
          const entry = ApiHelper.changeEntryField(
            buildChanges,
            ItemDetailsSelectors.getEntry(state$.value),
            ItemDetailsSelectors.getFields(state$.value),
          )
          return [
            ItemsActions.addEntry({
              entry: {
                access_keys: '',
                created_at: Date.now().toString(),
                created_by: username,
                fields: entry,
                d_id: datastoreId,
                i_id: result.data.id,
                p_id: projectId,
                rev_no: 0,
                title: '',
                unread: '0',
                updated_at: Date.now().toString(),
                updated_by: username,
                _id: result.data.id,
              },
            }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }),
          ]
        }),
        catchError((error: AxiosError) => {
          return of(ItemsDetailsActions.getActionSettingsFailed({ error: error.message }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] updateItemRequest
 * @param {string} actionId the id of the action we wish to get the settings from
 * @param {{[k: string]: any}} fields the updated items fields
 *
 * @description Execute an API call to update an item, update it in the items list
 * change the revision number and close the itemsdetails
 *
 * @outSuccess [ItemsDetails] updateItemSuccess
 * @outSuccess [Items] updateEntry
 * @outSuccess [ItemsDetails] setRevisionNumber
 * @outSuccess [ItemsDetails] setMode
 *
 * @outFailed [ItemsDetails] updateItemFailed
 */
const updateItemRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.updateItemRequest),
    switchMap((action: PayloadAction<{ actionId: string; changes: { [k: string]: any }; newStatusId?: string }>) => {
      const buildChanges = ApiHelper.getFieldsAsArray(action.payload.changes)
console.log("update start")
      // remove unneeded fields (separator/status...)
      const filteredChanges = ApiHelper.removeUnnecessaryFields(
        buildChanges,
        ItemDetailsSelectors.getFields(state$.value),
      )

      return HttpService.PostAsync<
        apiModels.update_post_item_history_request,
        apiModels.update_post_item_history_response
      >(`update_post_item_history`, {
        action_id: action.payload.actionId,
        history: {
          datastore_id: DataStoreSelectors.getCurrentDatastoreId(state$.value),
          item_id: ItemsSelectors.getCurrentItemId(state$.value),
          comment: '',
        },
        changes: filteredChanges,
        rev_no: ItemDetailsSelectors.getRevisionNumber(state$.value),
      }).pipe(
        switchMap(() => {
          const entry = ApiHelper.changeEntryField(
            buildChanges,
            ItemDetailsSelectors.getEntry(state$.value),
            ItemDetailsSelectors.getFields(state$.value),
          )

          if (action.payload.newStatusId) {
            const statusField = ItemDetailsSelectors.getStatusField(state$.value)
            const statusInfo = ItemDetailsSelectors.getStatusById(state$.value)(action.payload.newStatusId)
            if (statusField && statusInfo) {
              entry[statusField.f_id] = statusInfo.data.name
            }
          }
          console.log("update end?")

          return [
            ItemsDetailsActions.getItemDetailsRequest({ itemId: ItemsSelectors.getCurrentItemId(state$.value) }),
            ItemsActions.updateEntry({
              itemId: ItemsSelectors.getCurrentItemId(state$.value),
              changes: { fields: entry },
              newStatusId: action.payload.newStatusId,
            }),
          ]
        }),
        catchError((error: AxiosError) => {
          return of(ItemsDetailsActions.updateItemFailed({ error: error.message }))
        }),
      )
    }),
  )

/**
 * @action LINEAR
 * @in [ItemsDetails] goToItem
 * @param {string} projectId    Id of the item's project we wish to go to
 * @param {string} datastoreId  Id of the item's datastore we wish to go to
 * @param {string} itemId       Id of the item we wish to go to
 *
 * @description Go to an item in another project or datastore. If required, also load those project and
 * datastore
 *
 * @outSuccess [Project] setCurrentProjectId
 * @outSuccess [Datastore] setCurrentDatastoreId
 * @outSuccess [Items] setCurrentItemId
 */

const goToItemEpic = (action$, state$): Observable<any> =>
  action$.pipe(
    ofType(ItemsDetailsActions.goToItem),
    concatMap((action: PayloadAction<{ projectId: string; datastoreId: string; itemId: string }>) => {
      const projectId = SystemSelectors.getCurrentProjectId(state$.value)
      if (action.payload.projectId !== projectId) {
        return concat(
          of(SystemActions.setCurrentProjectId({ projectId: action.payload.projectId })),
          action$.pipe(
            ofType(DatastoreActions.getDatastoresSuccess),
            first(),
            switchMap(() =>
              of(
                DatastoreActions.setCurrentDatastoreId({ datastoreId: action.payload.datastoreId }),
                ItemsActions.setCurrentItemId({ itemId: action.payload.itemId }),
              ),
            ),
          ),
        )
      } else {
        return [
          DatastoreActions.setCurrentDatastoreId({ datastoreId: action.payload.datastoreId }),
          ItemsActions.setCurrentItemId({ itemId: action.payload.itemId }),
        ]
      }
    }),
  )
const setModeEpic = (action$, state$): Observable<any> =>
  action$.pipe(
    ofType(ItemsDetailsActions.setMode),
    concatMap((action: PayloadAction<{ mode: ItemDetailMode }>) => {
      if (
        action.payload.mode === ItemDetailMode.CLOSE ||
        action.payload.mode === ItemDetailMode.DISPLAY ||
        action.payload.mode === ItemDetailMode.LAYOUT
      ) {
        return [ItemsDetailsActions.cancelAction()]
      } else {
        return EMPTY
      }
    }),
  )

const getLinkedAllDbsItemsRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.getLinkedAllDbsItemsRequest),
    switchMap((action: { payload: { iId: string; relations: ItemDetailsState['relations'] } }) => {
      const iId = action.payload.iId
      const relations = action.payload.relations
      const dId = DataStoreSelectors.getCurrentDatastoreId(state$.value)

      const observableResponses = relations.map((relation) =>
        HttpService.GetAsync<null, apiModels.LinkedDbItemsResponse>(
          `datastores/${dId}/items/${iId}/links/${relation.d_id}`,
          null,
          HttpService.LinkerAPIBasePath,
        ).pipe(
          switchMap((response) => of(response)),
          // TODO: error handling
          catchError((error: string) => of(null)),
        ),
      )

      return forkJoin(observableResponses).pipe(
        switchMap((responses) => {
          const linkedAllDbsItems = responses.map((response) => {
            if (response) {
              return {
                d_id: response.data.datastore_id,
                fields: response.data.fields,
                items: response.data.items,
                columnsSettings: response.data.column_settings,
              }
            }
            return []
          })

          return of(
            ItemsDetailsActions.getLinkedAllDbsItemsSuccess({
              linkedAllDbsItems: linkedAllDbsItems as LinkedAllDbsItems,
            }),
          )
        }),
        catchError((error: string) => of(ItemsDetailsActions.getLinkedAllDbsItemsFaild({ error }))),
      )
    }),
  )

const createItemEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.createItemRequest),
    switchMap((action: { payload: { dId: string; item: { [key: string]: any } } }) => {
      const pId = SystemSelectors.getCurrentProjectId(state$.value)
      return HttpService.PostAsync<{ item: { [key: string]: any } }, null>(
        `/applications/${pId}/datastores/${action.payload.dId}/items/new`,
        {
          item: action.payload.item,
        },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap((response) => {
          return of(ItemsDetailsActions.createItemSuccess())
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.createItemFailed({ error }))
        }),
      )
    }),
  )

const deleteItemEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.deleteItemRequest),
    switchMap((action: { payload: { navigatePath: string } }) => {
      const project_id = SystemSelectors.getCurrentProjectId(state$.value)
      const datastore_id = DataStoreSelectors.getCurrentDatastoreId(state$.value)
      const item_id = ItemsSelectors.getCurrentItemId(state$.value)

      return HttpService.DeleteAsync<{}, { error: any; history_id: string; item_id: string }>(
        `applications/${project_id}/datastores/${datastore_id}/items/delete/${item_id}`,
        { data: {}, headers: { 'Content-Type': 'application/json' } },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap((response) => {
          return of(
            ItemsDetailsActions.deleteItemSuccess(),
            SystemActions.navigate({ path: action.payload.navigatePath }),
          )
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.deleteItemFailed({ error }))
        }),
      )
    }),
  )

export default [
  getItemDetailsEpic,
  selectActionEpic,
  newItemModeRequestEpic,
  newItemRequestEpic,
  getActionSettingsRequestEpic,
  updateItemRequestEpic,
  goToItemEpic,
  setModeEpic,
  getLinkedAllDbsItemsRequestEpic,
  createItemEpic,
  deleteItemEpic,
]
