import React, { useState,useCallback,useEffect } from 'react'
import { Modal, Row, Col, Form, Input, Table, Button, Spin } from 'antd'

import {
  formatLowestPriceColumn,
  formatLowestPrice,
  formatLowestSalesColumn,
  formatLowestSales,
  getLowestSalesAndPurchase,
  commaFormatter,
} from 'app/utils/components/quotation/quotation'

import { LowestPrices,LowestSaless } from 'app/services/store/quotationDetails/types';
import { getFields } from '../../../../services/store/itemDetails/selectors';

interface SearchLowestPriceModalProps {
  visible: boolean
  isLoading: boolean
  lowestPrices: LowestPrices
  lowestSaless: LowestSaless
  iId: any
  tabType: any
  targetItem: any
  handleOnOk: Function
  handleOnCancel: Function
}

const LowestPriceModal = ({
  visible,
  isLoading,
  lowestPrices,
  lowestSaless,
  iId,
  tabType,
  targetItem,
  handleOnOk,
  handleOnCancel,
}: SearchLowestPriceModalProps): JSX.Element => {
  const { lowestPriceDataOfAll, lowestPriceDataOfCustomer } = lowestPrices
  const { lowestSalesDataOfAll, lowestSalesDataOfCustomer } = lowestSaless
  const [stopflg, setstopflg] = useState(false)
  const [targetItems, settargetItems] = useState<{productCode : any,productName : any,standardCapacity : any,productUnit : any,remarks : any,sales : any,purchase : any,standardPurchase : any,grossProfitMargin : any,quantity : any,constructionVolume : any,amountUsed : any,internalCapacity : any}>
  ({ productCode:targetItem.productCode , productName: targetItem.productName , standardCapacity: targetItem.standardCapacity , productUnit: targetItem.productUnit , remarks: targetItem.remarks , sales: targetItem.sales , purchase: targetItem.purchase , standardPurchase: targetItem.standardPurchase , grossProfitMargin: targetItem.grossProfitMargin , quantity: targetItem.quantity , constructionVolume: targetItem.constructionVolume , amountUsed: targetItem.amountUsed , internalCapacity: targetItem.internalCapacity });

  let new_display:boolean
  if(targetItems.quantity <= 0){
    new_display = false
  }
  if(targetItems.grossProfitMargin <= 0){
    new_display = false
  }else{
    new_display = true
  }

  const [displayflg, setdisplayflg] = useState(new_display)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let colname = e.target.name
    let values : any = e.target.value
    let grossProfitMargin_math : number = 0
    console.log("values:"+values)
    if(colname === "amountUsed"){
      let quantity_math = Math.ceil((targetItems.constructionVolume * values) / targetItems.internalCapacity)

      form.setFieldsValue({ quantity: quantity_math, })
      settargetItems({ ...targetItems, [colname] : e.target.value, quantity : quantity_math})
    }else{
      if(colname === "purchase"){
        if(!targetItems.sales || !values ) {
          grossProfitMargin_math=0
        }else{
           grossProfitMargin_math = Math.floor((targetItems.sales - values) / targetItems.sales * 1000) / 1000
        }
        form.setFieldsValue({ grossProfitMargin: grossProfitMargin_math, })
        settargetItems({ ...targetItems, [colname] : e.target.value, grossProfitMargin : grossProfitMargin_math})
      }else{
        if(colname === "sales"){
          if(!targetItems.sales || !values) {
            grossProfitMargin_math=0
          }else{
            grossProfitMargin_math = Math.floor((values - targetItems.purchase) / values * 1000) / 1000
          }
          form.setFieldsValue({ grossProfitMargin: grossProfitMargin_math, })
          settargetItems({ ...targetItems, [colname] : e.target.value, grossProfitMargin : grossProfitMargin_math})
        }else{
          settargetItems({ ...targetItems, [colname] : e.target.value })
        }
      }
    }
    Alertcheck()
  }

  const handleapply = (lowestvalue_sales: number,lowestvalue_purchase: number) => {
    let grossProfitMargin_math=0
    if(!lowestvalue_purchase) {
      grossProfitMargin_math = 0
    }else{
      grossProfitMargin_math = Math.floor((lowestvalue_sales - lowestvalue_purchase) / lowestvalue_sales * 1000) / 1000
    }
    form.setFieldsValue({ grossProfitMargin: grossProfitMargin_math, })
    settargetItems({ ...targetItems, sales: lowestvalue_sales,purchase: lowestvalue_purchase, grossProfitMargin : grossProfitMargin_math })
  }

  const columns_sales = [
    {
      title: '',
      dataIndex: 'lowesttype',
      key: 'lowesttype',
      id: '',
      type: '',
    },
    {
      title: '得意先名',
      dataIndex: 'customerName',
      key: 'customerName',
      id: '',
      type: '',
    },
    {
      title: '現場名',
      dataIndex: 'siteName',
      key: 'siteName',
      id: '',
      type: '',
    },
    {
      title: '売上',
      render: (_, record) => <div style={{textAlign:"right"}}>{commaFormatter(record.sales)}</div>,
    },
    {
      title: '仕入',
      render: (_, record) => <div style={{textAlign:"right"}}>{commaFormatter(record.purchase)}</div>,
    },
    {
      title: '粗利率',
      render: (_, record) => <div style={{textAlign:"right"}}>{commaFormatter(Math.round(record.grossProfitMargin * 1000) / 10) +"%"}</div>,
    },
    {
      title: '日付',
      dataIndex: 'dateOfCreation',
      key: 'dateOfCreation',
      id: '',
      type: '',
    },
    {
      title: '',
      render: (_, record) => <Button type="primary" onClick={(): void => handleapply(record.sales,record.purchase)}>適用</Button>,
    }
  ]

  const columns_purchase = [
    {
      title: '',
      dataIndex: 'lowesttype',
      key: 'lowesttype',
      id: '',
      type: '',
    },
    {
      title: '得意先名',
      dataIndex: 'customerName',
      key: 'customerName',
      id: '',
      type: '',
    },
    {
      title: '現場名',
      dataIndex: 'siteName',
      key: 'siteName',
      id: '',
      type: '',
    },
    {
      title: '売上',
      render: (_, record) => <div style={{textAlign:"right"}}>{commaFormatter(record.sales)}</div>,
    },
    {
      title: '仕入',
      render: (_, record) => <div style={{textAlign:"right"}}>{commaFormatter(record.purchase)}</div>,
    },
    {
      title: '粗利率',
      render: (_, record) => <div style={{textAlign:"right"}}>{commaFormatter(Math.round(record.grossProfitMargin * 1000) / 10) +"%"}</div>,
    },
    {
      title: '日付',
      dataIndex: 'dateOfCreation',
      key: 'dateOfCreation',
      id: '',
      type: '',
    },
    {
      title: '',
      render: (_, record) => <Button type="primary" onClick={(): void => handleapply(record.sales,record.purchase)}>適用</Button>,
    }
  ]

  const [form] = Form.useForm()

const formattedLowestPriceOfCustomer = formatLowestPrice(lowestPriceDataOfCustomer.price)
const formattedLowestPriceOfAll = formatLowestPrice(lowestPriceDataOfAll.price)

const formattedLowestSalesOfCustomer = formatLowestSales(lowestSalesDataOfCustomer.price)
const formattedLowestSalesOfAll = formatLowestSales(lowestSalesDataOfAll.price)
/*
console.log("lowestPriceDataOfCustomer")
console.log(lowestPriceDataOfCustomer)
console.log("lowestSalesDataOfCustomer")
console.log(lowestSalesDataOfCustomer)
*/
let formattedLowestPriceOfCustomer_array = formattedLowestPriceOfCustomer[0]
let formattedLowestPriceOfAll_array = formattedLowestPriceOfAll[0]
if(formattedLowestPriceOfCustomer_array !== undefined) {
  formattedLowestPriceOfCustomer_array.lowesttype='得意先'
}
if(formattedLowestPriceOfAll_array !== undefined) {
  formattedLowestPriceOfAll_array.lowesttype='全社'
}

const formattedLowestPriceOfCustomer2 = formattedLowestPriceOfCustomer.concat(formattedLowestPriceOfAll);

let formattedLowestSalesOfCustomer_array = formattedLowestSalesOfCustomer[0]
let formattedLowestSalesOfAll_array = formattedLowestSalesOfAll[0]
if(formattedLowestSalesOfCustomer_array !== undefined) {
  formattedLowestSalesOfCustomer_array.lowesttype='得意先'
}
if(formattedLowestSalesOfAll_array !== undefined) {
  formattedLowestSalesOfAll_array.lowesttype='全社'
}

const formattedLowestSalesOfCustomer2 = formattedLowestSalesOfCustomer.concat(formattedLowestSalesOfAll);

function Alertcheck() {
  console.log("Alertcheck")
  let new_display = displayflg
  if(targetItems.quantity <= 0){
    new_display = false
  }
  if(targetItems.grossProfitMargin <= 0){
    new_display = false
  }else{
    new_display = true
  }
  const tempflg = new_display
  setdisplayflg(tempflg)
}

useEffect(() => Alertcheck, [displayflg])

return (
    <Modal visible={visible} closable={false} maskClosable={false} width="85vw" centered footer={[]}>
      <style>{".originalHeader .ant-table-thead > tr >th{color: white;background: #939598 !important;}"}</style>
      <Spin spinning={isLoading}>
      <Form form={form}>
      <div style={{ padding: '20px' }} >
      <table style={{width:"100%"}}>
        <thead style={{backgroundColor:"#446aaa",color:"white"}}>
        <tr>
          <th style={{backgroundColor:"#939598",height:"46px", paddingLeft: '5px'}}>商品コード</th>
          <th style={{ paddingLeft: '5px'}}>商品名</th>
          <th style={{ paddingLeft: '5px'}}>規格・容量</th>
          <th style={{ paddingLeft: '5px'}}>単位</th>
          <th style={{ paddingLeft: '5px'}}>備考</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td style={{height:"56px",width:"100px", paddingLeft: '5px'}}>{targetItems.productCode}</td>
          <td style={{width:"200px"}}><input type="text" name="productName" defaultValue={targetItems.productName} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
          <td style={{width:"90px"}}><input type="text" name="standardCapacity" defaultValue={targetItems.standardCapacity} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
          <td style={{width:"80px"}}><input type="text" name="productUnit" defaultValue={targetItems.productUnit} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
          <td style={{width:"300px"}}><input type="text" name="remarks" defaultValue={targetItems.remarks} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
          </tr>
        </tbody>
      </table>
      <div style={{alignSelf:"left",color:"#465DAA",fontWeight:"bolder"}}><span>※個別の商品名・商品情報の変更はこちらから入力して下さい</span></div>
      </div>
      <div style={{ padding: '20px'}}>
        <table style={{ marginRight:'40px' ,display: 'inline-block' }}>
          <thead style={{color:"white"}}>
          <tr>
            <th style={{background:"#ED1C24",height:"46px", paddingLeft: '5px'}}>売値</th>
            <th style={{background:"#ED1C24", paddingLeft: '5px'}}>仕入</th>
            <th style={{background:"#939598", paddingLeft: '5px'}}>標準仕入</th>
            <th style={{background:"#939598", paddingLeft: '5px'}}>粗利率</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td><input type="number" name="sales" value={targetItems.sales} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
            <td><input type="number" name="purchase" value={targetItems.purchase} onChange={handleChange}  style={{height:"46px",width:"100%"}} /></td>
            <td style={{textAlign:"right",height:"56px",width:"100px", paddingRight: '5px'}}>{commaFormatter(targetItems.standardPurchase)}</td>
            <td style={{textAlign:"right",width:"100px", paddingRight: '5px'}}><span style={{color:"black"}}>{commaFormatter(Math.round(targetItems.grossProfitMargin * 1000) / 10) + "%"}</span></td>
            </tr>
          </tbody>
        </table>

        <table style={{ display: 'inline-block' }}>
          <thead style={{color:"white"}}>
          <tr>
            <th style={{backgroundColor:"#446aaa",height:"46px", paddingLeft: '5px'}}>数量</th>
            <th style={{background:"#939598", paddingLeft: '5px'}}>施工量×</th>
            <th style={{backgroundColor:"#446aaa", paddingLeft: '5px'}}>使用量÷</th>
            <th style={{background:"#939598", paddingLeft: '5px'}}>内容量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td style={{width:"90px"}}><input type="number" name="quantity" value={targetItems.quantity} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
            <td style={{textAlign:"right",height:"56px",width:"100px", paddingRight: '5px'}}>{commaFormatter(targetItems.constructionVolume)}</td>
            <td style={{width:"90px"}}><input type="number" name="amountUsed" value={targetItems.amountUsed} onChange={handleChange} style={{height:"46px",width:"100%"}} /></td>
            <td style={{textAlign:"right",width:"100px", paddingRight: '5px'}}>{commaFormatter(targetItems.internalCapacity)}</td>
            </tr>
          </tbody>
        </table>
        <div style={{textAlign:"left",color:"#465DAA",fontWeight:"bolder"}}><span>※数量を手入力した場合『施工量×使用料÷内容量』の計算は無視されます。</span></div>
        <div style={{textAlign:"left",color:"red",fontWeight:"bolder", display: displayflg ? 'none' : ''}}><span>数量又は粗利率がマイナスです。</span></div>
      </div>
        <div style={{ padding: '20px' }} >
          <Row>
            <Col>
              <h2>仕入最安値</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns_purchase}
                dataSource={formattedLowestPriceOfCustomer2}
                pagination={false}
                className={"originalHeader"}
              />
            </Col>
          </Row>

          <Row  style={{ marginTop: '20px' }}>
            <Col>
              <h2>売最安値</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={columns_sales}
                dataSource={formattedLowestSalesOfCustomer2}
                pagination={false}
                className={"originalHeader"}
              />
            </Col>
          </Row>

          <Row justify="center" style={{ marginTop: '20px' }}>
            <Col offset={1} span={2}>
              <Button
                type="primary"
//                disabled={!(formattedLowestPriceOfAll.length > 0)}
                onClick={(): void =>
                  handleOnOk(targetItems.sales, targetItems.purchase,targetItems)
                }
              >
                単価確定
              </Button>
            </Col>
            <Col offset={1} span={2}>
              <Button onClick={(): void => handleOnCancel()}>取消</Button>
            </Col>

          </Row>
        </div>
        </Form>
      </Spin>
    </Modal>

)
}

export default LowestPriceModal
