import React from 'react'
import { Row, Col } from 'antd'
import { HistoryOutlined } from '@ant-design/icons'

interface SearchButtonProps {
  handleOpen: Function
  label: string
}

const SearchButton = ({ handleOpen, label }: SearchButtonProps): JSX.Element => {
  return (
    <Row onClick={(): void => handleOpen(true)} style={{ fontSize: '17px', cursor: 'pointer' }}>
      <Col span={4}>
        <HistoryOutlined />
      </Col>
      <Col offset={1} span={19}>
        <span>{label}</span>
      </Col>
    </Row>
  )
}

export default SearchButton
