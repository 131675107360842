import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import {
  Quotation,
  ConstructionMethod,
  LowestPrices,
  LowestSaless,
  Flat,
  Rise,
  Else,
} from 'app/services/store/quotationDetails/types'
import { QuotationDetailsState } from 'app/services/store/quotationDetails/state'
import { DATASTORE_ID, HEXA_ID } from 'app/constants/hexabase'
import { itemList } from 'app/services/store/utils/linkerApis'

const quotationDetailsState = (state: RootState): QuotationDetailsState => state.quotationDetails
const lowestPrices = (state: RootState): LowestPrices => state.quotationDetails.lowestPrices
const lowestSaless = (state: RootState): LowestSaless => state.quotationDetails.lowestSaless

export const isLoading = createSelector<RootState, QuotationDetailsState, boolean>(
  quotationDetailsState,
  (quotationDetailsState): boolean => quotationDetailsState.loading,
)
export const isConstructionLoading = createSelector<RootState, QuotationDetailsState, boolean>(
  quotationDetailsState,
  (quotationDetailsState): boolean => quotationDetailsState.constructionLoading,
)
export const getQuotation = createSelector<RootState, QuotationDetailsState, QuotationDetailsState['quotation']>(
  quotationDetailsState,
  (quotationDetailsState): Quotation => quotationDetailsState.quotation,
)
export const getConstructionMethods = createSelector<
  RootState,
  QuotationDetailsState,
  QuotationDetailsState['constructionMethod']
>(quotationDetailsState, (quotationDetailsState): ConstructionMethod[] => quotationDetailsState.constructionMethod)

export const getFlatRiseElse = createSelector<
  RootState,
  QuotationDetailsState,
  [QuotationDetailsState['flat'], QuotationDetailsState['rise'], QuotationDetailsState['else']]
>(quotationDetailsState, (quotationDetailsState): [Flat[], Rise[], Else[]] => [
  quotationDetailsState.flat,
  quotationDetailsState.rise,
  quotationDetailsState.else,
])

export const getCustomerSearchResult = createSelector<
  RootState,
  QuotationDetailsState,
  QuotationDetailsState['customerSearchResult']
>(quotationDetailsState, (quotationDetailsState) => quotationDetailsState.customerSearchResult)

export const getQuotationNumber = createSelector<RootState, QuotationDetailsState, string>(
  quotationDetailsState,
  (quotationDetailsState): string => {
    if (quotationDetailsState.quotation.quotationNum) {
      return quotationDetailsState.quotation.quotationNum.replace(/_/gi, '-')
    }
    return ''
  },
)

export const getSiteName = createSelector<RootState, QuotationDetailsState, string>(
  quotationDetailsState,
  (quotationDetailsState): string =>
    quotationDetailsState.quotation.siteName ? quotationDetailsState.quotation.siteName : '',
)
export const getCustomerCode = createSelector<RootState, QuotationDetailsState, string>(
  quotationDetailsState,
  (quotationDetailsState): string =>
    quotationDetailsState.quotation.customerCode ? quotationDetailsState.quotation.customerCode : '',
)
export const getLowestPrices = createSelector<RootState, LowestPrices, QuotationDetailsState['lowestPrices']>(
  lowestPrices,
  (lowestPrices): LowestPrices => {
    const { lowestPriceDataOfAll, lowestPriceDataOfCustomer } = lowestPrices

    let filteredLowestPrice
    if (lowestPriceDataOfAll.price.length > 0) {
      filteredLowestPrice = lowestPriceDataOfAll.price.reduce((a, b) => (a['purchase'] !== 0 && b['purchase'] !== 0 ? a['purchase'] < b['purchase'] ? a : b : a['purchase'] !== 0 ? a : b))
    }
    return {
      lowestPriceDataOfAll: {
        columns: lowestPriceDataOfAll.columns,
        price: filteredLowestPrice ? [filteredLowestPrice] : [],
      },
      lowestPriceDataOfCustomer,
    }
  },
)

export const getLowestSaless = createSelector<RootState, LowestSaless, QuotationDetailsState['lowestSaless']>(
  lowestSaless,
  (lowestSaless): LowestSaless => {
    const { lowestSalesDataOfAll, lowestSalesDataOfCustomer } = lowestSaless

    let filteredLowestSales
    if (lowestSalesDataOfAll.price.length > 0) {
      filteredLowestSales = lowestSalesDataOfAll.price.reduce((a, b) => (a['sales'] === 0 ? b : a['sales'] < b['sales'] ? a : b))
    }
    return {
      lowestSalesDataOfAll: {
        columns: lowestSalesDataOfAll.columns,
        price: filteredLowestSales ? [filteredLowestSales] : [],
      },
      lowestSalesDataOfCustomer,
    }
  },
)

export const getSeparateOrTotalOptions = createSelector<
  RootState,
  QuotationDetailsState,
  { separate: string; total: string }
>(quotationDetailsState, (quotationDetailsState): { separate: string; total: string } => {
  const options = { separate: '', total: '' }
  Object.entries(quotationDetailsState.constructionMethodFields).forEach(([_, value]) => {
    if (value.display_id === 'separateOrTotal' && value.options) {
      options.total = value.options.filter((item) => item.value === '合算')[0].option_id
      options.separate = value.options.filter((item) => item.value === '平・立 別')[0].option_id
    }
  })
  return options
})

export const statusesData = (state: RootState): QuotationDetailsState['statusesData'] =>
  state.quotationDetails.statusesData

export const getStatusesData = createSelector<RootState, QuotationDetailsState, QuotationDetailsState['statusesData']>(
  quotationDetailsState,
  (quotationDetailsState): QuotationDetailsState['statusesData'] => quotationDetailsState.statusesData,
)

export const getallConstructionMethods = async (quotationIdText: string) => {
  const conditions = [
    {
      id: 'quotationIdText',
      search_value: [quotationIdText],
      exact_match: true,
      include_null: false,
    },
  ]
  const applicationId = HEXA_ID.APPLICATION
  const datastoreId = DATASTORE_ID.CONSTRUCTION_METHOD
  const construction_data = await itemList({ applicationId, datastoreId, conditions }).toPromise().then((result) => result.data.items)
  return construction_data
}

export const getOneItem = async (i_id: string, datastoreId:string) => {
  const conditions = [
    {
      id: 'i_id',
      search_value: [i_id],
      exact_match: true,
      include_null: false,
    },
  ]
  const applicationId = HEXA_ID.APPLICATION
  const get_data = await itemList({ applicationId, datastoreId, conditions }).toPromise().then((result) => result.data.items)
  return get_data
}

export default [
  isLoading,
  isConstructionLoading,
  getQuotation,
  getConstructionMethods,
  getFlatRiseElse,
  getCustomerSearchResult,
  getQuotationNumber,
  getCustomerCode,
  getLowestPrices,
  getLowestSaless,
  getSiteName,
  getSeparateOrTotalOptions,
  getStatusesData,
  getallConstructionMethods,
  getOneItem,
]
