// TODO: ファイル名をindex.tsにリネーム
import { QuotationListItems } from 'app/types/components/quotation'
import { SummaryResourcesItems, QuotationListColumn, QuotationTableItems } from 'app/types/components/quotationDetail'
import { ItemDisplayableEntriesColumn } from 'app/services/store/items/types'
import { getReportData } from 'app/services/store/utils/linkerApis'
import { QUOTATION_FORM_NAME } from 'app/constants/components/quotation'
import * as LinkerTypes from 'app/services/store/utils/linkerApis.types'
import {
  LowestPriceColumn,
  LowestPrice,
  LowestSalesColumn,
  LowestSales,
  ConstructionMethod,
  Quotation,
  ConstructionMasterItem,
  ElseItem,
} from 'app/services/store/quotationDetails/types'
import StringUtils from 'app/utils/stringUtils'
import DeleteRowButtonCreator from 'app/components/molecules/DeleteRowButton'

import { ROUTE_PATH } from 'app/constants/router'
import {
  FLAT_AND_RISE_EDITABLE_COLUMNS,
  OTHERS_EDITABLE_COLUMNS,
  QUOTATION_DISP_ID as DISP_ID,
  LOWEST_PRICE_MODAL_TABLE_LABEL,
} from 'app/constants/components/quotation'
import { RelatedDatastoreItem } from 'app/services/store/utils/linkerApis.types'

const BigNumber = require('bignumber.js');

export const currencyFormatter = (value: number): string => {
  return Number(value).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
}

export const commaFormatter = (value: number): string => {
  var ans = Number(value).toLocaleString( undefined, { maximumFractionDigits: 4 })
  if (value === undefined){
    return '0'
  }
  return ans
}


export const percentFormatter = new Intl.NumberFormat('ja', { style: 'percent', maximumFractionDigits: 2 })

export const addEditableSettings = (columns: QuotationListColumn[] , elsecolumns: QuotationListColumn[]): [QuotationListColumn[], QuotationListColumn[]] => {
  const flatAndRiseColumns = columns.map((column) => {
    if (FLAT_AND_RISE_EDITABLE_COLUMNS.includes(column.dataIndex)) {
      return {
        ...column,
        editable: false,
      }
    } else {
      return column
    }
  })

  const othersColumns = elsecolumns.map((column) => {
    if (OTHERS_EDITABLE_COLUMNS.includes(column.dataIndex)) {
      return {
        ...column,
        editable: false,
      }
    } else {
      return column
    }
  })
  return [flatAndRiseColumns, othersColumns]
}

// TODO: リファクタ
export const getSummariesResources = (
  constructionMethod: ConstructionMethod,
): {
  flatSummaryResource: SummaryResourcesItems
  riseSummaryResource: SummaryResourcesItems
  elseSummaryResource: SummaryResourcesItems
  constMethodSummaryResource: SummaryResourcesItems
} => {
  console.log("constructionMethod")
  console.log(constructionMethod)

  const {
    flatTabTotalSales,
    flatTabTotalPurchase,
    flatTabTotalGrossProfitMargin,
    flatM2UnitPrice,
  } = constructionMethod.items
  const {
    riseTabTotalSales,
    riseTabTotalPurchase,
    riseTabTotalGrossProfitMargin,
    riseM2UnitPrice,
  } = constructionMethod.items
  const { elseTabTotalSales, elseTabTotalPurchase, elseTabTotalGrossProfitMargin } = constructionMethod.items
  const { constTotalOfSales, constTotalOfPurchase, constTotalOfGrossProfitMargin } = constructionMethod.items

  const flatSummaryResource = {
    title: '平場計',
    color: 'rgb(70, 107, 169)',
    backgroundColor: 'rgb(244, 246, 250)',
    isEmphasizeSales: true,
    sumOfSales: flatTabTotalSales,
    sumOfCost: flatTabTotalPurchase,
    grossProfit: flatTabTotalGrossProfitMargin,
    unitCost: flatM2UnitPrice,
    withIcon: true,
  }
  const riseSummaryResource = {
    title: '立上計',
    color: 'rgb(70, 107, 169)',
    backgroundColor: 'rgb(244, 246, 250)',
    isEmphasizeSales: true,
    sumOfSales: riseTabTotalSales,
    sumOfCost: riseTabTotalPurchase,
    grossProfit: riseTabTotalGrossProfitMargin,
    unitCost: riseM2UnitPrice,
    withIcon: true,
  }
  const elseSummaryResource = {
    title: 'その他計',
    color: 'rgb(70, 107, 169)',
    backgroundColor: 'rgb(244, 246, 250)',
    isEmphasizeSales: true,
    sumOfSales: elseTabTotalSales,
    sumOfCost: elseTabTotalPurchase,
    grossProfit: elseTabTotalGrossProfitMargin,
    withIcon: true,
  }
  const constMethodSummaryResource = {
    title: '工法計',
    color: 'rgb(79, 157, 138)',
    backgroundColor: 'rgb(248, 255, 253)',
    sumOfSales: constTotalOfSales,
    sumOfCost: constTotalOfPurchase,
    grossProfit: constTotalOfGrossProfitMargin,
    withIcon: true,
  }

  return {
    flatSummaryResource,
    riseSummaryResource,
    elseSummaryResource,
    constMethodSummaryResource,
  }
}

export const getTotalSummaryResource = (
  siteTotalOfSales: number,
  siteTotalOfPurchase: number,
  siteTotalOfGrossProfitMargin: number,
) => {
  return {
    title: '現場計',
    color: 'rgb(70, 107, 169)',
    sumOfSales: siteTotalOfSales,
    sumOfCost: siteTotalOfPurchase,
    grossProfit: siteTotalOfGrossProfitMargin,
  }
}

const sortOrder = [
  'ステータス',
  'customerName',
  'siteName',
  'address',
  'siteTotalOfSales',
  'dateOfCreation',
  'deleteButton',
]

export const filterColumns = (columns: ItemDisplayableEntriesColumn[]): ItemDisplayableEntriesColumn[] =>
  columns
    .filter(
      (item) =>
        item.key === 'ステータス' ||
        item.key === 'customerName' ||
        item.key === 'siteName' ||
        item.key === 'address' ||
        item.key === 'siteTotalOfSales' ||
        item.key === 'dateOfCreation' ||
        item.key === 'deleteButton',
    )
    .map((item) => {
      if (item.dataIndex !== item.key) {
        item.dataIndex = item.key
      }
      return item
    })
    .reduce((prev: [] | ItemDisplayableEntriesColumn[], cur) => {
      sortOrder.forEach((order, idx) => {
        if (cur.key === order) {
          cur['order'] = idx + 1
        }
      })
      const temp = [...prev, cur]
      return temp.sort((a, b): number => {
        if (a['order'] && b['order'] && a['order'] > b['order']) {
          return 1
        } else {
          return -1
        }
      })
    }, [])

export const customizeColumns = (
  columns: QuotationListColumn[],
  history,
  isAdmin: boolean,
  userId: string,
  deleteRow: Function,
): QuotationListColumn[] => {
  const onCellValue = (record: QuotationListItems) => {
    return {
      onClick: (): void => {
        history.push({
          pathname: `${ROUTE_PATH.QUOTATION_EDIT}/${record.i_id}`,
          state: { iId: record.i_id },
        })
      },
    }
  }
  const temp = [
    ...columns,
    {
      title: '',
      dataIndex: 'deleteButton',
      key: 'deleteButton',
      id: '',
      type: '',
      render: (_: string, record: QuotationListItems): JSX.Element | undefined => {
        if (isAdmin || record['userId'] === userId) {
          const iId = record.i_id
          return DeleteRowButtonCreator({ iId, deleteRow })
        } else {
          return undefined
        }
      },
      width: 100,
    },
  ]

  const result = filterColumns(temp).map((column: QuotationListColumn) => {
    if (column.key !== 'deleteButton') {
      return {
        ...column,
        onCell: onCellValue,
      }
    } else {
      return {
        ...column,
      }
    }
  })

  return result as QuotationListColumn[]
}

export const filterItemsToUnapproved = (dataSource: QuotationListItems[]): QuotationListItems[] => {
  const result = dataSource.filter((item) => item.ステータス === '未承認')
  return result
}

export const getTabId = (): string => {
  return StringUtils.GenUUID()
}

export const convertItemToChanges = (item): LinkerTypes.Changes | undefined => {
  const changes = new Array<{ id: string; value: string | string[] }>()
  if (item) {
    Object.entries(item).forEach(([key, value]) => {
      if (value !== '' && value !== undefined) {
        return changes.push({
          id: key,
          value: value as string,
        })
      }
    })
    changes.push({ id: DISP_ID.LAST_UPDATE_DATE, value: new Date().toISOString() })
    return changes
  }
}

export const formatLowestPriceColumn = (
  columns: LowestPriceColumn[],
  ): {
  title: string
  dataIndex: string
  key: string
}[] => {
  const result: {
    title: string
    dataIndex: string
    key: string
  }[] = []
  columns.forEach((column) => {
    if (LOWEST_PRICE_MODAL_TABLE_LABEL.includes(column['title'])) {
      result.push({
        title: column['title'],
        dataIndex: column['display_id'],
        key: column['display_id'],
      })
    }
  }
  )
  return result
}

export const formatLowestPrice = (lowestPrice: LowestPrice[]): { [k: string]: string }[] => {
  const result: { [k: string]: string }[] = []
  lowestPrice.forEach((item, index) => {
    result.push({
      ...item,
      key: index.toString(),
    })
  })
  return result
}

export const formatLowestSalesColumn = (
  columns: LowestSalesColumn[],
  ): {
  title: string
  dataIndex: string
  key: string
}[] => {
  const result: {
    title: string
    dataIndex: string
    key: string
  }[] = []
  columns.forEach((column) => {
    if (LOWEST_PRICE_MODAL_TABLE_LABEL.includes(column['title'])) {
      result.push({
        title: column['title'],
        dataIndex: column['display_id'],
        key: column['display_id'],
      })
    }
  }
  )
  return result
}

export const formatLowestSales = (lowestSales: LowestSales[]): { [k: string]: string }[] => {
  const result: { [k: string]: string }[] = []
  lowestSales.forEach((item, index) => {
    result.push({
      ...item,
      key: index.toString(),
    })
  })
  return result
}

export const getLowestSalesAndPurchase = (
  columns: {
    title: string
    dataIndex: string
    key: string
  }[],
  lowestPrice: { [k: string]: string | number }[],
): { lowestSales: number; lowestPurchase: number } => {
  const resultOfSales =
    lowestPrice.length > 0
      ? lowestPrice[0][columns[columns.findIndex((column) => column.title === '売上')].dataIndex]
      : 0
  const resultOfPurchase =
    lowestPrice.length > 0
      ? lowestPrice[0][columns[columns.findIndex((column) => column.title === '仕入')].dataIndex]
      : 0

  return {
    lowestSales: Number(resultOfSales),
    lowestPurchase: Number(resultOfPurchase),
  }
}

export const setConstructionVolume = (
  dataSource: QuotationTableItems[],
  constructionVolume: number,
): QuotationTableItems[] => {
  const result = dataSource.map((item) => {
    return {
      ...item,
      constructionVolume: constructionVolume,
      quantity: item.internalCapacity
      ? Math.ceil((constructionVolume * item.amountUsed) / item.internalCapacity)
      : 0
    }
  })
  return result
}

export const quotatonStatusDeterminer = (quotation): number => {
  if (!quotation || !quotation['ステータス']) {
    return 0
  }
  if (quotation['ステータス'] === '作成済み') {
    return 0
  } else if (quotation['ステータス'] === '未承認') {
    return 1
  } else {
    return 2
  }
}

export const extractQuotationSummary = (
  quotation?: Quotation,
):
  | {
      siteTotalOfSales?: number
      siteTotalOfPurchase?: number
      siteTotalOfGrossProfitMargin?: number
    }
  | undefined => {
  if (quotation) {
    return {
      siteTotalOfSales: quotation.siteTotalOfSales,
      siteTotalOfPurchase: quotation.siteTotalOfPurchase,
      siteTotalOfGrossProfitMargin: quotation.siteTotalOfGrossProfitMargin,
    }
  } else {
    return undefined
  }
}

export const extractConstMethodSummaries = (
  constructionMethod?: ConstructionMethod | null,
):
  | {
      constTotalOfSales?: number
      constTotalOfPurchase?: number
      constTotalOfGrossProfitMargin?: number
      flatTabTotalSales?: number
      flatTabTotalPurchase?: number
      flatM2UnitPrice?: number
      flatTabTotalGrossProfitMargin?: number
      riseTabTotalSales?: number
      riseTabTotalPurchase?: number
      riseM2UnitPrice?: number
      riseTabTotalGrossProfitMargin?: number
      elseTabTotalSales?: number
      elseTabTotalPurchase?: number
      elseTabTotalGrossProfitMargin?: number
    }
  | undefined => {
  if (constructionMethod && constructionMethod.items) {
    return {
      constTotalOfSales: constructionMethod.items.constTotalOfSales,
      constTotalOfPurchase: constructionMethod.items.constTotalOfPurchase,
      constTotalOfGrossProfitMargin: constructionMethod.items.constTotalOfGrossProfitMargin,
      flatTabTotalSales: constructionMethod.items.flatTabTotalSales,
      flatTabTotalPurchase: constructionMethod.items.flatTabTotalPurchase,
      flatM2UnitPrice: constructionMethod.items.flatM2UnitPrice,
      flatTabTotalGrossProfitMargin: constructionMethod.items.flatTabTotalGrossProfitMargin,
      riseTabTotalSales: constructionMethod.items.riseTabTotalSales,
      riseTabTotalPurchase: constructionMethod.items.riseTabTotalPurchase,
      riseM2UnitPrice: constructionMethod.items.riseM2UnitPrice,
      riseTabTotalGrossProfitMargin: constructionMethod.items.riseTabTotalGrossProfitMargin,
      elseTabTotalSales: constructionMethod.items.elseTabTotalSales,
      elseTabTotalPurchase: constructionMethod.items.elseTabTotalPurchase,
      elseTabTotalGrossProfitMargin: constructionMethod.items.elseTabTotalGrossProfitMargin,
    }
  } else {
    return undefined
  }
}

export const getCatalogUrl = async (dataReportsId: string, selectedValue, form, setCatalogUrl) => {
  const response = await getReportData({ dataReportsId }).toPromise()

  const systemReportField = response.data.report_fields.find((item) => item.title === 'システム')
  const systemRpfId = systemReportField ? systemReportField.display_id : ''

  const constructionMethodReportField = response.data.report_fields.find((item) => item.title === '工法')
  const constructionMethodRpfId = constructionMethodReportField ? constructionMethodReportField.display_id : ''

  const urlReportField = response.data.report_fields.find((item) => item.title === 'URL')
  const urlRpfId = urlReportField ? urlReportField.display_id : ''

  const extractedField = response.data.report_results.find(
    (item) =>
      item[systemRpfId] === form.getFieldValue(QUOTATION_FORM_NAME.SYSTEM) &&
      item[constructionMethodRpfId] === selectedValue,
  )
  const result = extractedField ? extractedField[urlRpfId] : ''
  setCatalogUrl(result)
}

// 関連DB一括保存用のリクエストボディを作成する関数
export const createConstMethodRelatedDsItems = (
  dataSource,
  constructionMethod: ConstructionMethod,
  constIdText,
): RelatedDatastoreItem[] | undefined => {
  if (!dataSource.items || dataSource.items.length === 0) {
    return undefined
  }
  var dataSource2
  dataSource2 = []
  dataSource.items.forEach((item) => {
    if(item.productName){
      dataSource2.push(item)
    }
  })
  console.log("テストデータ2")
  console.log(dataSource)
  console.log(dataSource2)

  // 新規保存
  if (!dataSource.isSaved) {
    return (dataSource2 as ConstructionMasterItem[]).map((item) => {
      return {
        operation: 1,
        is_force_update: true,
        item: {
          amountUsed: Number(item.amountUsed),
          amountUsedUnit: item.amountUsedUnit,
          constructionVolume: Number(item.constructionVolume),
          constructionVolumeUnit: item.constructionVolumeUnit,
          grossProfitMargin: Number(item.grossProfitMargin),
          internalCapacity: Number(item.internalCapacity),
          internalCapacityUnit: item.internalCapacityUnit,
          productCode: item.productCode,
          productName: item.productName,
          productUnit: item.productUnit,
          purchase: Number(item.purchase),
          totalPurchase: Number(item.totalPurchase),
          quantity: Number(item.quantity),
          remarks: item.remarks,
          sales: Number(item.sales),
          totalSales: Number(item.totalSales),
          standardCapacity: item.standardCapacity,
          standardPurchase: Number(item.standardPurchase),
          constIdText: constIdText,
        },
      }
    })
  }

  // 更新 その他タブ以外
  if (dataSource2[0].tabType !== 'その他') {
    return (dataSource2 as ConstructionMasterItem[])
      .filter((item) => item.isEdited)
      .map((item) => {
        return {
          operation: 2,
          is_force_update: true,
          i_id: item.i_id,
          item: {
            amountUsed: Number(item.amountUsed),
            amountUsedUnit: item.amountUsedUnit,
            constructionVolume: Number(item.constructionVolume),
            constructionVolumeUnit: item.constructionVolumeUnit,
            grossProfitMargin: Number(item.grossProfitMargin),
            internalCapacity: Number(item.internalCapacity),
            internalCapacityUnit: item.internalCapacityUnit,
            productCode: item.productCode,
            productName: item.productName,
            productUnit: item.productUnit,
            purchase: Number(item.purchase),
            totalPurchase: Number(item.totalPurchase),
            quantity: Number(item.quantity),
            remarks: item.remarks,
            sales: Number(item.sales),
            totalSales: Number(item.totalSales),
            standardCapacity: item.standardCapacity,
            standardPurchase: Number(item.standardPurchase),
            constIdText: constIdText,
          },
        }
      })
  } else {
    // 更新 その他タブ
    return (dataSource2 as ElseItem[])
      .filter((item) => !(item.isSaved && !item.isEdited)) // 保存済みで編集してないアイテムは除外
      .map((item) => {
        if (item.isEdited) {
          return {
            operation: 2,
            is_force_update: true,
            i_id: item.i_id,
            item: {
              amountUsed: Number(item.amountUsed),
              amountUsedUnit: item.amountUsedUnit,
              constructionVolume: Number(item.constructionVolume),
              constructionVolumeUnit: item.constructionVolumeUnit,
              grossProfitMargin: Number(item.grossProfitMargin),
              internalCapacity: Number(item.internalCapacity),
              internalCapacityUnit: item.internalCapacityUnit,
              productCode: item.productCode,
              productName: item.productName,
              productUnit: item.productUnit,
              purchase: Number(item.purchase),
              totalPurchase: Number(item.totalPurchase),
              quantity: Number(item.quantity),
              remarks: item.remarks,
              sales: Number(item.sales),
              totalSales: Number(item.totalSales),
              standardCapacity: item.standardCapacity,
              standardPurchase: Number(item.standardPurchase),
              constIdText: constIdText,
            },
          }
        } else {
          return {
            operation: 1,
            is_force_update: true,
            item: {
              amountUsed: Number(item.amountUsed),
              amountUsedUnit: item.amountUsedUnit,
              constructionVolume: Number(item.constructionVolume),
              constructionVolumeUnit: item.constructionVolumeUnit,
              grossProfitMargin: Number(item.grossProfitMargin),
              internalCapacity: Number(item.internalCapacity),
              internalCapacityUnit: item.internalCapacityUnit,
              productCode: item.productCode,
              productName: item.productName,
              productUnit: item.productUnit,
              purchase: Number(item.purchase),
              totalPurchase: Number(item.totalPurchase),
              quantity: Number(item.quantity),
              remarks: item.remarks,
              sales: Number(item.sales),
              totalSales: Number(item.totalSales),
              standardCapacity: item.standardCapacity,
              standardPurchase: Number(item.standardPurchase),
              constIdText: constIdText,
            },
          }
        }
      })
  }
}

// 平場・立上り・その他の金額、数量などの端数計算
export const calcFraction = (dataResource?) => {
  if (!dataResource && !dataResource.items) {
    return undefined
  } else {
    const result = dataResource.items.map((item) => {

      const constructionVolume = item.constructionVolume ? Math.floor(BigNumber(item.constructionVolume).times(10000)) / 10000 : 0
      const amountUsed = item.amountUsed ? Math.floor(BigNumber(item.amountUsed).times(10000)) / 10000 : 0
      const internalCapacity = item.internalCapacity ? Math.floor(BigNumber(item.internalCapacity).times(10000)) / 10000 : 0
      const quantity = item.quantity ? item.quantity : 0
      const purchase = item.purchase ? Math.floor(item.purchase) : 0
      const totalPurchase = (item.purchase ? item.purchase : 0) * quantity
      const sales = item.sales ? Math.floor(item.sales) : 0
      const totalSales = (item.sales ? item.sales : 0) * quantity

      return {
        ...item,
        quantity,
        constructionVolume,
        amountUsed,
        internalCapacity,
        purchase,
        totalPurchase,
        sales,
        totalSales,
      }
    })
    return {
      ...dataResource,
      items: result,
    }
  }
}

// 平場・立上り・その他の金額、数量などの端数計算
export const calcFraction2 = (dataResource?) => {
  if (!dataResource && !dataResource.items) {
    return undefined
  } else {
    const result = dataResource.items.map((item) => {

      const constructionVolume = item.constructionVolume ? Math.floor(BigNumber(item.constructionVolume).times(10000)) / 10000 : 0
      const amountUsed = item.amountUsed ? Math.floor(BigNumber(item.amountUsed).times(10000)) / 10000 : 0
      const internalCapacity = item.internalCapacity ? Math.floor(BigNumber(item.internalCapacity).times(10000)) / 10000 : 0
      const quantity = item.quantity ? item.quantity : 0
      const purchase = item.purchase ? Math.floor(item.purchase) : 0
      const totalPurchase = (item.purchase ? item.purchase : 0) * quantity
      const sales = item.sales ? Math.floor(item.sales) : 0
      const totalSales = (item.sales ? item.sales : 0) * quantity
      const isEdited = true

      return {
        ...item,
        quantity,
        constructionVolume,
        amountUsed,
        internalCapacity,
        purchase,
        totalPurchase,
        sales,
        totalSales,
        isEdited,
      }
    })
    return {
      ...dataResource,
      items: result,
    }
  }
}

export const sortItemsByDate = (dataSource: { [k: string]: any }[]): { [k: string]: any }[] => {
  const result = dataSource.sort((a, b) => {
    const aDate = a['lastUpdateDate'] ? a['lastUpdateDate'].replace(/-/g, '/') : a['dateOfCreation']
    const bDate = b['lastUpdateDate'] ? b['lastUpdateDate'].replace(/-/g, '/') : b['dateOfCreation']
    
    if (aDate < bDate) {
      return 1
    } else if (aDate > bDate) {
      return -1
    }
    return 0
  })
  return result
}
