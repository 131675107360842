import { initialState, SystemState } from 'app/services/store/system/state'
import { Project } from 'app/services/store/system/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { Workspace } from './types'

export const setCurrentWorkspaceIdRequest = (
  state: SystemState,
  action: PayloadAction<{ workspaceId: string }>,
): SystemState => {
  return {
    ...state,
    loading: true,
  }
}

export const setCurrentWorkspaceIdSuccess = (
  state: SystemState,
  action: PayloadAction<{ workspaceId: string }>,
): SystemState => {
  return {
    ...state,
    current_workspace_id: action.payload.workspaceId,
    loading: false,
  }
}

export const setCurrentWorkspaceIdFailed = (
  state: SystemState,
  action: PayloadAction<{ error: string }>,
): SystemState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getWorkspacesRequest = (state: SystemState): SystemState => {
  return {
    ...state,
    loading: true,
  }
}

export const getWorkspacesSuccess = (
  state: SystemState,
  action: PayloadAction<{ workspaces: Array<Workspace> }>,
): SystemState => {
  return {
    ...state,
    loading: false,
    workspaces_list: action.payload.workspaces,
    error: '',
  }
}

export const getWorkspacesFailed = (state: SystemState, action: PayloadAction<{ error: string }>): SystemState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const setCurrentProjectId = (state: SystemState, action: PayloadAction<{ projectId: string }>): SystemState => {
  return {
    ...state,
    current_project_id: action.payload.projectId,
  }
}

export const getProjectsRequest = (state: SystemState, action: PayloadAction<{ workspaceId: string }>): SystemState => {
  return {
    ...state,
    loading: true,
  }
}

export const getProjectsSuccess = (
  state: SystemState,
  action: PayloadAction<{ projects: Array<Project> }>,
): SystemState => {
  return {
    ...state,
    project_list: action.payload.projects,
    loading: false,
    error: '',
  }
}

export const getProjectsFailed = (state: SystemState, action: PayloadAction<{ error: string }>): SystemState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const navigate = (state: SystemState, action: PayloadAction<{ path: string }>): SystemState => {
  return {
    ...state,
  }
}

export const reset = (state: SystemState): SystemState => {
  return {
    ...initialState,
  }
}
