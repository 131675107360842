export const ROUTE_PATH = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password/:id',
  QUOTATION_LIST: '/quotation',
  QUOTATION_SEARCH: '/quotation/search',
  QUOTATION_SEARCH_RESULT: '/quotation/search/result',
  QUOTATION_CREATE: '/quotation/create',
  QUOTATION_EDIT: '/quotation/edit',
  QUOTATION_COPY: '/quotation/copy',
}
