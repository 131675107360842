import { notification } from 'antd'
import i18n from 'i18next'

import AuthHelper from 'app/services/authHelper/authHelper'
import StringUtils from 'app/utils/stringUtils'
import { ERROR_MESSAGES } from 'app/constants/system'
import { ROUTE_PATH } from 'app/constants/router'

/**
 * @namespace
 * @property {string} [id]              - Id of the error
 * @property {string} message           - The error message to display
 * @property {number} time              - The time when the error happened
 * @property {string} [origin]          - the origin of the error
 */
interface Error {
  id?: string
  message: string
  time: number
  origin?: string
}

export class ErrorHandlerService {
  /** @static @property {Array<Error>} errors   - list of errors */
  static errors = new Array<Error>()

  /**
   * @static
   * @param  {string} errorId the id ot the error to display
   *
   * @description display the selected error to the user
   *
   * @returns {void}
   */
  static displayError(errorId: string): void {
    const error = this.errors.find((err) => err.id === errorId)
    if (!error) {
      return
    }
    notification['error']({
      message: i18n.t(error.message),
      duration: 4000,
    })
  }

  /**
   * @static
   * @param  {Error} error the error to add
   * @param  {boolean} [display] do we also want to display the error
   *
   * @description add an error to the Error Handler and display it to the user if necessary
   *
   * @returns {string}
   */
  static addError(error: Error, display?: boolean): string {
    if (!error.id) {
      error.id = StringUtils.GenUUID()
    }
    this.errors.unshift(error)

    if (display) {
      this.displayError(error.id)
    }

    return error.id
  }

  /**
   * @static
   * @param  {string} errorId the id ot the error to remove
   *
   * @description remove the selected error from the list
   *
   * @returns {void}
   */
  static removeError(errorId: string): void {
    this.errors = this.errors.filter((err) => err.id !== errorId)
  }

  static createErrorMsgFromStatusCode(code: number): string {
    if (window.location.pathname === ROUTE_PATH.LOGIN && code === 401) {
      return ERROR_MESSAGES.INVALID_LOGIN
    } else if (window.location.pathname === ROUTE_PATH.LOGIN && code === 500) {
      return ERROR_MESSAGES.INVALID_LOGIN
    } else if (code === 401) {
      AuthHelper.logout()
      return ERROR_MESSAGES.TOKEN_EXPIRED
    }
    return ERROR_MESSAGES.INTERNAL_SERVERERROR
  }
}
