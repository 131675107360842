import React from 'react'
import { Row, Col } from 'antd'
import { StockOutlined, ShoppingCartOutlined } from '@ant-design/icons'

import { currencyFormatter, percentFormatter } from 'app/utils/components/quotation/quotation'

import Frame from 'app/components/atoms/Frame'

interface SummaryProps {
  resources: {
    title: string
    color?: string
    backgroundColor?: string
    isEmphasizeSales?: boolean
    sumOfSales?: number
    sumOfCost?: number
    grossProfit?: number
    unitCost?: number
    withIcon?: boolean
  }
}
export const Summary = ({
  resources: {
    title,
    color,
    backgroundColor,
    isEmphasizeSales,
    sumOfSales,
    sumOfCost,
    grossProfit,
    unitCost,
    withIcon,
  },
}: SummaryProps): JSX.Element => {
  return (
    <Frame color={color} backgroundColor={backgroundColor}>
      <Row>
        <Col span={unitCost === undefined ? 3 : 2}>{title}</Col>
        <Col span={unitCost === undefined ? 8 : 5}>
          <span style={{ color: isEmphasizeSales ? 'rgb(249, 133, 112)' : color }}>
            {withIcon && <StockOutlined />}売上計 {sumOfSales && currencyFormatter(sumOfSales)}
          </span>
        </Col>
        <Col offset={unitCost === undefined ? 0 : 1} span={unitCost === undefined ? 8 : 5}>
          {withIcon && <ShoppingCartOutlined />}仕入計 {sumOfCost && currencyFormatter(sumOfCost)}
        </Col>
        {unitCost !== undefined && (
          <Col offset={1} span={5}>
            ㎡ 単価 {unitCost && currencyFormatter(unitCost)}
          </Col>
        )}
        <Col offset={unitCost === undefined ? 0 : 1}>
          粗利率 <span style={{ fontWeight: 'bold' }}>{grossProfit && percentFormatter.format(grossProfit)}</span>
        </Col>
      </Row>
    </Frame>
  )
}

export default Summary
