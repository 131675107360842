import React from 'react'
import { Row, Col } from 'antd'

import { ROUTE_PATH } from 'app/constants/router'
import PlusButton from 'app/components/molecules/PlusButton'
import SearchButton from 'app/components/molecules/SearchButton'

interface CreateAndSearchButtonsProps {
  setSearchModalVisibility: Function
}
const CreateAndSearchButtons = ({ setSearchModalVisibility }: CreateAndSearchButtonsProps): JSX.Element => {
  return (
    <Row justify="end">
      <Col span={10}>
        <PlusButton label="新規作成" pathname={ROUTE_PATH.QUOTATION_CREATE} />
      </Col>
      <Col span={2}>/</Col>
      <Col span={10}>
        <SearchButton label="履歴検索" handleOpen={setSearchModalVisibility} />
      </Col>
    </Row>
  )
}

export default CreateAndSearchButtons
