import { createEpicMiddleware, combineEpics } from 'redux-observable'
import UserEpics from './user/epic'
import SystemEpics from './system/epic'
import ItemsEpics from './items/epic'
import DatastoreEpics from './datastore/epic'
import ItemDetailsEpics from './itemDetails/epic'
import QuotationDetailsEpic from './quotationDetails/epic'

export const rootEpic = combineEpics(
  ...UserEpics,
  ...SystemEpics,
  ...DatastoreEpics,
  ...ItemsEpics,
  ...ItemDetailsEpics,
  ...QuotationDetailsEpic,
)

const epicMiddleware = createEpicMiddleware()

export default epicMiddleware
