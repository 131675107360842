import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { UsersSelectors, QuotationDetailsSelectors, QuotationDetailsActions } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'

import AppLayout from 'app/components/templates/AppLayout'
import QuotationDetailPage from 'app/components/pages/quotation/QuotationDetailPage'
import { DATASTORE_ID } from 'app/constants/hexabase'
import { quotatonStatusDeterminer } from 'app/utils/components/quotation/quotation'

const CopyQuotationDetailContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const constructionMethods = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))
  const quotationNumber = QuotationDetailsSelectors.getQuotationNumber(useSelector(rootSelector))
  const userName = UsersSelectors.getUsername(useSelector(rootSelector))
  const userEmail = UsersSelectors.getUserEmail(useSelector(rootSelector))

  useEffect(() => {
    dispatch(QuotationDetailsActions.getConstructionMethodFields({ datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD }))
    return () => {
      dispatch(QuotationDetailsActions.reset())
    }
  }, [dispatch])

  return (
    <AppLayout>
      <QuotationDetailPage
        mode="copy"
        isDisabled={false}
        quotationStatus={quotatonStatusDeterminer(quotation)}
        quotationNumber={quotationNumber}
        userName={quotation.personInChargeName || userName}
        userEmail={quotation.personInChargeEmail || userEmail}
        isCustomerInfoSaved={!!quotation['i_id']}
        constructionDataResources={constructionMethods}
      />
    </AppLayout>
  )
}

export default CopyQuotationDetailContainer
