import React from 'react'
import { useHistory } from 'react-router'
import { Row, Col } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

interface PlusButtonProps {
  pathname: string
  label: string
}

const PlusButton = ({ pathname, label }: PlusButtonProps): JSX.Element => {
  const history = useHistory()

  return (
    <Row
      onClick={(): void => {
        history.push({ pathname })
      }}
      style={{ fontSize: '17px', cursor: 'pointer' }}
    >
      <Col span={4}>
        <PlusCircleOutlined />
      </Col>
      <Col offset={1} span={19}>
        <span>{label}</span>
      </Col>
    </Row>
  )
}

export default PlusButton
