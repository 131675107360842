/**
 * @static
 *
 * @description generate a Unique ID
 *
 * @todo Should garantee the id is unique
 *
 * @returns {string}
 */
export default class StringUtils {
  public static GenUUID(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }
}
