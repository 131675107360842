import React, { ReactNode } from 'react'

interface TagProps {
  children: ReactNode
  color?: string
  backgroundColor?: string
  style?: Record<string, any>
}
export const Frame = ({ children, color, backgroundColor, style }: TagProps): JSX.Element => {
  return (
    <div
      style={{
        height: '40px',
        padding: '8px 25px',
        borderRadius: '10px',
        border: '1px solid',
        borderColor: color,
        color: color,
        backgroundColor: backgroundColor ? backgroundColor : 'white',
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Frame
