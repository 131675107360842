import React from 'react'

import { STATUS_LABELS } from 'app/constants/components/quotation'
import StatusBlock from 'app/components/atoms/StatusBlock'

interface StatusBarProps {
  currentStatus?: number
}
export const StatusBar = ({ currentStatus }: StatusBarProps): JSX.Element => {
  return (
    <div>
      {STATUS_LABELS.map((status, index) => (
        <StatusBlock
          label={status}
          isCurrent={index === currentStatus ? true : false}
          noStatus={currentStatus === undefined}
          isStart={index === 0}
          isEnd={index + 1 === STATUS_LABELS.length}
          key={index}
        />
      ))}
    </div>
  )
}

export default StatusBar
