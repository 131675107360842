import React, { useState, useEffect, useRef } from 'react'
import { Input, InputNumber } from 'antd'

import { QuotationTableItems } from 'app/types/components/quotationDetail'
import { commaFormatter } from 'app/utils/components/quotation/quotation'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string
  title: string
  inputType: 'number' | 'text'
  record: QuotationTableItems
  editable: boolean
  children: React.ReactNode
  form
  handleUpdateOriginData: Function
}
const EditableCell = ({
  dataIndex,
  inputType,
  record,
  editable,
  handleUpdateOriginData,
  ...restProps
}: EditableCellProps): JSX.Element => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>()

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = (): void => {
    if (editable) {
      setEditing(!editing)
    }
  }

  const save = (value?: string | number) => {
    const targetIid = record.i_id
    const tabType = record.tabType
    const updatedValues = [
      {
        dataIndex,
        value,
      },
    ]
    handleUpdateOriginData(tabType, targetIid, updatedValues)
  }

  const handleBlur = (value?: string | number): void => {
    console.log(value)
    save(value)
    toggleEdit()
  }
  return (
    <td {...restProps}>
      {editing ? (
        inputType === 'number' ? (
          <InputNumber
            ref={inputRef}
            onBlur={(e): void => handleBlur(Number(e.target.value))}
            onPressEnter={(e): void => handleBlur(Number(e.currentTarget.value))}
            defaultValue={record ? record[dataIndex] : ''}
          />
        ) : (
          <Input
            ref={inputRef}
            onBlur={(e): void => handleBlur(e.target.value)}
            onPressEnter={(e): void => handleBlur(e.currentTarget.value)}
            defaultValue={record ? record[dataIndex] : ''}
          />
        )
      ) : (
        <div className="editable-cell-value-wrap" style={{ padding: '10px' }} onClick={toggleEdit}>
          {
            /* FixMe: 三項演算子を使用しない記述にする */
            record
              ? dataIndex === 'grossProfitMargin'
                ? record[dataIndex]
                  ? commaFormatter(Number(Math.floor(Number(record[dataIndex]) * 1000) / 10)) + '%'
                  : '0%'
              : dataIndex === 'quantity' ? commaFormatter(record[dataIndex])
              : dataIndex === 'constructionVolume' ? commaFormatter(record[dataIndex])
              : dataIndex === 'amountUsed' ? commaFormatter(record[dataIndex])
              : dataIndex === 'internalCapacity' ? commaFormatter(record[dataIndex])
              : dataIndex === 'sales' ? commaFormatter(record[dataIndex])
              : dataIndex === 'purchase' ? commaFormatter(record[dataIndex])
              : dataIndex === 'totalSales' ? commaFormatter(record[dataIndex]!!)
              : dataIndex === 'totalPurchase' ? commaFormatter(record[dataIndex]!!)
              : dataIndex === 'standardPurchase' ? commaFormatter(record[dataIndex])
              : record[dataIndex]
            : ''
          }
        </div>
      )}
    </td>
  )
}

export default EditableCell
