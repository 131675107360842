import React, { useCallback, memo } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { Row, Col, Button } from 'antd'
import 'antd/dist/antd.css'

import { UsersActions } from 'app/services/store'

import kakenLogo from '../../../../assets/images/kakenLogo.png'
import { ROUTE_PATH } from 'app/constants/router'

const CommonHeader = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const logout = React.useCallback(() => {
    dispatch(UsersActions.logout())
  }, [dispatch])

  const navigateQuotationList = useCallback(() => {
    history.push(ROUTE_PATH.QUOTATION_LIST)
  }, [history])

  return (
    <Row style={{ marginBottom: '50px' }}>
      <Col span={22}>
        <Button onClick={navigateQuotationList} type="link">
          <div className="logo">
            <img src={kakenLogo} alt="化研マテリアル" />
          </div>
        </Button>
      </Col>
      <Col span={2}>
        <Button size="large" type="primary" onClick={logout}>
          ログアウト
        </Button>
      </Col>
    </Row>
  )
}

export default memo(CommonHeader)
