import produce from 'immer'
import { Moment } from 'moment'
import { PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { EMPTY_FLAT_AND_RISE_RESOURCE, ACTION_ID } from 'app/constants/components/quotation'
import { getTabId } from 'app/utils/components/quotation/quotation'
import { QuotationTableItems } from 'app/types/components/quotationDetail'

import { calculateSummaries } from 'app/utils/store/quotationDetails/index'

import { QuotationDetailsState, initialState } from './state'
import * as Types from 'app/services/store/quotationDetails/types'
import StringUtils from 'app/utils/stringUtils'

export const reset = (state: QuotationDetailsState): QuotationDetailsState => {
  return { ...initialState, constructionMethodFields: state.constructionMethodFields }
}

// TODO: Abstruct these two methods when three tabs is implemented
export const addConstructionMethod = (state: QuotationDetailsState): QuotationDetailsState => {
  const tabId = getTabId()
  const emptyConstructionMethod = {
    items: {},
    tabId: tabId,
  }

  return produce(state, (draft) => {
    if (draft['constructionMethod'].length < 30) {
      draft['constructionMethod'].push(emptyConstructionMethod)
      draft['flat'].push({ items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId: tabId, isSaved: false })
      draft['rise'].push({ items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId: tabId, isSaved: false })
      draft['else'].push({ items: [], tabId: tabId, isSaved: false })
    }
  })
}

// DBに保存されていない工法データをstore上から削除
export const deleteConstructionMethod = (
  state: QuotationDetailsState,
  action: PayloadAction<{ tabId: string }>,
): QuotationDetailsState => {
  const tabId = action.payload.tabId
  const constIndex = state.constructionMethod.findIndex((item: { tabId: string }) => item.tabId === tabId)
  const flatIndex = state.flat.findIndex((item: { tabId: string }) => item.tabId === tabId)
  const riseIndex = state.rise.findIndex((item: { tabId: string }) => item.tabId === tabId)
  const elseIndex = state.else.findIndex((item: { tabId: string }) => item.tabId === tabId)

  return produce(state, (draft) => {
    draft['constructionMethod'].splice(constIndex, 1)
    if (flatIndex !== -1) {
      draft['flat'].splice(flatIndex, 1)
    }
    if (riseIndex !== -1) {
      draft['rise'].splice(riseIndex, 1)
    }
    if (elseIndex !== -1) {
      draft['else'].splice(elseIndex, 1)
    }
  })
}

export const updateQuotationAndConstruction: CaseReducer<
  QuotationDetailsState,
  PayloadAction<Types.UpdateQuotationAndConstructionAction>
> = (state): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const updateQuotationAndConstructionSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.UpdateQuotationAndConstructionSuccess>,
): QuotationDetailsState => {
  const { targetState, tabId, result } = action.payload

  return produce(state, (draft) => {
    draft['loading'] = false
    if (targetState === 'quotation') {
      draft[targetState] = result
    } else {
      draft[targetState][draft[targetState].findIndex((item: { tabId: string }) => item.tabId === tabId)] = {
        items: { ...result },
        tabId,
      }
    }
  })
}

export const updateQuotationAndConstructionFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<{ error: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const createRequest = (
  state: QuotationDetailsState,
  action: PayloadAction<{
    datastoreId: string
    item: { [key: string]: string | Moment }
    userId: string
    tabId?: string
  }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const createSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<{ items: { [key: string]: string }; targetState: string; tabId?: string }>,
): QuotationDetailsState => {
  const { items, targetState, tabId } = action.payload

  return produce(state, (draft) => {
    draft['loading'] = false
    draft[targetState][draft[targetState].findIndex((item: { tabId: string }) => item.tabId === tabId)] = {
      items,
      tabId,
    }
  })
}

export const createQuotation = (
  state: QuotationDetailsState,
  action: PayloadAction<{ item: { [key: string]: string | Moment } }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const createQuotationSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<{ items: { [key: string]: string } }>,
): QuotationDetailsState => {
  const { items } = action.payload

  return produce(state, (draft) => {
    draft['loading'] = false
    draft['quotation'] = items
  })
}

export const createFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<{ error: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

// constructionMethod DBから関連DBも含めてitemを削除する
export const deleteConstructionMethodFromDB = (
  state: QuotationDetailsState,
  action: PayloadAction<{ datastoreId: string; itemId: string; tabId: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const deleteConstructionMethodFromDBSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<{ tabId: string }>,
): QuotationDetailsState => {
  const { tabId } = action.payload
  const constIndex = state.constructionMethod.findIndex((item: { tabId: string }) => item.tabId === tabId)
  const flatIndex = state.flat.findIndex((item: { tabId: string }) => item.tabId === tabId)
  const riseIndex = state.rise.findIndex((item: { tabId: string }) => item.tabId === tabId)
  const elseIndex = state.else.findIndex((item: { tabId: string }) => item.tabId === tabId)

  return produce(state, (draft) => {
    draft['loading'] = false
    draft['constructionMethod'].splice(constIndex, 1)
    if (flatIndex !== -1) {
      draft['flat'].splice(flatIndex, 1)
    }
    if (riseIndex !== -1) {
      draft['rise'].splice(riseIndex, 1)
    }
    if (elseIndex !== -1) {
      draft['else'].splice(elseIndex, 1)
    }
  })
}

export const deleteConstructionMethodFromDBFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<{ error: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const deleteQuotation: CaseReducer<QuotationDetailsState, PayloadAction<Types.QuotationDeleteAction>> = (
  state,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const deleteQuotationSuccess: CaseReducer<QuotationDetailsState, PayloadAction<Types.QuotationDeleteSuccess>> = (
  state,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}

// TODO: 正しい型定義
export const getFlatRiseMasterData = (
  state: QuotationDetailsState,
  action: PayloadAction<{
    constructionMethod: string
    specificationCodeFlat: string
    specificationCodeRise: string
    constructionVolumeFlat: number
    constructionVolumeRise: number
    system: string
    tabId: string
  }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const getFlatRiseMasterDataSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<{ flat: any; rise: any; tabId: string; isSaved: boolean }>,
): QuotationDetailsState => {
  const { flat, rise, tabId, isSaved } = action.payload

  flat.forEach((item) => {
    item.isEdited = false
    item.constructionVolumeUnit = '㎡'
    item.purchase = item.standardPurchase
  })

  rise.forEach((item) => {
    item.isEdited = false
    item.constructionVolumeUnit = '㎡'
    item.purchase = item.standardPurchase
  })

  return produce(state, (draft) => {
    draft['loading'] = false
    draft['flat'][draft['flat'].findIndex((item) => item.tabId === tabId)] = {
      items: flat,
      tabId,
      isSaved,
    }
    draft['rise'][draft['rise'].findIndex((item) => item.tabId === tabId)] = {
      items: rise,
      tabId,
      isSaved,
    }
  })
}

export const getFlatRiseMasterDataFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<{ error: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const customerSearch = (
  state: QuotationDetailsState,
  action: PayloadAction<{ customerName: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['customerSearchLoading'] = true
  })
}

export const customerSearchSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<{ customerSearchResult: Types.CustomerSearchResult[] }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['customerSearchLoading'] = false
    draft['customerSearchResult'] = action.payload.customerSearchResult
  })
}

export const customerSearchFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<{ error: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['customerSearchLoading'] = false
    draft['error'] = action.payload.error
  })
}

export const genQuitationId = (state: QuotationDetailsState): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const genQuitationIdSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<{ quotationNumber: string }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['quotation']['quotationNum'] = action.payload.quotationNumber
  })
}

// TODO: 本来は保存も実行するため、Epicを作ってAPIコールも実行すべき
export const calculateConstMethodSummaries: CaseReducer<QuotationDetailsState, PayloadAction<{ tabId: string }>> = (
  state,
  action,
): QuotationDetailsState => {
  const tabId = action.payload.tabId
  const targetTabIndex = state['constructionMethod'].findIndex((item) => item.tabId === tabId)
  const unit = targetTabIndex !== -1 ? state['flat'][targetTabIndex].items[0].constructionVolume : 0
  const unit2 = targetTabIndex !== -1 ? state['raise'][targetTabIndex].items[0].constructionVolume : 0
  const flatResources = state['flat'][targetTabIndex].items
  const riseResources = state['rise'][targetTabIndex].items
  const elseResources = state['else'][targetTabIndex].items
  const constMethodSummaries = calculateSummaries(unit, unit2, flatResources, riseResources, elseResources)
  const updatedConstructionMethod = { ...constMethodSummaries }

  return produce(state, (draft) => {
    Object.entries(updatedConstructionMethod).forEach(([key, value]) => {
      draft['constructionMethod'][targetTabIndex].items[key] = value
    })
  })
}

export const editFlatRiseElse = (
  state: QuotationDetailsState,
  action: PayloadAction<{
    tabType: '平場' | '立上り' | 'その他'
    tabId: string
    targetIid: any
    updatedValues: { dataIndex: string; value: string | number }[]
  }>,
): QuotationDetailsState => {
  const { tabType, tabId, targetIid, updatedValues } = action.payload
  const targetTabType = tabType === '平場' ? 'flat' : tabType === '立上り' ? 'rise' : 'else'
  const targetTab = state[targetTabType].findIndex((item) => item.tabId === tabId)
  const targetRow = state[targetTabType][targetTab].items.findIndex((item) => item.i_id === targetIid)

  return produce(state, (draft) => {
    updatedValues.forEach((item) => {
      draft[targetTabType][targetTab].items[targetRow][item.dataIndex] = item.value
      draft[targetTabType][targetTab].items[targetRow]['isEdited'] = true
    })
  })
}


// 工法・平場・立上り・その他の保存
export const savelowestpriceFlatRiseElse: CaseReducer<QuotationDetailsState, PayloadAction<Types.SavelowestpriceFlatRizeElseAction>> = (
  state,
  action,
) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const savelowestpriceFlatRiseElseSuccess: CaseReducer<
  QuotationDetailsState,
  PayloadAction<Types.SavelowestpriceFlatRizeElseSuccess>
> = (state, action) => {
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}

export const savelowestpriceFlatRiseElseFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  const { error } = action.payload
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = error
  })
}

// 工法・平場・立上り・その他の保存
export const saveOneItem: CaseReducer<QuotationDetailsState, PayloadAction<Types.SaveOneItemAction>> = (
  state,
) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const saveOneItemSuccess: CaseReducer<
  QuotationDetailsState,
  PayloadAction<Types.SaveOneItemSuccess>
> = (state, action) => {
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}
  
export const saveOneItemFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  const { error } = action.payload
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = error
  })
}

// 工法・平場・立上り・その他の保存
export const saveAddItem: CaseReducer<QuotationDetailsState, PayloadAction<Types.SaveAddItemAction>> = (
  state,
) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const saveAddItemSuccess: CaseReducer<
  QuotationDetailsState,
  PayloadAction<Types.SaveAddItemSuccess>
> = (state, action) => {
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}
  
export const saveAddItemFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  const { error } = action.payload
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = error
  })
}

export const editEntireFlatRiseElse = (
  state: QuotationDetailsState,
  action: PayloadAction<{
    tabId: string
    updatedValues: {
      flatResources?: QuotationTableItems[] | undefined
      riseResources?: QuotationTableItems[] | undefined
      elseResources?: QuotationTableItems[]
    }
  }>,
): QuotationDetailsState => {
  const { tabId, updatedValues } = action.payload
  const { flatResources, riseResources, elseResources } = updatedValues
  const targetConstMethodTab = state['flat'].findIndex((item) => item.tabId === tabId)

  return produce(state, (draft) => {
    if (flatResources) {
      // 編集チェックフラグを追加
      flatResources.forEach((item) => (item.isEdited = true))

      draft['flat'][targetConstMethodTab].items = flatResources
    }
    if (riseResources) {
      // 編集チェックフラグを追加
      riseResources.forEach((item) => (item.isEdited = true))

      draft['rise'][targetConstMethodTab].items = riseResources
    }
    if (elseResources) {
      // 編集チェックフラグを追加
      elseResources.forEach((item) => (item.isEdited = true))

      draft['else'][targetConstMethodTab].items = elseResources
    }
  })
}

export const getLowestPriceRequest = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.GetLowestPriceRequest>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const getLowestPriceSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.GetLowestPriceSuccess>,
): QuotationDetailsState => {
  const { target, datareportResult } = action.payload
  return produce(state, (draft) => {
    if (datareportResult) {
      draft['lowestPrices'][target]['columns'] = datareportResult.report_fields
      draft['lowestPrices'][target]['price'] = datareportResult.report_results
    }
    draft['loading'] = false
  })
}

export const getLowestPriceFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.GetLowestPriceFailed>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const getLowestSalesRequest = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.GetLowestSalesRequest>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const getLowestSalesSuccess = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.GetLowestSalesSuccess>,
): QuotationDetailsState => {
  const { target, datareportResult } = action.payload
  return produce(state, (draft) => {
    if (datareportResult) {
      draft['lowestSaless'][target]['columns'] = datareportResult.report_fields
      draft['lowestSaless'][target]['price'] = datareportResult.report_results
    }
    draft['loading'] = false
  })
}

export const getLowestSalesFailed = (
  state: QuotationDetailsState,
  action: PayloadAction<Types.GetLowestSalesFailed>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const addElseRow = (
  state: QuotationDetailsState,
  action: PayloadAction<{
    tabId: string
    elseResources: any // TODO: 正しい型づけ
  }>,
): QuotationDetailsState => {
  const { tabId, elseResources } = action.payload
  const targetConstMethodTab = state['else'].findIndex((item) => item.tabId === tabId)
  const elseItem = {
    ...elseResources,
    amountUsed: 1,
    internalCapacity: 1,
    constructionVolumeUnit: '㎡',
    purchase: elseResources.standardPurchasePrice,
    isSaved: false,
    isEdited: false,
  }
  return produce(state, (draft) => {
    draft['else'][targetConstMethodTab].items.push(elseItem)
  })
}

export const deleteElseRow = (
  state: QuotationDetailsState,
  action: PayloadAction<{
    tabId: string
    iId: string
    isSaved: boolean
  }>,
): QuotationDetailsState => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const deleteElseRowSuccess: CaseReducer<QuotationDetailsState, PayloadAction<{ tabId: string; iId: string }>> = (
  state,
  action,
) => {
  const { tabId, iId } = action.payload
  const targetConstMethodTab = state['else'].findIndex((item) => item.tabId === tabId)
  const targetRow = state['else'][targetConstMethodTab].items.findIndex((item) => item.i_id === iId)

  return produce(state, (draft) => {
    draft['loading'] = false
    draft['else'][targetConstMethodTab].items.splice(targetRow, 1)
  })
}

// 工法・平場・立上り・その他の保存
export const saveFlatRiseElse: CaseReducer<QuotationDetailsState, PayloadAction<Types.SaveFlatRizeElseAction>> = (
  state,
) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const saveFlatRiseElseSuccess: CaseReducer<
  QuotationDetailsState,
  PayloadAction<Types.SaveFlatRizeElseSuccess>
> = (state, action) => {
  const { tabId, flatItems, riseItems, elseItems, constMethod } = action.payload

  // view側でtabTypeというプロパティに依存したコードを記述しているため、ステートに追加する
  flatItems.forEach((item) => (item.tabType = '平場'))
  riseItems.forEach((item) => (item.tabType = '立上り'))
  elseItems.forEach((item) => (item.tabType = 'その他'))

  // 編集チェックフラグを追加
  flatItems.forEach((item) => (item.isEdited = false))
  riseItems.forEach((item) => (item.isEdited = false))
  elseItems.forEach((item) => (item.isEdited = false))

  // 個別の保存フラグの追加(その他タブのみ)
  elseItems.forEach((item) => (item.isSaved = true))

  const flatRow = state['flat'].findIndex((item) => item.tabId === tabId)
  const riseRow = state['rise'].findIndex((item) => item.tabId === tabId)
  const elseRow = state['else'].findIndex((item) => item.tabId === tabId)
  const constRow = state['constructionMethod'].findIndex((item) => item.tabId === tabId)
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['flat'][flatRow]['items'] = flatItems
    draft['rise'][riseRow]['items'] = riseItems
    draft['else'][elseRow]['items'] = elseItems
    draft['constructionMethod'][constRow]['items'] = constMethod
    draft['flat'][flatRow]['isSaved'] = flatItems.length > 0 ? true : false
    draft['rise'][riseRow]['isSaved'] = riseItems.length > 0 ? true : false
    draft['else'][elseRow]['isSaved'] = elseItems.length > 0 ? true : false
  })
}

export const saveFlatRiseElseFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  const { error } = action.payload
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = error
  })
}

// アクション実行
export const executeAction: CaseReducer<
  QuotationDetailsState,
  PayloadAction<{ itemId: string; datastoreId: string; actionId: string }>
> = (state) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const executeActionSuccess: CaseReducer<QuotationDetailsState, PayloadAction<{ actionId: string }>> = (
  state,
  action,
) => {
  const { actionId } = action.payload
  if (actionId === ACTION_ID.NEXT_STATUS) {
    return produce(state, (draft) => {
      draft['loading'] = false
      draft['quotation']['ステータス'] = '未承認' // 申請ボタンクリック後、画面のステータスを未承認にするためステートを更新する
    })
  }
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}

export const executeActionFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const getConstructionMethodFields: CaseReducer<QuotationDetailsState, PayloadAction<{ datastoreId: string }>> = (
  state,
) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const getConstructionMethodFieldsSuccess: CaseReducer<
  QuotationDetailsState,
  PayloadAction<{ fields: Types.SearchResultFields }>
> = (state, action) => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['constructionMethodFields'] = action.payload.fields
  })
}

// 見積作成画面の初期値を作成
export const genInitStateForCreateContainer: CaseReducer<QuotationDetailsState> = (state) => {
  const tabId = StringUtils.GenUUID()
  return produce(state, (draft) => {
    draft['quotation'] = { i_id: '' }
    draft['constructionMethod'] = [
      {
        items: { i_id: '' },
        tabId,
      },
    ]
    draft['flat'] = [
      {
        items: [EMPTY_FLAT_AND_RISE_RESOURCE],
        tabId: tabId,
        isSaved: false,
      },
    ]
    draft['rise'] = [
      {
        items: [EMPTY_FLAT_AND_RISE_RESOURCE],
        tabId: tabId,
        isSaved: false,
      },
    ]
    draft['else'] = [
      {
        items: [],
        tabId: tabId,
        isSaved: false,
      },
    ]
  })
}

// 見積編集画面の初期値を作成
export const genInitStateForEditContainer: CaseReducer<QuotationDetailsState, PayloadAction<{ itemId: string }>> = (
  state,
) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const genInitStateForEditContainerSuccess: CaseReducer<
  QuotationDetailsState,
  PayloadAction<Types.GetInitStateForEditContainerSuccess>
> = (state, action) => {
  const { quotation, constructionMethodItems } = action.payload
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['quotation'] = quotation

    if (constructionMethodItems.length > 0) {
      constructionMethodItems.forEach((item, idx) => {
        const tabId = StringUtils.GenUUID()
        draft['constructionMethod'][idx] = { items: item, tabId }
        draft['flat'][idx] = { items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId, isSaved: false }
        draft['rise'][idx] = { items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId, isSaved: false }
        draft['else'][idx] = { items: [], tabId, isSaved: false }
      })
    } else {
      const tabId = StringUtils.GenUUID()
      draft['constructionMethod'][0] = { items: { i_id: '' }, tabId }
      draft['flat'][0] = { items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId, isSaved: false }
      draft['rise'][0] = { items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId, isSaved: false }
      draft['else'][0] = { items: [], tabId, isSaved: false }
    }
  })
}

// DBから平場・立上り・その他のデータを取得
export const getFlatRiseElse: CaseReducer<QuotationDetailsState, PayloadAction<Types.GetFlatRiseElseAction>> = (
  state,
) => {
  return produce(state, (draft) => {
    draft['constructionLoading'] = true
  })
}

export const getFlatRiseElseSuccess: CaseReducer<QuotationDetailsState, PayloadAction<Types.GetFlatRiseElseSuccess>> = (
  state,
  action,
) => {
  const { tabId, flatItems, riseItems, elseItems } = action.payload
  const flatIdx = state.flat.findIndex((item) => item.tabId === tabId)
  const riseIdx = state.rise.findIndex((item) => item.tabId === tabId)
  const elseIdx = state.else.findIndex((item) => item.tabId === tabId)

  // view側でtabTypeというプロパティに依存したコードを記述しているため、ステートに追加する
  flatItems.forEach((item) => (item.tabType = '平場'))
  riseItems.forEach((item) => (item.tabType = '立上り'))
  elseItems.forEach((item) => (item.tabType = 'その他'))

  // 編集チェックフラグを追加
  flatItems.forEach((item) => (item.isEdited = false))
  riseItems.forEach((item) => (item.isEdited = false))
  elseItems.forEach((item) => (item.isEdited = false))

  // 個別の保存フラグの追加(その他タブのみ)
  elseItems.forEach((item) => (item.isSaved = true))

  return produce(state, (draft) => {
    draft['constructionLoading'] = false
    if (flatItems.length > 0) {
      draft['flat'][flatIdx] = { items: flatItems, tabId, isSaved: true }
    } else {
      draft['flat'][flatIdx] = { items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId, isSaved: false }
    }

    if (riseItems.length > 0) {
      draft['rise'][riseIdx] = { items: riseItems, tabId, isSaved: true }
    } else {
      draft['rise'][flatIdx] = { items: [EMPTY_FLAT_AND_RISE_RESOURCE], tabId, isSaved: false }
    }

    if (elseItems.length > 0) {
      draft['else'][elseIdx] = { items: elseItems, tabId, isSaved: true }
    } else {
      draft['else'][elseIdx] = { items: [], tabId, isSaved: false }
    }
    console.log("test03")
    console.log(state)
  })
}

export const getFlatRiseElseFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  return produce(state, (draft) => {
    draft['constructionLoading'] = false
    draft['error'] = action.payload.error
  })
}

export const resetLowestPrice: CaseReducer<QuotationDetailsState> = (state) => {
  return produce(state, (draft) => {
    draft['lowestPrices'] = {
      lowestPriceDataOfAll: {
        columns: [],
        price: [],
      },
      lowestPriceDataOfCustomer: {
        columns: [],
        price: [],
      },
    }
  })
}

export const resetLowestSales: CaseReducer<QuotationDetailsState> = (state) => {
  return produce(state, (draft) => {
    draft['lowestSaless'] = {
      lowestSalesDataOfAll: {
        columns: [],
        price: [],
      },
      lowestSalesDataOfCustomer: {
        columns: [],
        price: [],
      },
    }
  })
}
export const copyQuotation: CaseReducer<QuotationDetailsState> = (state) => {
  return produce(state, (draft) => {
    delete draft.quotation.i_id
    delete draft.quotation.personInChargeName
    delete draft.quotation.status_id
    delete draft.quotation.ステータス
    delete draft.quotation.quotationId
    delete draft.quotation.quotationIdText
    delete draft.quotation.quotationNum
    delete draft.quotation.dateOfCreation
    delete draft.quotation.lastUpdateDate
    delete draft.quotation.rev_no
    draft.constructionMethod.forEach((obj) => {
      delete obj.items.i_id
      delete obj.items.constId
      delete obj.items.constIdText
      delete obj.items.status
      delete obj.items.userId
      delete obj.items.quotationIdText
      delete obj.items.dateOfCreation
      delete obj.items.lastUpdateDate
      delete obj.items.rev_no
    })
    draft.flat.forEach((obj) => {
      obj.isSaved = false
      obj.items.forEach((item) => {
        delete item.i_id
        delete item.constIdText
        delete item.flatId
        delete item.status
        delete item.rev_no
        item.isEdited = false
      })
    })

    draft.rise.forEach((obj) => {
      obj.isSaved = false
      obj.items.forEach((item) => {
        delete item.i_id
        delete item.constIdText
        delete item.riseId
        delete item.status
        delete item.rev_no
        item.isEdited = false
      })
    })

    draft.else.forEach((obj) => {
      obj.isSaved = false
      obj.items.forEach((item) => {
        delete item.i_id
        delete item.constIdText
        delete item.elseId
        delete item.status
        delete item.rev_no
        item.isEdited = false
        item.isSaved = false
      })
    })
  })
}

export const downloadPdf: CaseReducer<QuotationDetailsState, PayloadAction<{ itemId: string }>> = (state) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const downloadPdfSuccess: CaseReducer<QuotationDetailsState> = (state) => {
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}
export const downloadPdfFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const downloadCsvRequest: CaseReducer<QuotationDetailsState, PayloadAction<{ itemId: string }>> = (state) => {
  return produce(state, (draft) => {
    draft['loading'] = true
  })
}

export const downloadCsvSuccess: CaseReducer<QuotationDetailsState, PayloadAction<{ csvdata: Types.DatareportResult }>> = (state) => {
  return produce(state, (draft) => {
    draft['loading'] = false
  })
}
export const downloadCsvFailed: CaseReducer<QuotationDetailsState, PayloadAction<{ error: string }>> = (
  state,
  action,
) => {
  return produce(state, (draft) => {
    draft['loading'] = false
    draft['error'] = action.payload.error
  })
}

export const setStatusesData: CaseReducer<
  QuotationDetailsState,
  PayloadAction<{ id: string; statues: { id: string; name: string }[] }>
> = (state, action) => {
  return produce(state, (draft) => {
    draft.statusesData.id = action.payload.id
    draft.statusesData.statues = action.payload.statues
  })
}
