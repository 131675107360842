import { Items, ItemEntry, DatastoreField, DatastoreActions } from './types'

export interface ItemsState extends Items {
  current_item_id: string
  loading: boolean
  approveItemsLoading: boolean
  error: string
}

export const initialState: ItemsState = {
  entries: new Array<ItemEntry>(),
  columns: {
    column_settings: {},
    encoding: '',
    name: '',
  },
  fields: new Array<DatastoreField>(),
  new_actions: new Array<DatastoreActions>(),
  settings: {
    created_at: '',
    d_id: '',
    data_source: '',
    deleted: false,
    display_id: '',
    display_order: 0,
    encoding: '',
    failed: false,
    imported: false,
    invisible: false,
    is_external_service: false,
    labels: new Array<any>(),
    name: new Map<string, string>(),
    no_status: false,
    p_id: '',
    progress: 0,
    roles: new Array<string>(),
    show_in_menu: false,
    show_only_dev_mode: false,
    updated_at: false,
    uploading: false,
    use_board_view: false,
    use_csv_update: false,
    use_external_sync: false,
    use_grid_view: false,
    use_qr_download: false,
    use_replace_upload: false,
    use_status_update: false,
    w_id: '',
  },
  personalization: {
    found: false,
    settings: {
      UpdatedAt: '',
      d_id: '',
      p_id: '',
      q_id: '',
      u_id: '',
      ui_grid_state: {},
      w_id: '',
    },
  },
  searchItems: {
    items: [],
    fields: {},
    totalItems: 0,
    searchConditions: [],
  },
  current_item_id: '',
  loading: false,
  approveItemsLoading: false,
  error: '',
}
