import { AxiosError } from 'axios'
import { Action, PayloadAction } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { of, Observable, EMPTY } from 'rxjs'
import { switchMap, catchError, mergeMap, map, concatMap } from 'rxjs/operators'
import { notification } from 'antd'

import {
  UsersActions,
  SystemActions,
  UsersSelectors,
  ItemsActions,
  DatastoreActions,
  ItemsDetailsActions,
  QuotationDetailsActions,
} from '..'
import HttpService from 'app/services/httpService/httpService'
import rootStore from '../rootStore'
import * as apiModels from './epic.types'
import { User } from './types'

import { APP_CONSTANT } from 'app/constants/app'
import { ERROR_MESSAGES, MESSAGES } from 'app/constants/system'
import { RESET_PASSWORD_ID } from 'app/constants/login'
import { ROUTE_PATH } from 'app/constants/router'
import { ErrorHandlerService } from 'app/services/errorHandler/errorHandler'
import { DATASTORE_ID } from 'app/constants/hexabase'

/**
 * @action INIT
 * @in [PERSIST-STORE] REHYDRATE
 *
 * @description This is an init action called automatically  when the
 * store have been rehydrated. Here used to get the current user
 * If you want to know why this is called : https://github.com/b-eee/hexalite/wiki/Store-Architecture (check EPIC subpart)
 *
 * @outSuccess [User] getCurrentUserRequest
 */
const initEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(APP_CONSTANT.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      const userLogged = UsersSelectors.isUserLogged(state$.value)
      if (userLogged) {
        return of(
          UsersActions.getUserinfoRequest(),
          QuotationDetailsActions.getConstructionMethodFields({ datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD }),
        )
      } else {
        return EMPTY
      }
    }),
  )

/**
 * @action HTTP
 * @in [User] loginRequest
 *
 * @param {string} email the mail we would like to use to login
 * @param {string} password the password we would like to use to login
 *
 * @description Request authentification of a user, on success, set the
 * token in store and request loading of the user workspaces
 *
 * @outSuccess [User] setUserAuth
 * @outSuccess [User] loginSuccess
 * @outSuccess [Workspace] getWorkspacesRequest
 *
 * @outFailed [User] loginFailed
 */
const loginEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.loginRequest),
    switchMap((action: PayloadAction<{ email: string; password: string }>) =>
      HttpService.PostAsync<apiModels.api_token_auth_request, apiModels.api_token_auth_response>(
        'token-auth',
        action.payload,
      ).pipe(
        mergeMap((response) => {
          const token = response && response.data && response.data.token ? response.data.token : ''
          return of(
            UsersActions.setUserAuth({ authKey: token }),
            UsersActions.loginSuccess(),
            UsersActions.getUserinfoRequest(),
            SystemActions.getWorkspacesRequest(),
            SystemActions.navigate({ path: ROUTE_PATH.QUOTATION_LIST }),
            QuotationDetailsActions.getConstructionMethodFields({ datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD }),
          )
        }),
        catchError((error: AxiosError) => {
          return of(UsersActions.loginFailed({ error: typeof error === 'string' ? error : error.message || '' }))
        }),
      ),
    ),
  )

/**
 * @action HTTP
 * @in [User] getCurrentUserRequest
 *
 * @description Request to retrieve user information from the API
 * and store it in the user store
 *
 * @outSuccess [User] getCurrentUserSuccess
 *
 * @outFailed [User] getCurrentUserFailed
 */
const getUserinfoRequestEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.getUserinfoRequest),
    switchMap(() =>
      HttpService.GetAsync<null, User>('userinfo', null, HttpService.LinkerAPIBasePath).pipe(
        map((response) => {
          return UsersActions.getUserinfoSuccess({ user: response.data })
        }),
        catchError((error: string) => {
          return of(UsersActions.getUserinfoFailed({ error }))
        }),
      ),
    ),
  )

/**
 * @action LINEAR
 * @in [User] logout
 *
 * @description Logout the user, purge the locally stored data
 * and redirect to login. This will also clear the user auth token.
 *
 * @outSuccess [User] reset
 * @outSuccess [Workspace] reset
 * @outSuccess [Project] reset
 * @outSuccess [Datastore] reset
 * @outSuccess [Item] reset
 * @outSuccess [ItemDetail] reset
 */
const logoutEpic = (action$) =>
  action$.pipe(
    ofType(UsersActions.logout),
    switchMap(() => {
      rootStore.persistor.purge()
      window.location.href = '/login'
      return [
        UsersActions.reset(),
        SystemActions.reset(),
        DatastoreActions.reset(),
        ItemsActions.reset(),
        ItemsDetailsActions.reset(),
      ]
    }),
  )

const forgotPasswordEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.forgotPasswordRequest),
    switchMap((action: PayloadAction<{ email: string }>) =>
      HttpService.PostAsync<apiModels.user_password_forgot_request, apiModels.user_password_forgot_response>(
        'users/password/forgot',
        { email: action.payload.email, host: window.location.origin },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap((response) => {
          if (response.data.valid_email) {
            notification['info']({
              message: MESSAGES.SEND_EMAIL,
              duration: 4000,
            })
            return of(UsersActions.forgotPassowrdSuccess())
          } else {
            ErrorHandlerService.addError({ message: ERROR_MESSAGES.INVALID_EMAIL, time: Date.now() }, true)
            return of(UsersActions.forgotPasswordFailed({ error: ERROR_MESSAGES.INVALID_EMAIL }))
          }
        }),
        catchError((error: string) => {
          return of(UsersActions.forgotPasswordFailed({ error }))
        }),
      ),
    ),
  )

const changePasswordEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.changePasswordRequest),
    switchMap((action: PayloadAction<{ newPassword: string; confirmPassword: string }>) =>
      HttpService.PutAsync<apiModels.user_password_forgot_change_request, null>(
        'users/password/forgot',
        {
          new_password: action.payload.newPassword,
          confirm_password: action.payload.confirmPassword,
          id: window.location.pathname.split('/')[RESET_PASSWORD_ID],
        },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap(() => {
          notification['info']({
            message: MESSAGES.RESET_PASSWORD,
            duration: 4000,
          })
          return of(UsersActions.changePassowrdSuccess(), SystemActions.navigate({ path: ROUTE_PATH.LOGIN }))
        }),
        catchError((error: string) => {
          return of(UsersActions.forgotPasswordFailed({ error }))
        }),
      ),
    ),
  )

const passwordValidateEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.passwordValidateRequest),
    switchMap(() =>
      HttpService.GetAsync<apiModels.user_password_validateId_request, apiModels.user_password_validateId_response>(
        'users/password/validate',
        {
          id: window.location.pathname.split('/')[2],
        },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap((response) => {
          if (response.data.accessed) {
            notification['info']({
              message: ERROR_MESSAGES.USED_LINK,
              duration: 4000,
            })
            return of(UsersActions.passowrdValidateSuccess(), SystemActions.navigate({ path: ROUTE_PATH.LOGIN }))
          }
          if (response.data.isElapsed) {
            ErrorHandlerService.addError({ message: ERROR_MESSAGES.EXPIRED_LINK, time: Date.now() }, true)
            return of(
              UsersActions.passowrdValidateSuccess(),
              SystemActions.navigate({ path: ROUTE_PATH.RESET_PASSWORD }),
            )
          }
          return of(UsersActions.passowrdValidateSuccess())
        }),
        catchError((error: string) => {
          return of(UsersActions.passwordValidateFailed({ error }))
        }),
      ),
    ),
  )

export default [
  initEpic,
  getUserinfoRequestEpic,
  loginEpic,
  logoutEpic,
  forgotPasswordEpic,
  changePasswordEpic,
  passwordValidateEpic,
]
