import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Row, Col, Form, Select, Button, Input, Radio, Modal } from 'antd'
import { ReadFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import {
  QUOTATION_FORM_NAME,
  CONSTRUCTION_LOCATION_OPTIONS,
  GROUND_WORK_OPTIONS,
  EXISTING_WATERPROOF_OPTIONS,
  CASCADING_SELECT_FORMS,
  QUOTATION_DISP_ID as DISP_ID,
  QUOTATION_ID_LABEL,
} from 'app/constants/components/quotation'
import { DATAREPORTS_ID, DATASTORE_ID } from 'app/constants/hexabase'
import { ConstructionMethod } from 'app/services/store/quotationDetails/types'

import { UsersSelectors, QuotationDetailsActions, QuotationDetailsSelectors } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'

import { getReportData } from 'app/services/store/utils/linkerApis'

import { extractConstMethodSummaries, getCatalogUrl } from 'app/utils/components/quotation/quotation'

const { Option } = Select

interface ConstructionInfoProps {
  tabId: string
  mode: 'create' | 'update' | 'copy'
  isDisabled: boolean
  isCustomerInfoSaved: boolean
  resource: ConstructionMethod
  removeConstructionDataTabPane: Function
  handleSetConstructionVolume: Function
  handleSetConstructionVolumeAndSave: Function
}
const ConstructionInfo = ({
  mode,
  isCustomerInfoSaved,
  isDisabled,
  resource: { items, tabId },
  removeConstructionDataTabPane,
  handleSetConstructionVolume,
  handleSetConstructionVolumeAndSave,
}: ConstructionInfoProps): JSX.Element => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [isConstDelModalOpen, handleConstDelModalOpen] = useState(false)

  const userId = UsersSelectors.getUserId(useSelector(rootSelector))

  const { separate, total } = QuotationDetailsSelectors.getSeparateOrTotalOptions(useSelector(rootSelector))
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const [flat, rise] = QuotationDetailsSelectors.getFlatRiseElse(useSelector(rootSelector))
  const flatIndex = useMemo(() => {
    return flat.findIndex((flatObj) => flatObj.tabId === tabId)
  }, [flat, tabId])

  const riseIndex = useMemo(() => {
    return rise.findIndex((riseObj) => riseObj.tabId === tabId)
  }, [rise, tabId])

  const constructionMethods = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))
  const constructionMethod = constructionMethods
    ? constructionMethods.find((item) => {
        return item.tabId === tabId
      })
    : null
  const constructionItemId = constructionMethod ? constructionMethod.items.i_id : ''
  const constructionType = constructionMethod ? constructionMethod.items.constructionType : ''

  const [optionsList, setOptionsList] = useState<string[][]>([])
  const [catalogUrl, setCatalogUrl] = useState<string | undefined>()
  const [flatOptions, setFlatOptions] = useState<string[] | undefined>()
  const [riseOptions, setRiseOptions] = useState<string[] | undefined>()
  const [disableType, setdisableType] = useState<boolean>(false)
  const [disableTypeElse, setdisableTypeElse] = useState<boolean>(false)
  const [disablerequired, setdisablerequired] = useState<boolean>(true)

  // TODO: リファクタ（各処理を適切な単位に分解する）
  const setOptions = useCallback(
    async (dataReportsId: string, listIndex: number, selectedValue?, selectedFormLabel?: string) => {
      const options: string[] = []

      if (dataReportsId !== DATAREPORTS_ID.SPECIFICATION_CODE_FLAT) {
        const response = await getReportData({ dataReportsId }).toPromise()

        const formerReportField = response.data.report_fields.find((item) => item.title === selectedFormLabel)
        const formerRpfId = formerReportField ? formerReportField.display_id : ''

        const reportTitle =
          response.data.report_title === '仕様平' || response.data.report_title === '仕様立'
            ? response.data.report_title.slice(0, -1) // '仕様平' or '仕様立' -> '仕様'
            : response.data.report_title
        const nextReportField = response.data.report_fields.find((item) => item.title === reportTitle)
        const nextRpfId = nextReportField ? nextReportField.display_id : ''

        if (selectedValue && selectedFormLabel) {
          response.data.report_results.forEach((item) => {
            item[formerRpfId] === selectedValue &&
              !options.some((option) => {
                return item[nextRpfId] === option
              }) &&
              options.push(item[nextRpfId])
          })
        } else {
          response.data.report_results.forEach((item) => {
            options.push(item[nextRpfId])
          })
        }
      }

      const resetFormsName = [...Array(CASCADING_SELECT_FORMS.length - listIndex)]
      resetFormsName.forEach((_, index) => {
        resetFormsName[index] = CASCADING_SELECT_FORMS[listIndex + index]
      })

      form.resetFields(resetFormsName)
      if (listIndex < 4) {
        setCatalogUrl(undefined)
      }
      setFlatOptions(undefined)
      setRiseOptions(undefined)

      const newOptionsList: string[][] = []
      optionsList.forEach((option, index) => index < listIndex && newOptionsList.push(option))
      newOptionsList[listIndex] = options
      setOptionsList(newOptionsList)
    },
    [optionsList, form],
  )

  const handleSetOptions = useCallback(
    (dataReportsId: string, listIndex: number, selectedValue?, selectedFormLabel?: string) => {
      setOptions(dataReportsId, listIndex, selectedValue, selectedFormLabel)
      if(selectedFormLabel==="工事種別"){
        if((selectedValue==="塗装工事")){
          setdisableType(true)
          setdisableTypeElse(false)
          setdisablerequired(true)
        }else if(selectedValue==="その他"){
          setdisableType(true)
          setdisableTypeElse(true)
          setdisablerequired(false)
        }else{
          setdisableType(false)
          setdisableTypeElse(false)
          setdisablerequired(true)
        }
      }
    },
    [setOptions],
  )

  const setFlatAndRiseSelect = useCallback((selectedValue) => {
    const dataReportIds = [DATAREPORTS_ID.SPECIFICATION_CODE_FLAT, DATAREPORTS_ID.SPECIFICATION_CODE_RISE]

    dataReportIds.forEach(async (id, index) => {
      const response = await getReportData({ dataReportsId: id }).toPromise()

      const constructionMethodReportField = response.data.report_fields.find((item) => item.title === '工法')
      const constructionMethodRpfId = constructionMethodReportField ? constructionMethodReportField.display_id : ''

      const specificationReportField = response.data.report_fields.find((item) => item.title === '仕様')
      const specificationRpfId = specificationReportField ? specificationReportField.display_id : ''

      const options: string[] = []
      response.data.report_results.forEach((item) => {
        item[constructionMethodRpfId] === selectedValue &&
        item.system === form.getFieldValue(QUOTATION_FORM_NAME.SYSTEM) &&
          !options.some((option) => {
              return item[specificationRpfId] === option
          }) &&
          options.push(item[specificationRpfId])
      })

      if (index === 0) {
        setFlatOptions(options)
      } else {
        setRiseOptions(options)
      }
    })
  }, [])

  const handleSetFlatAndRiseSelect = useCallback(
    (selectedValue) => {
      setFlatAndRiseSelect(selectedValue)
    },
    [setFlatAndRiseSelect],
  )

  let showInfo = true
  const handleSetHidden = useCallback(
    (selectedValue) => {
      if(selectedValue === "塗装工事"){ showInfo = false }
     },
    [showInfo],
    )

  const handleSetUrl = useCallback(
    (dataReportsId: string, selectedValue) => {
      getCatalogUrl(dataReportsId, selectedValue, form, setCatalogUrl)
    },
    [setCatalogUrl, form],
  )

  const handleDeleteConstructionMethod = useCallback((): void => {
    if (isCustomerInfoSaved && constructionItemId) {
      handleConstDelModalOpen(false)
      dispatch(
        QuotationDetailsActions.deleteConstructionMethodFromDB({
          datastoreId: DATASTORE_ID.CONSTRUCTION_METHOD,
          itemId: constructionItemId,
          tabId,
        }),
      )
    } else {
      removeConstructionDataTabPane(tabId)
    }
  }, [isCustomerInfoSaved, dispatch, constructionItemId, removeConstructionDataTabPane, tabId])

  // TODO: Epic内で実行すべき処理を分離
  const onFinish = async (values) => {
    const datastoreId = DATASTORE_ID.CONSTRUCTION_METHOD
    const constMethodSummaries = extractConstMethodSummaries(constructionMethod)

    const items = {
      ...values,
      ...constMethodSummaries,
    }
    items[QUOTATION_FORM_NAME.CATALOG_URL] = catalogUrl || items[QUOTATION_FORM_NAME.CATALOG_URL]

    // TODO: リファクタ
    Object.keys(items).forEach((key) => {
      if (
        key === QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT ||
        key === QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE ||
        key === QUOTATION_FORM_NAME.CONST_TOTAL_SALES ||
        key === QUOTATION_FORM_NAME.CONST_TOTAL_PURCHASE ||
        key === QUOTATION_FORM_NAME.CONST_TOTAL_GROSS_PROFIT_MARGIN ||
        key === QUOTATION_FORM_NAME.FLAT_TAB_TOTAL_SALES ||
        key === QUOTATION_FORM_NAME.FLAT_TAB_TOTAL_PURCHASE ||
        key === QUOTATION_FORM_NAME.FLAT_M2_UNIT_PRICE ||
        key === QUOTATION_FORM_NAME.FLAT_TAB_TOTAL_GROSS_PROFIT_MARGIN ||
        key === QUOTATION_FORM_NAME.RISE_TAB_TOTAL_SALES ||
        key === QUOTATION_FORM_NAME.RISE_TAB_TOTAL_PURCHASE ||
        key === QUOTATION_FORM_NAME.RISE_M2_UNIT_PRICE ||
        key === QUOTATION_FORM_NAME.RISE_TAB_TOTAL_GROSS_PROFIT_MARGIN ||
        key === QUOTATION_FORM_NAME.ELSE_TAB_TOTAL_GROSS_PROFIT_MARGIN ||
        key === QUOTATION_FORM_NAME.ELSE_TAB_TOTAL_PURCHASE ||
        key === QUOTATION_FORM_NAME.ELSE_TAB_TOTAL_SALES
      ) {
        items[key] = Number(items[key])
      }
    })
    console.log("items")
    console.log(items)
    if (!constructionItemId) {
      // create
      items[DISP_ID.DATE_OF_CREATION] = new Date().toISOString()
      items[QUOTATION_ID_LABEL.QUOTATION_ID_TEXT] = quotation['quotationId']
      dispatch(QuotationDetailsActions.createRequest({ datastoreId, item: items, userId, tabId }))

      // 複製画面で平場・立上りのデータが存在する場合は、保存に併せて施工量も更新する
      if (mode === 'copy' && flat[flatIndex].items.length > 1) {
        handleSetConstructionVolume(
          form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT),
          form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE),
          )
      }

      // 見積書の新規作成画面・編集画面の場合は、工法のcreate時に、マスターDBから平場・立上りの情報を取得する
      // 見積書の複製画面の場合は、平場・立上りにデータが存在しない場合に、マスターDBから情報取得する
      if (
        mode !== 'copy' ||
        (mode === 'copy' && flat[flatIndex].items.length <= 1 && rise[riseIndex].items.length <= 1)
      ) {
        // TODO: Epic内で実行する
        const constructionMethod = form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_METHOD)
        const specificationCodeFlat = form.getFieldValue(QUOTATION_FORM_NAME.SPECIFICATION_CODE_FLAT)
        let specificationCodeRise = form.getFieldValue(QUOTATION_FORM_NAME.SPECIFICATION_CODE_RISE)
        const constructionVolumeFlat = form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT)
        let constructionVolumeRise = form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE)
        const system = form.getFieldValue(QUOTATION_FORM_NAME.SYSTEM)

        if(specificationCodeRise===undefined){specificationCodeRise="ー"}
        if(constructionVolumeRise===undefined){constructionVolumeRise="1"}
        if (
          constructionMethod &&
          specificationCodeFlat &&
          specificationCodeRise &&
          constructionVolumeFlat &&
          constructionVolumeRise &&
          system &&
          tabId
        ) {
          dispatch(
            QuotationDetailsActions.getFlatRiseMasterData({
              constructionMethod,
              specificationCodeFlat,
              specificationCodeRise,
              constructionVolumeFlat,
              constructionVolumeRise,
              system,
              tabId,
            }),
          )
        }
      }
    } else {
      dispatch(
        QuotationDetailsActions.updateQuotationAndConstruction({
          datastoreId,
          itemId: constructionItemId,
          item: items,
          tabId,
        }),
      )
    }
  }

  // TODO: リファクタ
  const formatRadioType = (items) => {
    const formatedData = {}
    Object.entries(items).forEach(([key, value]) => {
      if (key === QUOTATION_FORM_NAME.SEPARATE_OR_TOTAL && value === '平・立 別' && separate) {
        formatedData[key] = separate
      } else if (key === QUOTATION_FORM_NAME.SEPARATE_OR_TOTAL && value === '合算' && total) {
        formatedData[key] = total
      } else {
        formatedData[key] = value
      }
    })
    if (!formatedData['separateOrTotal']) {
      // 新規作成時、ラジオボタンの初期値を「合算」に設定する
      formatedData['separateOrTotal'] = total
    }
    return formatedData
  }

  const openConstDelModal = useCallback(() => {
    handleConstDelModalOpen(true)
  }, [])

  const closeConstDelModal = useCallback(() => {
    handleConstDelModalOpen(false)
  }, [])

  useEffect(() => {
    if (optionsList.length === 0) {
      handleSetOptions(DATAREPORTS_ID.CONSTRUCTION_TYPE, 0)
    }
    if (optionsList.length === 1 && items.i_id) {
      handleSetOptions(DATAREPORTS_ID.MAKER, 1, items.constructionType, '工事種別')
    }
    if (optionsList.length === 2 && items.i_id) {
      handleSetOptions(DATAREPORTS_ID.SYSTEM, 2, items.maker, 'メーカー')
    }

    if (optionsList.length === 3 && items.i_id) {
      handleSetOptions(DATAREPORTS_ID.CONSTRUCTION_METHOD, 3, items.system, 'システム')
    }

    if (optionsList.length === 4 && items.i_id) {
      handleSetOptions(DATAREPORTS_ID.SPECIFICATION_CODE_FLAT, 4, items.constructionMethod, '工法')
    }
  }, [
    optionsList,
    handleSetOptions,
    items.i_id,
    items.constructionMethod,
    items.constructionType,
    items.maker,
    items.system,
  ])

  return (
    <div style={{ backgroundColor: 'rgb(238, 238, 238)', padding: '25px 20px 10px', marginTop: '0px' }}>
      <Form key={tabId} form={form} initialValues={formatRadioType(items)} onFinish={onFinish}>
        <Row>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              colon={false}
              label="・工事種別"
              name={QUOTATION_FORM_NAME.CONSTRUCTION_TYPE}
            >
              <Select
                onChange={(value) => {
                  handleSetOptions(DATAREPORTS_ID.MAKER, 1, value, '工事種別')
                }}

                disabled={
                  mode === 'copy' ||
                  (!!constructionItemId && disableTypeElse) || 
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {optionsList[0] &&
                  optionsList[0].map((option, index) => (
                    <Option value={option} key={index}>
                      {option}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              colon={false}
              label="・メーカー"
              name={QUOTATION_FORM_NAME.MAKER}
            >
              <Select
                onChange={(value) => handleSetOptions(DATAREPORTS_ID.SYSTEM, 2, value, 'メーカー')}
                disabled={
                  disableTypeElse || 
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {optionsList[1] &&
                  optionsList[1].map((option, index) => {
                    return (
                      <Option value={option} key={index}>
                        {option}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・システム"
              name={QUOTATION_FORM_NAME.SYSTEM}
            >
              <Select
                onChange={(value) => handleSetOptions(DATAREPORTS_ID.CONSTRUCTION_METHOD, 3, value, 'システム')}
                disabled={
                  disableTypeElse || 
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {optionsList[2] &&
                  optionsList[2].map((option, index) => {
                    return (
                      <Option value={option} key={index}>
                        {option}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・工法"
              name={QUOTATION_FORM_NAME.CONSTRUCTION_METHOD}
            >
              <Select
                disabled={
                  disableTypeElse || 
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
                onChange={(value) => {
                  handleSetOptions(DATAREPORTS_ID.SPECIFICATION_CODE_FLAT, 4, value, '工法')
                  handleSetFlatAndRiseSelect(value)
                  handleSetHidden(value)
                  handleSetUrl(DATAREPORTS_ID.CONSTRUCTION_METHOD, value)
                }}
              >
                {optionsList[3] &&
                  optionsList[3].map((option, index) => {
                    return (
                      <Option value={option} key={index}>
                        {option}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Form.Item name={QUOTATION_FORM_NAME.CATALOG_URL}>
              <Button
                icon={<ReadFilled style={{ color: 'darkCyan' }} />}
                disabled={disableTypeElse || !items['catalog'] && !catalogUrl}
                onClick={(): void => {
                  window.open(items['catalog'] || catalogUrl)
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: '10px' }}>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・仕様 平"
              name={QUOTATION_FORM_NAME.SPECIFICATION_CODE_FLAT}
              rules={[{ required: disablerequired, message: '入力必須' }]}
            >
              <Select
                disabled={
                  disableTypeElse || 
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {flatOptions &&
                  flatOptions.map((option, index) => {
                    return (
                      <Option value={option} key={index}>
                        {option}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              colon={false}
              label="・施工量 平"
              rules={[{ required: disablerequired, message: '入力必須' }]}
              required={disablerequired}
            >
              <Form.Item
                name={QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT}
                style={{ display: 'inline-block', width: 'calc(95% - 15px)' }}
                rules={[
                  { required: disablerequired, message: '入力必須/半角数字のみ' },
                  {
                    pattern: new RegExp(/^[1-9](\d?)+(.[0-9]{1})?$/),
                    message: '先頭0は以外/少数は1桁まで有効',
                  },
                ]}
              >
                <Input type="number" disabled={disableTypeElse || isDisabled} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}} />
              </Form.Item>
              <span style={{ display: 'inline-block', marginLeft: '5px' }}>
                m<sup>2</sup>
              </span>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・仕様 立"
              className="disableField"
              name={QUOTATION_FORM_NAME.SPECIFICATION_CODE_RISE}
//              rules={[{ required: true, message: '入力必須' }]}
            >
              <Select
                disabled={
                  disableType ||
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {riseOptions &&
                  riseOptions.map((option, index) => {
                    return (
                      <Option value={option} key={index}>
                        {option}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              className="disableField"
              colon={false}
              label="・施工量 立"
//              required={true}
            >
              <Form.Item
                name={QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE}
                style={{ display: 'inline-block', width: 'calc(95% - 15px)' }}
/*
                rules={[
                  { required: true, message: '入力必須/半角数字のみ' },
                  {
                    pattern: new RegExp(/^[1-9](\d?)+(.[0-9]{1})?$/),
                    message: '先頭0は以外/少数は1桁まで有効',
                  },
                ]}
*/
              >
                <Input type="number" disabled={disableType || isDisabled} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}} />
              </Form.Item>
              <span style={{ display: 'inline-block', marginLeft: '5px' }}>
                m<sup>2</sup>
              </span>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={QUOTATION_FORM_NAME.SEPARATE_OR_TOTAL}
              required={true}
              rules={[{ required: true, message: '入力必須' }]}
            >
              <Radio.Group style={{ marginLeft: '40px' }} disabled={disableType || isDisabled}>
                <Radio value={separate}>平・立 別</Radio>
                <Radio value={total}>合算</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
          <Form.Item
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              colon={false}
              label="・備考"
              required={false}
            >
              <Form.Item
                name={QUOTATION_FORM_NAME.RESERVE1}
                style={{ display: 'inline-block', width: 'calc(95% - 15px)' }}
              >
                <Input type="text" disabled={isDisabled} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}} />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              colon={false}
              label={<span>・施工箇所</span>}
              name={QUOTATION_FORM_NAME.CONSTRUCTION_LOCATION}
            >
              <Select
                disabled={
                  disableTypeElse || 
                  (mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1))
                }
              >
                {CONSTRUCTION_LOCATION_OPTIONS.map((option, index) => {
                  return (
                    <Option value={option} key={index}>
                      {option}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label={<span>・下地</span>}
              name={QUOTATION_FORM_NAME.GROUND_WORK}
            >
              <Select
                disabled={
                  disableTypeElse || 
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {GROUND_WORK_OPTIONS.map((option, index) => (
                  <Option value={option} key={index}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label={<span>・既存防水</span>}
              name={QUOTATION_FORM_NAME.EXISTING_WATERPROOF}
            >
              <Select
                disabled={
                  disableTypeElse || 
                  mode === 'copy' ||
                  (!!constructionItemId && flat[flatIndex].items.length > 1)
                }
              >
                {EXISTING_WATERPROOF_OPTIONS.map((option, index) => (
                  <Option value={option} key={index}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
        <Col>
            <Button
              type="primary"
              disabled={
                isDisabled ||
                !isCustomerInfoSaved ||
                constructionType ==="その他" && constructionItemId !== undefined ||
                (!!constructionItemId && (flat[flatIndex].items.length > 1 || rise[riseIndex].items.length > 1))
              }
              htmlType="submit"
            >
              {mode === 'copy' ? '保存' : '表示して次へ'}
            </Button>
          </Col>
          <Col offset={1}>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  disabled={
                    isDisabled || 
                    !isCustomerInfoSaved || 
                    constructionItemId === undefined || 
                    constructionItemId.length === 0 ||
                    constructionType ==="その他" ||
                    form
                      .getFieldsError([
                        QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT,
                        QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE,
                        QUOTATION_FORM_NAME.SEPARATE_OR_TOTAL,
                        QUOTATION_FORM_NAME.RESERVE1,
                      ])
                      .filter(({ errors }) => errors.length).length > 0
                  }
                  onClick={() =>
                    handleSetConstructionVolumeAndSave(
                      form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT),
                      form.getFieldValue(QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE),
                      form.getFieldValue(QUOTATION_FORM_NAME.SEPARATE_OR_TOTAL),
                      form.getFieldValue(QUOTATION_FORM_NAME.RESERVE1),
                      DATASTORE_ID.CONSTRUCTION_METHOD,
                      constructionItemId,
                      items,
                      tabId,
                    )
                  }
                >
                  施工量変更
                </Button>
              )}
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Button
              style={{color: 'white', backgroundColor: 'black' }}
//              type="primary"
              onClick={openConstDelModal}
              disabled={isDisabled || !isCustomerInfoSaved || constructionItemId===undefined || constructionItemId.length===0 }
            >
              削除
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal visible={isConstDelModalOpen} onOk={handleDeleteConstructionMethod} onCancel={closeConstDelModal} centered>
        <p>工法を削除します。よろしいですか。</p>
      </Modal>
    </div>
  )
}

export default ConstructionInfo
