export const HEXA_LABEL = {
  WORKSPACE_NAME: 'Kaken-Material',
  PROJECT_NAME: '見積書作成',
  ESTIMATION_DB_NAME: '見積書',
}

export const HEXA_ID = {
  APPLICATION: 'QUOTATION-APP',
}

// datastore display id
export const DATASTORE_ID = {
  CUSTOMER_AND_PERSONINCHARGE_MASTER: 'CUSTOMER&PERSONINCHARGE',
  PRODUCT_MASTER: 'PRODUCT',
  CONSTRUCTION_METHOD_MASTER: 'CONSTRUCTIONMETHODMASTER',
  QUOTATION: 'QUOTATION',
  CONSTRUCTION_METHOD: 'CONSTRUCTIONMETHOD',
  FLAT: 'FLAT',
  RISE: 'RISE',
  ELSE: 'ELSE',
  EMPLOYEE: 'EMPLOYEE',
}

// dataReports display id
export const DATAREPORTS_ID = {
  CONSTRUCTION_TYPE: 'reportConstructionType',
  MAKER: 'reportMaker',
  SYSTEM: 'reportSystem',
  CONSTRUCTION_METHOD: 'reportConstructionMethod',
  SPECIFICATION_CODE_FLAT: 'reportSpecificationCodeFlat',
  SPECIFICATION_CODE_RISE: 'reportSpecificationCodeRise',
  REPORT_LOWEST_PRICE_FLAT_ALL: 'reportLowestPriceFlatAll',
  REPORT_LOWEST_PRICE_RISE_ALL: 'reportLowestPriceRiseAll',
  REPORT_LOWEST_PRICE_ELSE_ALL: 'reportLowestPriceElseAll',
  REPORT_LOWEST_PRICE_FLAT_CUSTOMER: 'reportLowestPriceFlatCustomer',
  REPORT_LOWEST_PRICE_RISE_CUSTOMER: 'reportLowestPriceRiseCustomer',
  REPORT_LOWEST_PRICE_ELSE_CUSTOMER: 'reportLowestPriceElseCustomer',
  REPORT_CSV_FLAT: 'reportCsvFlat',
  REPORT_CSV_RISE: 'reportCsvRise',
  REPORT_CSV_ELSE: 'reportCsvElse',
}
