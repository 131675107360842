import moment from 'moment'
import { FIELD_ID as QUOTATION_FIELD_ID } from 'app/constants/components/quotation'
import { SearchConditions } from 'app/services/store/items/types'
import { SearchData } from 'app/types/search'

export const createConditions = (searchData: SearchData[]): SearchConditions =>
  searchData.map((data) => {
    let value = [data.value]
    if (data.itemId === QUOTATION_FIELD_ID.DATE_OF_CREATION) {
      if (typeof data.value !== 'number' && data.value.length !== 0) {
        const from = data.value[0].length !== 0 ? new Date(data.value[0]).toISOString() : ''
        const to = data.value[1].length !== 0 ? moment(data.value[1]).add('days', 1).toISOString() : ''
        value = from === to ? [from] : [from, to]
      } else {
        // 値が無い場合はUI上では初期値として今日日付が表示されている
        // @see SearchRow.tsx
        value = ['TODAY']
      }
    }
    return {
      id: data.itemId,
      search_value: value as [string],
      exact_match: false,
      include_null: false,
    }
  })
