import React from 'react'
import { useDispatch } from 'react-redux'
import { QuotationDetailsActions } from 'app/services/store'
import { Table } from 'antd'

import { QuotationListColumn, QuotationTableItems } from 'app/types/components/quotationDetail'

import EditableCell from 'app/components/organisms/quotation/quotationDetail/quotationTable/EditableCell'

interface EditableTableProps {
  form
  tabId: string
  columns: QuotationListColumn[]
  origindata: QuotationTableItems[]
}
const EditableTable = (props: EditableTableProps): JSX.Element => {
  const { form, tabId, columns, origindata } = props
  const dispatch = useDispatch()

  const handleUpdateOriginData = (
    tabType: '平場' | '立上り' | 'その他',
    targetIid: string,
    updatedValues: { dataIndex: string; value: string | number }[],
  ): void => {
    dispatch(QuotationDetailsActions.editFlatRiseElse({ tabType, tabId, targetIid, updatedValues }))
  }

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record: QuotationTableItems) => ({
        record,
        inputType:
          col.dataIndex === 'purchase' || col.dataIndex === 'sales' || col.dataIndex === 'quantity' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editable: false,
        form,
        origindata,
        handleUpdateOriginData,
        className: 
          col.dataIndex === 'productName' || col.dataIndex === 'quantity' ||  col.dataIndex === 'purchase' || col.dataIndex === 'sales' ? 'fontbold' : '',
      }),
    }
  })

  return (
    <Table
      components={{
        body: {
          cell: EditableCell,
        },
      }}
      dataSource={origindata}
      columns={mergedColumns}
      rowClassName="editable-row"
      pagination={false}
      rowKey="productCode"
      // rowKey='i_id'  // TODO: 複製機能の場合、i_idがないため、productCodeに変更した。問題ないか要確認
    />
  )
}

export default EditableTable
