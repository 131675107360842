import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { rootSelector } from 'app/services/store/rootReducer'
import { datastoresState } from 'app/services/store/datastore/selectors'
import { ItemsSelectors, ItemsActions, UsersSelectors } from 'app/services/store'

import { HEXA_LABEL, DATASTORE_ID } from 'app/constants/hexabase'

import { customizeColumns, sortItemsByDate } from 'app/utils/components/quotation/quotation'
import { getDatastoreIdFromName } from 'app/utils/storeHelper'

import AppLayout from 'app/components/templates/AppLayout'
import SearchResultPage from 'app/components/pages/quotation/SearchResultPage'
import { QuotationListItems } from 'app/types/components/quotation'

const QuotationSearchResultContainer = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { items, columns } = ItemsSelectors.getSearchResultDisplayableEntries(useSelector(rootSelector))
  const sortedItems = sortItemsByDate(items)

  const { datastores_list } = useSelector(datastoresState)
  const quotationDBId = getDatastoreIdFromName(datastores_list, HEXA_LABEL.ESTIMATION_DB_NAME)
  const userId = UsersSelectors.getUserId(useSelector(rootSelector))
  const isAdmin = UsersSelectors.isAdmin(useSelector(rootSelector))
  const isItemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))

  const deleteTableRow = useCallback(
    (itemId: string): void => {
      dispatch(
        ItemsActions.deleteItemFromSearchResultRequest({
          itemId,
          datastoreId: quotationDBId,
          deleteLinkedItems: true,
          targetDatastores: [DATASTORE_ID.CONSTRUCTION_METHOD, DATASTORE_ID.FLAT, DATASTORE_ID.RISE, DATASTORE_ID.ELSE],
        }),
      )
    },
    [quotationDBId, dispatch],
  )

  const resetSearchResult = useCallback((): void => {
    dispatch(ItemsActions.itemsSearchReset())
  }, [dispatch])

  const modifiedColumns = customizeColumns(columns, history, isAdmin, userId, deleteTableRow)

  return (
    <AppLayout>
      <SearchResultPage
        columns={modifiedColumns}
        dataSource={sortedItems as QuotationListItems[]}
        reset={resetSearchResult}
        isLoading={isItemsLoading}
      />
    </AppLayout>
  )
}

export default QuotationSearchResultContainer
