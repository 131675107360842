import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { rootSelector } from 'app/services/store/rootReducer'

import { QuotationDetailsActions, QuotationDetailsSelectors } from 'app/services/store'

import { calculateSummaries, calcSalesPurchaseTotalForRelDsData } from 'app/utils/store/quotationDetails/index'
import {
  convertItemToChanges,
  setConstructionVolume,
  createConstMethodRelatedDsItems,
  calcFraction,
  calcFraction2,
  getSummariesResources,
} from 'app/utils/components/quotation/quotation'
import { ConstructionMethod, Flat, Rise, Else } from 'app/services/store/quotationDetails/types'

import {QUOTATION_FORM_NAME } from 'app/constants/components/quotation'

import ConstructionInfo from 'app/components/organisms/quotation/quotationDetail/ConstructionInfo'
import ConstructionDetailTabs from 'app/components/organisms/quotation/quotationDetail/ConstructionDetailTabs'
import { HEXA_ID, DATASTORE_ID } from 'app/constants/hexabase'
import { RelatedDatastoreItem } from 'app/services/store/utils/linkerApis.types'

import HttpService from 'app/services/httpService/httpService'
import axios from 'axios';

interface ConstructionDataProps {
  mode: 'create' | 'update' | 'copy'
  isDisabled: boolean
    tabId: string
  resources: {
    constructionMethod: ConstructionMethod
    flatResources: Flat
    riseResources: Rise
    elseResources: Else
  }
  isCustomerInfoSaved: boolean
  removeConstructionDataTabPane: Function
}
const ConstructionData = ({
  mode,
  isDisabled,
  tabId,
  resources,
  isCustomerInfoSaved,
  removeConstructionDataTabPane,
}: ConstructionDataProps): JSX.Element => {
  var { constructionMethod, flatResources, riseResources, elseResources } = resources

  const dispatch = useDispatch()

  const constructionMethods = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))
  const original_data = useSelector(rootSelector)
  const handleSetConstructionVolume = (flat?: number, rise?: number): void => {
    const updatedValues = {
      flatResources: setConstructionVolume(flatResources.items, flat ? flat : 0),
      riseResources: setConstructionVolume(riseResources.items, rise ? rise : 0),
    }
    dispatch(QuotationDetailsActions.editEntireFlatRiseElse({ tabId, updatedValues }))
  }

  const handleSetConstructionVolumeAndSave = (flat?: number, rise?: number, separate?: any, reserve1?: string, datastoreId?: any, constructionItemId?: any, items?: any, tabId?: any): void => {
    const updatedValues = {
      flatResources: setConstructionVolume(flatResources.items, flat ? flat : 0),
      riseResources: setConstructionVolume(riseResources.items, rise ? rise : 0),
    }

    if(flat !== undefined){

      dispatch(QuotationDetailsActions.editEntireFlatRiseElse({ tabId, updatedValues }))
      let updateitems = {}
      // TODO: リファクタ
      Object.keys(items).forEach((key) => {
        if (
          key === QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_FLAT ||
          key === QUOTATION_FORM_NAME.CONSTRUCTION_VOLUME_RISE ||
          key === QUOTATION_FORM_NAME.RESERVE1 ||
          key === QUOTATION_FORM_NAME.SEPARATE_OR_TOTAL
        ) {
          updateitems[key] = items[key]
        }
      })

      updateitems["constructionVolumeFlat"] = Number(flat)
      if(rise !== undefined){
        updateitems["constructionVolumeRise"] = Number(rise)
      }
      updateitems["separateOrTotal"] = separate
      updateitems["reserve1"] = reserve1

      console.log("update_items")
      console.log(updateitems)
      dispatch(
        QuotationDetailsActions.updateQuotationAndConstruction({
          datastoreId,
          itemId: constructionItemId,
          item: updateitems,
          tabId,
        }),
      )
    }    
  }

  // TODO: utils/store側の不要になった処理の削除
  // TODO: View側とReducer側で無駄な計算処理が走っているので処理をReducer側にまとめる
  const handleCalculateSummaryResources = (): void => {
    //一括変更処理
    const disptabId = tabId
    const dispconstId = constructionMethod['items']['constId']

    console.log("useSelector(rootSelector)")
    console.log(original_data)
    var newflatResources = JSON.parse(JSON.stringify(original_data.quotationDetails.flat))
    var newriseResources = JSON.parse(JSON.stringify(original_data.quotationDetails.rise))
    var newelseResources = JSON.parse(JSON.stringify(original_data.quotationDetails.else))

    console.log("非表示タブ計算処理")
    let promise_resp = Promise.all(constructionMethods.map( async (item) => {
      var constIdText = item.items.constIdText
      var tabId = item.tabId
      var flatResources2
      var riseResources2
      var elseResources2
      var relatedDsItems

      var flatsummaries
      var risesummaries
      var elsesummaries
      flatsummaries=[]
      risesummaries=[]
      elsesummaries=[]
  

      if(tabId!==disptabId){
        newflatResources.forEach((tempitem) => {
          if(tempitem.tabId===tabId){
            flatResources2 = tempitem
          }
        })
        newriseResources.forEach((tempitem) => {
          if(tempitem.tabId===tabId){
            riseResources2 = tempitem
          }
        })
        newelseResources.forEach((tempitem) => {
          if(tempitem.tabId===tabId){
            elseResources2 = tempitem
          }
        })
  
        if (item['items'] && item['items']['i_id'] && item['items']['constId']) {
          const flatItems = calcSalesPurchaseTotalForRelDsData(
           createConstMethodRelatedDsItems(calcFraction2(flatResources2), constructionMethod,constIdText),
          )
          const riseItems = calcSalesPurchaseTotalForRelDsData(
            createConstMethodRelatedDsItems(calcFraction2(riseResources2), constructionMethod,constIdText),
          )
          const elseItems = calcSalesPurchaseTotalForRelDsData(
            createConstMethodRelatedDsItems(calcFraction2(elseResources2), constructionMethod,constIdText),
          )

         // 工法・平場・立上り・その他のデータをDBに保存
          if (elseItems) {
            relatedDsItems = {
              [DATASTORE_ID.FLAT]: (flatItems as unknown) as RelatedDatastoreItem[],
              [DATASTORE_ID.RISE]: (riseItems as unknown) as RelatedDatastoreItem[],
              [DATASTORE_ID.ELSE]: elseItems,
            }
          } else {
            relatedDsItems = {
              [DATASTORE_ID.FLAT]: (flatItems as unknown) as RelatedDatastoreItem[],
              [DATASTORE_ID.RISE]: (riseItems as unknown) as RelatedDatastoreItem[],
            }
          }

          flatItems.forEach((tempitem) => {
            flatsummaries.push(tempitem.item)
          })
    
          riseItems.forEach((tempitem) => {
            risesummaries.push(tempitem.item)
          })
          if (elseItems) {
            elseItems.forEach((tempitem) => {
              elsesummaries.push(tempitem.item)
            })
          }
    
          var constMethodSummaries = calculateSummaries(
            flatResources2.items[0].constructionVolume,
            riseResources2.items[0].constructionVolume,
            flatsummaries,
            risesummaries,
            elsesummaries,
          )

          console.log("非表示タブ計算データ")
          console.log(tabId)
          console.log(constIdText)
          console.log(item.items.i_id)
          console.log(flatItems)
          console.log(riseItems)
          console.log(constMethodSummaries)

          var changes = convertItemToChanges(item)
          await axios.post(HttpService.getBaseUrl('/applications/'+HEXA_ID.APPLICATION+'/datastores/'+DATASTORE_ID.CONSTRUCTION_METHOD+'/items/edit/'+item.items.i_id,HttpService.LinkerAPIBasePath),
          {
            changes,
            use_display_id: true,
            is_force_update: true,
            rev_no: undefined,
            related_ds_items: relatedDsItems,
            item: constMethodSummaries,
          })
          .then(function (response) {
              console.log(response)
              dispatch(QuotationDetailsActions.getFlatRiseElse({ tabId, constIdText }))
              return Promise.resolve(response)
          })
          .catch(function (error) {
              console.log(error)
          })
        }
      }
    }))

    //通常処理
    tabId = disptabId
    var constIdText = dispconstId
    var relatedDsItems
    var flatsummaries
    var risesummaries
    var elsesummaries
    flatsummaries=[]
    risesummaries=[]
    elsesummaries=[]

    console.log("表示タブ計算処理")

    if (constructionMethod['items'] && constructionMethod['items']['i_id'] && constructionMethod['items']['constId']) {
      const flatItems = calcSalesPurchaseTotalForRelDsData(
        createConstMethodRelatedDsItems(calcFraction2(flatResources), constructionMethod,constructionMethod['items']['constId']),
      )
      const riseItems = calcSalesPurchaseTotalForRelDsData(
        createConstMethodRelatedDsItems(calcFraction2(riseResources), constructionMethod,constructionMethod['items']['constId']),
      )
      const elseItems = calcSalesPurchaseTotalForRelDsData(
        createConstMethodRelatedDsItems(calcFraction(elseResources), constructionMethod,constructionMethod['items']['constId']),
      )

      flatItems.forEach((tempitem) => {
        flatsummaries.push(tempitem.item)
      })

      riseItems.forEach((tempitem) => {
        risesummaries.push(tempitem.item)
      })
      if (elseItems) {
        elseItems.forEach((tempitem) => {
          elsesummaries.push(tempitem.item)
        })
      }
      console.log("チェック用")
      console.log(flatResources.items)
      console.log(flatItems)
      var constMethodSummaries = calculateSummaries(
        flatResources.items[0].constructionVolume,
        riseResources.items[0].constructionVolume,
        flatsummaries,
        risesummaries,
        elsesummaries,
      )
// 工法・平場・立上り・その他のデータをDBに保存
      if (elseItems) {
        relatedDsItems = {
          [DATASTORE_ID.FLAT]: (flatItems as unknown) as RelatedDatastoreItem[],
          [DATASTORE_ID.RISE]: (riseItems as unknown) as RelatedDatastoreItem[],
          [DATASTORE_ID.ELSE]: elseItems,
        }
        dispatch(
          QuotationDetailsActions.saveFlatRiseElse({
            tabId,
            item: constMethodSummaries,
            itemId: constructionMethod['items']['i_id'],
            constIdText: constructionMethod['items']['constId'],
            relatedDsItems: {
              [DATASTORE_ID.FLAT]: (flatItems as unknown) as RelatedDatastoreItem[],
              [DATASTORE_ID.RISE]: (riseItems as unknown) as RelatedDatastoreItem[],
              [DATASTORE_ID.ELSE]: elseItems,
            },
          }),
        )
      } else {
        relatedDsItems = {
          [DATASTORE_ID.FLAT]: (flatItems as unknown) as RelatedDatastoreItem[],
          [DATASTORE_ID.RISE]: (riseItems as unknown) as RelatedDatastoreItem[],
        }

        dispatch(
          QuotationDetailsActions.saveFlatRiseElse({
            tabId,
            item: constMethodSummaries,
            itemId: constructionMethod['items']['i_id'],
            constIdText: constructionMethod['items']['constId'],
            relatedDsItems: {
              [DATASTORE_ID.FLAT]: (flatItems as unknown) as RelatedDatastoreItem[],
              [DATASTORE_ID.RISE]: (riseItems as unknown) as RelatedDatastoreItem[],
            },
          }),
        )
      }
    }
    console.log("resouce")
    console.log(constructionMethod)
  }

  return (
    <div>
      <ConstructionInfo
        mode={mode}
        tabId={tabId}
        isDisabled={isDisabled}
        resource={constructionMethod}
        isCustomerInfoSaved={isCustomerInfoSaved}
        removeConstructionDataTabPane={removeConstructionDataTabPane}
        handleSetConstructionVolume={handleSetConstructionVolume}
        handleSetConstructionVolumeAndSave={handleSetConstructionVolumeAndSave}
      />
      <ConstructionDetailTabs
        tabId={tabId}
        isDisabled={isDisabled}
        resource={{constructionMethod, flatResources, riseResources, elseResources }}
        handleCalculateSummaryResources={handleCalculateSummaryResources}
      />
    </div>
  )
}

export default ConstructionData
