import { FIELD_ID as QUOTATION_FIELD_ID, ELSE_ID } from 'app/constants/components/quotation'
import { QuotationDetailsState } from 'app/services/store/quotationDetails/state'

export const SEARCH_INFO = {
  QUOTATION_SEARCH: (statusesData: QuotationDetailsState['statusesData']) => {
    return {
      title: '見積書 履歴検索',
      options: [
        {
          label: 'ステータス',
          id: statusesData.id,
          dataType: 'select',
          options: statusesData.statues.map((status) => {
            return {
              id: status.id,
              name: status.name,
            }
          }),
        },
        {
          label: '得意先名',
          id: QUOTATION_FIELD_ID.CUSTOMER_NAME,
          dataType: 'text',
          options: [],
        },
        {
          label: '現場名',
          id: QUOTATION_FIELD_ID.SITE_NAME,
          dataType: 'text',
          options: [],
        },
        {
          label: '住所',
          id: QUOTATION_FIELD_ID.ADDRESS,
          dataType: 'text',
          options: [],
        },
        {
          label: '作成日付',
          id: QUOTATION_FIELD_ID.DATE_OF_CREATION,
          dataType: 'date',
          options: [],
        },
      ],
    }
  },
  MERCHANDISE_SEARCH: {
    title: '商品 検索追加',
    options: [
      {
        label: '商品ﾌﾘｶﾞﾅ',
        id: ELSE_ID.PRODUCT_NAME_KANA,
        dataType: 'text',
        options: [],
      },
      {
        label: 'メーカー',
        id: ELSE_ID.MAKER_NAME,
        dataType: 'text',
        options: [],
      },
      {
        label: '規格・容量',
        id: ELSE_ID.STANDARD_CAPACITY,
        dataType: 'number',
        options: [],
      },
      {
        label: '商品名',
        id: ELSE_ID.PRODUCT_NAME,
        dataType: 'text',
        options: [],
      },
    ],
  },
}
