import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { HEXA_LABEL, DATASTORE_ID } from 'app/constants/hexabase'
import { QuotationListItems } from 'app/types/components/quotation'

import { rootSelector } from 'app/services/store/rootReducer'
import { datastoresState } from 'app/services/store/datastore/selectors'
import {
  ItemsSelectors,
  ItemsActions,
  DatastoreActions,
  ItemDetailsSelectors,
  UsersSelectors,
  QuotationDetailsActions,
} from 'app/services/store'

import { customizeColumns, filterItemsToUnapproved, sortItemsByDate } from 'app/utils/components/quotation/quotation'
import { getDatastoreIdFromName } from 'app/utils/storeHelper'

import AppLayout from 'app/components/templates/AppLayout'
import QuotationListPage from 'app/components/pages/quotation/QuotationListPage'
import { itemsFields } from 'app/services/store/items/selectors'

const QuotationListContainer = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isAdmin = UsersSelectors.isAdmin(useSelector(rootSelector))
  const userId = UsersSelectors.getUserId(useSelector(rootSelector))
  const isQuotationItemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const isApproveItemsLoading = ItemsSelectors.getApproveItemsIsLoading(useSelector(rootSelector))
  const { items, columns } = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))
  const sortedItems = sortItemsByDate(items)
  const fields = useSelector(itemsFields)
  const ownEntries = ItemsSelectors.getDisplayableOwnEntries(useSelector(rootSelector))
  const sortedOwnItems = sortItemsByDate(ownEntries.items)
  const { datastores_list } = useSelector(datastoresState)
  const stateActions = ItemDetailsSelectors.getStateActions(useSelector(rootSelector))

  const quotationDBId = getDatastoreIdFromName(datastores_list, HEXA_LABEL.ESTIMATION_DB_NAME)
  const unapprovedItems = isAdmin ? filterItemsToUnapproved(sortedItems as QuotationListItems[]) : []
  const firstUnapprovedItem = unapprovedItems.length > 0 && unapprovedItems[0]
  const statusActionId = stateActions[0] && stateActions[0].a_id

  const deleteTableRow = useCallback(
    (itemId: string): void => {
      dispatch(
        ItemsActions.deleteItemRequest({
          itemId,
          datastoreId: quotationDBId,
          deleteLinkedItems: true,
          targetDatastores: [DATASTORE_ID.CONSTRUCTION_METHOD, DATASTORE_ID.FLAT, DATASTORE_ID.RISE, DATASTORE_ID.ELSE],
        }),
      )
    },
    [dispatch, quotationDBId],
  )

  const approveItems = useCallback(
    (targetIIds: string[]) => {
      dispatch(
        ItemsActions.executeBulkActionRequest({
          searchValue: targetIIds,
          datastoreId: quotationDBId,
          actionId: statusActionId,
        }),
      )
    },
    [dispatch, quotationDBId, statusActionId],
  )

  useEffect(() => {
    if (datastores_list) {
      dispatch(DatastoreActions.setCurrentDatastoreId({ datastoreId: quotationDBId }))
    }
    dispatch(QuotationDetailsActions.reset())
  }, [dispatch, datastores_list, quotationDBId])

  useEffect(() => {
    isAdmin && firstUnapprovedItem && dispatch(ItemsActions.setCurrentItemId({ itemId: firstUnapprovedItem['i_id'] }))
  }, [dispatch, isAdmin, firstUnapprovedItem])

  useEffect(() => {
    if (fields) {
      const status = fields.filter((field) => field.displayID === 'ステータス')[0]
      if (status && status.statuses) {
        dispatch(QuotationDetailsActions.setStatusesData({ id: status.field, statues: status.statuses }))
      }
    }
  }, [fields, dispatch])

  const modifiedColumns = customizeColumns(columns, history, isAdmin, userId, deleteTableRow)

  return (
    <AppLayout>
      <QuotationListPage
        isQuotationItemsLoading={isQuotationItemsLoading}
        isApproveItemsLoading={isApproveItemsLoading}
        isAdmin={isAdmin}
        columns={modifiedColumns}
        quotationItems={isAdmin ? (sortedItems as QuotationListItems[]) : (sortedOwnItems as QuotationListItems[])}
        unapprovedItems={unapprovedItems}
        approveItems={approveItems}
      />
    </AppLayout>
  )
}

export default QuotationListContainer
