import { QuotationTableItems } from 'app/types/components/quotationDetail'
import { RelatedDatastoreItem } from 'app/services/store/utils/linkerApis.types'

export const calculateGrossProfitMargin = (totalOfSales?: number, totalOfPurchase?: number): number => {
  if (totalOfPurchase) {
    if (totalOfSales) {
      const result = Math.floor(((totalOfSales - totalOfPurchase) / totalOfSales) * 1000) / 1000
      return result
    } else {
      const result = Math.floor(((0 - totalOfPurchase) / totalOfPurchase) * 1000) / 1000
      return result
    }
  } else {
    return 0
  }
}

export const calculateTotalSummary = (
  summariesOfSales?: number[],
  summariesOfPurchase?: number[],
): {
  siteTotalOfSales: number
  siteTotalOfPurchase: number
  siteTotalOfGrossProfitMargin: number
} => {
  const siteTotalOfSales = summariesOfSales
    ? summariesOfSales.reduce((prev, current) => {
        return prev + current
      }, 0)
    : 0
  const siteTotalOfPurchase = summariesOfPurchase
    ? summariesOfPurchase.reduce((prev, current) => {
        return prev + current
      }, 0)
    : 0
  const siteTotalOfGrossProfitMargin = calculateGrossProfitMargin(siteTotalOfSales, siteTotalOfPurchase)

  return { siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin }
}

// TODO: 正しい型定義
export const calculateSalesAndPurchaseTotal = (data?: QuotationTableItems[]): any => {
  if (data) {
    const result = data.map((item) => {

      const quantity = item.quantity
      const totalSales = (item.sales ? item.sales : 0) * quantity
      const totalPurchase = (item.purchase ? item.purchase : 0) * quantity
      const grossProfitMargin = calculateGrossProfitMargin(totalSales, totalPurchase)

      return {
        ...item,
        quantity,
        totalSales,
        totalPurchase,
        grossProfitMargin,
      }
    })
    return result
  } else {
    return undefined
  }
}

// TODO: 正しい型定義
export const calcSalesPurchaseTotalForRelDsData = (relatedItems?: RelatedDatastoreItem[]): any => {
  if (!relatedItems) {
    return undefined
  }
  const result = relatedItems.map((relatedItem) => {
    const quantity = relatedItem.item.quantity
    const totalSales = (relatedItem.item.sales ? relatedItem.item.sales : 0) * quantity
    const totalPurchase = (relatedItem.item.purchase ? relatedItem.item.purchase : 0) * quantity
    const grossProfitMargin = calculateGrossProfitMargin(totalSales, totalPurchase)

    return {
      ...relatedItem,
      item: {
        ...relatedItem.item,
        quantity,
        totalSales,
        totalPurchase,
        grossProfitMargin,
      },
    }
  })
  return result
}

export const sumTotalPrices = (
  calculatedTotalPrices?: QuotationTableItems[],
): {
  totalOfSales: number
  totalOfPurchase: number
} => {
  if (calculatedTotalPrices) {
    const totalOfSales = calculatedTotalPrices
      .map((item) => (item.totalSales ? item.totalSales : 0))
      .reduce((prev, current) => {
        return Number(prev) + Number(current)
      }, 0)
    const totalOfPurchase = calculatedTotalPrices
      .map((item) => (item.totalPurchase ? item.totalPurchase : 0))
      .reduce((prev, current) => {
        return Number(prev) + Number(current)
      }, 0)
    return { totalOfSales, totalOfPurchase }
  } else {
    return { totalOfSales: 0, totalOfPurchase: 0 }
  }
}

export const calculateTotal = (data?: QuotationTableItems[], unit?: number): number[] => {
  const calculatedTotalPrices = calculateSalesAndPurchaseTotal(data)
  const { totalOfSales, totalOfPurchase } = sumTotalPrices(calculatedTotalPrices)
  const unitCost = unit ? totalOfSales / unit : 0
  const grossProfitMargin = calculateGrossProfitMargin(totalOfSales, totalOfPurchase)

  return [totalOfSales, totalOfPurchase, unitCost, grossProfitMargin]
}

// TODO: 正しい型定義,リネーム
export const calculateSummaries = (
  unit?: number,
  unit2?: number,
  flatResources?: any,
  riseResources?: any,
  elseResources?: any,
): { [k: string]: number } => {
  const [flatTabTotalSales, flatTabTotalPurchase, flatM2UnitPrice, flatTabTotalGrossProfitMargin] = calculateTotal(
    flatResources,
    unit,
  )
  const [riseTabTotalSales, riseTabTotalPurchase, riseM2UnitPrice, riseTabTotalGrossProfitMargin] = calculateTotal(
    riseResources,
    unit2,
  )
  const [elseTabTotalSales, elseTabTotalPurchase, elseM2UnitPrice, elseTabTotalGrossProfitMargin] = calculateTotal(
    elseResources,
    unit,
  )
  const constTotalOfSales = flatTabTotalSales + riseTabTotalSales + elseTabTotalSales
  const constTotalOfPurchase = flatTabTotalPurchase + riseTabTotalPurchase + elseTabTotalPurchase
  const constTotalOfGrossProfitMargin = calculateGrossProfitMargin(constTotalOfSales, constTotalOfPurchase)

  const flatTabTotal = {
    flatTabTotalSales,
    flatTabTotalPurchase,
    flatM2UnitPrice,
    flatTabTotalGrossProfitMargin,
  }
  const riseTabTotal = {
    riseTabTotalSales,
    riseTabTotalPurchase,
    riseM2UnitPrice,
    riseTabTotalGrossProfitMargin,
  }
  const elseTabTotal = {
    elseTabTotalSales,
    elseTabTotalPurchase,
    elseM2UnitPrice,
    elseTabTotalGrossProfitMargin,
  }
  const constTotal = {
    constTotalOfSales,
    constTotalOfPurchase,
    constTotalOfGrossProfitMargin,
  }
  return {
    ...flatTabTotal,
    ...riseTabTotal,
    ...elseTabTotal,
    ...constTotal,
  }
}
