import React, { useState } from 'react'

import Frame from 'app/components/atoms/Frame'
import LowestPriceModalContainer from 'app/containers/quotation/organisms/LowestPriceModalContainer'
console.log("0")
interface SearchLowestPriceButtonProps {
  Id?: string
  iId?: string
  tabId: string
  SetLowestPrice: Function
  productCode: string
  targetItem: any
  customerCode: string
  siteName: string
  tabType?: string
}
const SearchLowestPriceButton = ({
  Id,
  iId,
  tabId,
  SetLowestPrice,
  productCode,
  customerCode,
  targetItem,
  siteName,
  tabType,
}: SearchLowestPriceButtonProps): JSX.Element => {
  const [searchLowestPriceModalVisibility, setSearchLowestPriceModalVisibility] = useState(false)

  return (
    <div>
      <div onClick={(): void => setSearchLowestPriceModalVisibility(true)}>
        <Frame
          color={'rgb(79, 157, 138)'}
          style={{ padding: '3px 0 0', textAlign: 'center', height: '30px', width: '30px', cursor: 'pointer' }}
        >
          ￥
        </Frame>
      </div>
      <LowestPriceModalContainer
        visible={searchLowestPriceModalVisibility}
        Id={Id}
        iId={iId}
        tabId={tabId}
        productCode={productCode}
        customerCode={customerCode}
        targetItem={targetItem}
        siteName={siteName}
        tabType={tabType}
        SetLowestPrice={SetLowestPrice}
        onCancel={(): void => setSearchLowestPriceModalVisibility(false)}
      />
    </div>
  )
}

export default SearchLowestPriceButton
