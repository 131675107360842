import rootStore from 'app/services/store/rootStore'
import { UsersActions } from 'app/services/store'

class AuthHelper {
  /**
   * @static
   * @description return the user auth key stored
   *
   * @returns {string} the user auth key
   */
  public static getAuthKey = (): string => {
    const state = rootStore.store.getState()
    return state.user.auth_key
  }

  public static logout = (): void => {
    rootStore.store.dispatch(UsersActions.logout())
    return
  }
}

export default AuthHelper
