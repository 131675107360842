import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { DeleteFilled } from '@ant-design/icons'

interface DeleteTableRowButtonProps {
  iId?: string
  isSaved?: boolean
  deleteRow: Function
}
const DeleteTableRowButton = ({ iId, deleteRow, isSaved }: DeleteTableRowButtonProps): JSX.Element => {
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)

  const onOk = (): void => {
    iId && deleteRow(iId, isSaved)
    setModalVisibility(false)
  }
  const onCancel = (): void => {
    setModalVisibility(false)
  }
  return (
    <div>
      <Button onClick={(): void => setModalVisibility(true)} icon={<DeleteFilled />} style={{ color: 'orangeRed' }} />
      <Modal visible={modalVisibility} onOk={onOk} onCancel={onCancel}>
        <p>選択された行を削除します。よろしいですか。</p>
      </Modal>
    </div>
  )
}

export default DeleteTableRowButton
