import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Form, Button, Card, Typography, Input, Spin } from 'antd'

import { ROUTE_PATH } from 'app/constants/router'

const { Text } = Typography

interface ForgotPasswordProps {
  forgotPassword: (email: string) => void
  isLoading: boolean
}

const ForgotPasswordPage = ({ forgotPassword, isLoading }: ForgotPasswordProps): JSX.Element => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()

  const onFinish = (values): void => {
    forgotPassword(values.email)
  }

  useEffect(() => {
    // To disable submit button at the beginning.
    forceUpdate({})
    // eslint-disable-next-line
  }, [])

  return (
    <Spin size="large" spinning={isLoading}>
      <div style={{ background: '#F3F3F3' }}>
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <Row justify="center">
            <Col span={24}>
              <Card className="loginForm">
                <Row
                  justify="center"
                  style={{
                    fontSize: '20px',
                    color: '#1589d6',
                    marginTop: '26px',
                    marginBottom: '25px',
                  }}
                >
                  パスワードのリセット
                </Row>
                <Row justify="center" style={{ margin: '20px 0', textAlign: 'center' }}>
                  <Text>リセットするアカウントのメールアドレスを入力してください</Text>
                </Row>
                <Form form={form} onFinish={onFinish} layout="vertical" size="large">
                  <Form.Item
                    label="メールアドレス"
                    name="email"
                    rules={[
                      { required: true, message: 'メールアドレスの入力は必須です' },
                      { type: 'email', message: 'メールアドレスの入力形式に誤りがあります' },
                    ]}
                  >
                    <Input size="large" placeholder="メールアドレス" style={{ width: '400px' }} />
                  </Form.Item>
                  <Form.Item shouldUpdate={true}>
                    {(): JSX.Element => (
                      <Row justify="center" style={{ marginTop: '15px' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: '300px' }}
                          disabled={
                            !form.isFieldsTouched(true) ||
                            form.getFieldsError().filter(({ errors }) => errors.length).length
                              ? true
                              : false
                          }
                        >
                          パスワードリセット
                        </Button>
                      </Row>
                    )}
                  </Form.Item>
                </Form>
                <Row justify="center">
                  <Button
                    type="primary"
                    onClick={(): void => history.push(ROUTE_PATH.LOGIN)}
                    style={{ width: '300px' }}
                    size="large"
                  >
                    戻る
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Row>
      </div>
    </Spin>
  )
}

export default ForgotPasswordPage
