import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'

import { rootSelector } from 'app/services/store/rootReducer'
import { UsersActions, UsersSelectors } from 'app/services/store'

import LoginPage from 'app/components/pages/login/LoginPage'

const LoginContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const userToken = UsersSelectors.getUserToken(useSelector(rootSelector))
  const loginRequest = React.useCallback(
    (email: string, password: string): void => {
      dispatch(UsersActions.loginRequest({ email, password }))
    },
    [dispatch],
  )

  if (userToken) {
    return <Redirect to="/" />
  }
  return <LoginPage userToken={userToken} loginRequest={loginRequest} />
}

export default LoginContainer
