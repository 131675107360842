import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

/**
 * @namespace
 * @property {Function} getItemDetailsRequest     - Request to get the item details
 * @property {Function} getItemDetailsSuccess     - Above on success (Do not call outside of epic)
 * @property {Function} getItemDetailsFailed      - Above on failure (Do not call outside of epic)
 * @property {Function} getActionSettingsRequest  - Request to get the action settings
 * @property {Function} getActionSettingsSuccess  - Above on success (Do not call outside of epic)
 * @property {Function} getActionSettingsFailed   - Above on failure (Do not call outside of epic)
 * @property {Function} updateItemRequest         - Request to update the item
 * @property {Function} updateItemSuccess         - Above on success (Do not call outside of epic)
 * @property {Function} updateItemFailed          - Above on failure (Do not call outside of epic)
 * @property {Function} newItemRequest            - Request to execute the action to create a new item (used to save a new item)
 * @property {Function} newItemSuccess            - Above on success (Do not call outside of epic)
 * @property {Function} newItemFailed             - Above on failure (Do not call outside of epic)
 * @property {Function} newItemModeRequest        - Request to enter in the mode New Item (used to get temporary item id)
 * @property {Function} newItemModeSuccess        - Above on success (Do not call outside of epic)
 * @property {Function} newItemModeFailed         - Above on failure (Do not call outside of epic)
 * @property {Function} goToItem                  - Go to another item in the workspace but in another project or datastore
 * @property {Function} selectAction              - Select an action
 * @property {Function} setRevisionNumber         - Set the item revision number
 * @property {Function} setMode                   - Set the item detail mode (open, close ...)
 * @property {Function} reset                     - Reset the store to initial state
 */

export const itemDetailsSlice = createSlice({
  name: 'itemDetails',
  initialState: initialState,
  reducers: {
    getItemDetailsRequest: reducers.getItemDetailsRequest,
    getItemDetailsSuccess: reducers.getItemDetailsSuccess,
    getItemDetailsFailed: reducers.getItemDetailsFailed,
    selectAction: reducers.selectAction,
    getActionSettingsRequest: reducers.getActionSettingsRequest,
    getActionSettingsSuccess: reducers.getActionSettingsSuccess,
    getActionSettingsFailed: reducers.getActionSettingsFailed,
    updateItemRequest: reducers.updateItemRequest,
    updateItemSuccess: reducers.updateItemSuccess,
    updateItemFailed: reducers.updateItemFailed,
    newItemRequest: reducers.newItemRequest,
    newItemSuccess: reducers.newItemSuccess,
    newItemFailed: reducers.newItemFailed,
    setRevisionNumber: reducers.setRevisionNumber,
    newItemModeRequest: reducers.newItemModeRequest,
    newItemModeSuccess: reducers.newItemModeSuccess,
    newItemModeFailed: reducers.newItemModeFailed,
    cancelAction: reducers.cancelAction,
    goToItem: reducers.goToItem,
    setMode: reducers.setMode,
    getLinkedAllDbsItemsRequest: reducers.getLinkedAllDbsItemsRequest,
    getLinkedAllDbsItemsSuccess: reducers.getLinkedAllDbsItemsSuccess,
    getLinkedAllDbsItemsFaild: reducers.getItemDetailsFailed,
    createItemRequest: reducers.createItemRequest,
    createItemSuccess: reducers.deleteItemSuccess,
    createItemFailed: reducers.deleteItemFailed,
    deleteItemRequest: reducers.deleteItemRequest,
    deleteItemSuccess: reducers.deleteItemSuccess,
    deleteItemFailed: reducers.deleteItemFailed,
    reset: reducers.reset,
  },
})

export const {
  getItemDetailsRequest,
  getItemDetailsSuccess,
  getItemDetailsFailed,
  selectAction,
  getActionSettingsRequest,
  getActionSettingsSuccess,
  getActionSettingsFailed,
  deleteItemRequest,
  deleteItemSuccess,
  deleteItemFailed,
  createItemRequest,
  createItemSuccess,
  createItemFailed,
  updateItemRequest,
  updateItemSuccess,
  updateItemFailed,
  newItemRequest,
  newItemSuccess,
  newItemFailed,
  setRevisionNumber,
  newItemModeRequest,
  newItemModeSuccess,
  newItemModeFailed,
  getLinkedAllDbsItemsRequest,
  getLinkedAllDbsItemsSuccess,
  getLinkedAllDbsItemsFaild,
  cancelAction,
  goToItem,
  setMode,
  reset,
} = itemDetailsSlice.actions
