import React from 'react'
import Logger from 'app/utils/logger'

class ErrorBoundary extends React.Component {
  /**
   * @namespace
   * @property {boolean} hasError - does the component catch an error
   */
  state = { hasError: false }

  /**
   * @static
   * @param {any} error the error
   *
   *  @description Update state so the next render will show the fallback UI.
   *
   * @returns {{hasError: boolean}}
   */
  static getDerivedStateFromError(error: any): { hasError: boolean } {
    return { hasError: true }
  }

  /**
   * @param {any} error the error
   * @param {any} error the error
   *  @description You can also log the error to an error reporting service
   * logErrorToMyService(error, errorInfo);
   * @returns {void}
   */
  componentDidCatch(error: any, errorInfo: any): void {
    Logger.console.log(error)
    Logger.console.log(errorInfo)
  }

  /**
   * @description default renderer
   * @returns {JSX.Element | React.ReactNode}
   */
  render(): JSX.Element | React.ReactNode {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
