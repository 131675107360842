import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { History } from 'history'

import ErrorBoundary from 'app/components/shared/errorBoundary/errorBoundary'
import PrivateRoute from 'app/config/router/privateRoute'
import { ROUTE_PATH } from 'app/constants/router'

import LoginContainer from 'app/containers/login/LoginContainer'
import ForgotPasswordContainer from 'app/containers/login/ForgotPasswordContainer'
import ResetPasswordContainer from 'app/containers/login/ResetPasswordContainer'
import QuotationListContainer from 'app/containers/quotation/QuotationListContainer'
import QuotationSearchResultContainer from 'app/containers/search/QuotationSearchResultContainer'
import CreateQuotationDetailContainer from 'app/containers/quotation/CreateQuotationDetailContainer'
import EditQuotationDetailContainer from 'app/containers/quotation/EditQuotationDetailContainer'
import CopyQuotationDetailContainer from 'app/containers/quotation/CopyQuotationDetailContainer'

interface CustomRouterProps {
  history: History
}

const AppRouter = ({ history }: CustomRouterProps): JSX.Element => {
  return (
    <Router history={history}>
      <ErrorBoundary>
        <Switch>
          <Route path={ROUTE_PATH.LOGIN} component={LoginContainer} />
          <Route path={ROUTE_PATH.FORGOT_PASSWORD} component={ForgotPasswordContainer} />
          <Route path={ROUTE_PATH.RESET_PASSWORD} component={ResetPasswordContainer} />
          <PrivateRoute exact path={ROUTE_PATH.QUOTATION_SEARCH_RESULT} component={QuotationSearchResultContainer} />
          <PrivateRoute exact path={ROUTE_PATH.QUOTATION_LIST} component={QuotationListContainer} />
          <PrivateRoute exact path={ROUTE_PATH.QUOTATION_CREATE} component={CreateQuotationDetailContainer} />
          <PrivateRoute exact path={`${ROUTE_PATH.QUOTATION_EDIT}/:id`} component={EditQuotationDetailContainer} />
          <PrivateRoute exact path={ROUTE_PATH.QUOTATION_COPY} component={CopyQuotationDetailContainer} />
          <PrivateRoute component={QuotationListContainer} />
        </Switch>
      </ErrorBoundary>
    </Router>
  )
}

export default AppRouter
