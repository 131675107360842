import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

/**
 * @namespace
 * @property {Function} getItemsListRequest   - Request to get the datastore item list
 * @property {Function} getItemsListSuccess   - Above on success (Do not call outside of epic)
 * @property {Function} getItemsListFailed    - Above on failure (Do not call outside of epic)
 * @property {Function} deleteItemRequest     - Request to delete an item from the list
 * @property {Function} deleteItemSuccess     - Above on success (Do not call outside of epic)
 * @property {Function} deleteItemFailed      - Above on failure (Do not call outside of epic)
 * @property {Function} setCurrentItemId      - Set the current item Id
 * @property {Function} updateEntry           - Update an entry of the list
 * @property {Function} addEntry              - Add an entry to the list
 * @property {Function} reset                 - Reset the store to initial state
 */

export const itemsSlice = createSlice({
  name: 'items',
  initialState: initialState,
  reducers: {
    setCurrentItemId: reducers.setCurrentItemId,
    getItemsListRequest: reducers.getItemsListRequest,
    getItemsListSuccess: reducers.getItemsListSuccess,
    getItemsListFailed: reducers.getItemsListFailed,
    deleteItemRequest: reducers.deleteItemRequest,
    deleteItemSuccess: reducers.deleteItemSuccess,
    deleteItemFailed: reducers.deleteItemFailed,
    updateEntry: reducers.updateEntry,
    addEntry: reducers.addEntry,
    itemsSearchRequest: reducers.itemsSearchRequest,
    itemsSearchSuccess: reducers.itemsSearchSuccess,
    itemsSearchFaild: reducers.itemsSearchFailed,
    itemsSearchReset: reducers.itemsSearchReset,
    deleteItemFromSearchResultRequest: reducers.deleteItemFromSearchResultRequest,
    deleteItemFromSearchResultFaild: reducers.deleteItemFromSearchResultFailed,
    deleteItemFromSearchResultSuccess: reducers.deleteItemFromSearchResultSuccess,
    executeBulkActionRequest: reducers.executeBulkActionRequest,
    executeBulkActionSuccess: reducers.executeBulkActionSuccess,
    executeBulkActionFailed: reducers.executeBulkActionFailed,
    reset: reducers.reset,
  },
})

export const {
  setCurrentItemId,
  getItemsListRequest,
  getItemsListSuccess,
  getItemsListFailed,
  deleteItemRequest,
  deleteItemSuccess,
  deleteItemFailed,
  updateEntry,
  addEntry,
  itemsSearchRequest,
  itemsSearchSuccess,
  itemsSearchFaild,
  itemsSearchReset,
  deleteItemFromSearchResultRequest,
  deleteItemFromSearchResultFaild,
  deleteItemFromSearchResultSuccess,
  executeBulkActionRequest,
  executeBulkActionSuccess,
  executeBulkActionFailed,
  reset,
} = itemsSlice.actions
