import Axios from 'axios-observable'
import AuthHelper from 'app/services/authHelper/authHelper'
import { ErrorHandlerService } from 'app/services/errorHandler/errorHandler'
import Logger from 'app/utils/logger'

Axios.interceptors.request.use(
  (request) => {
    const authKey = AuthHelper.getAuthKey()
    if (authKey) {
      Logger.console.log(`[${request.method}] --> ${request.url}`)
      request.headers.common = {
        ...request.headers.common,
        ...{
          Authorization: `Bearer ${authKey}`,
        },
      }
    }
    return request
  },
  (error) => {
    Logger.console.log(error)
    return Promise.reject(error)
  },
)

Axios.interceptors.response.use(
  (response) => {
    try {
      Logger.console.log(`[response] --> ${response.status}`)
    } catch (e) {
      Logger.console.log(e)
    }
    return response
  },
  (error) => {
    Logger.console.log(`[error] --> ${error}`)
    if (error && error.response) {
      switch (error.response.status) {
        case 201:
        case 200:
          break
        default:
          ErrorHandlerService.addError(
            {
              message:
                typeof error === 'string'
                  ? error
                  : ErrorHandlerService.createErrorMsgFromStatusCode(error.response.status),
              time: Date.now(),
            },
            true,
          )
          break
      }
    }
    throw error
  },
)
