import { Observable, Action } from 'redux'
import { PayloadAction } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'

import { of, EMPTY } from 'rxjs'
import { catchError, switchMap, concatMap, tap, ignoreElements } from 'rxjs/operators'
import { AxiosError } from 'axios'

import { APP_CONSTANT } from 'app/constants/app'

import HttpService from 'app/services/httpService/httpService'
import { getWorkspaceIdFromName, getProjectIdFromName } from 'app/utils/storeHelper'

import * as storeModels from 'app/services/store/system/types'
import * as apiModels from 'app/services/store/system/epic.types'
import { HEXA_LABEL } from 'app/constants/hexabase'
import { SystemActions, UsersSelectors, SystemSelectors, DatastoreActions } from '..'

import { history } from 'App'

/**
 * @action INIT
 * @in [PERSIST-STORE] REHYDRATE
 *
 * @description This is an init action called automatically  when the
 * store have been rehydrated. Here used to update the workspace list
 * If you want to know why this is called : https://github.com/b-eee/hexalite/wiki/Store-Architecture (check EPIC subpart)
 *
 * @outSuccess [Workspace] getWorkspacesRequest
 */
const initEpic = (action$, state$): Observable<Action<string>> => {
  return action$.pipe(
    ofType(APP_CONSTANT.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      const isLoggedIn = UsersSelectors.isUserLogged(state$.value)
      if (isLoggedIn) {
        return of(SystemActions.getWorkspacesRequest())
      } else {
        return EMPTY
      }
    }),
  )
}

// Get list of workspaces and call the set current work space id request action.
const getWorkspacesEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(SystemActions.getWorkspacesRequest),
    switchMap(() =>
      HttpService.GetAsync<null, apiModels.api_get_all_workspace_response>('get_all_workspaces').pipe(
        switchMap((response) => {
          const workspaces = response.data ? response.data.workspaces : new Array<storeModels.Workspace>()

          const workspaceId = response.data ? getWorkspaceIdFromName(response.data, HEXA_LABEL.WORKSPACE_NAME) : ''
          return [
            SystemActions.getWorkspacesSuccess({ workspaces }),
            SystemActions.setCurrentWorkspaceIdRequest({ workspaceId }),
          ]
        }),
        catchError((error: AxiosError) => {
          return of(SystemActions.getWorkspacesFailed({ error: error.message }))
        }),
      ),
    ),
  )

// Set current workspace and call api that get list of projects.
const setCurrentWorkspaceIdEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(SystemActions.setCurrentWorkspaceIdRequest),
    switchMap((action: PayloadAction<{ workspaceId: string }>) => {
      return HttpService.PostAsync<apiModels.api_post_set_current_workspace_id_request, null>(
        `post_set_current_workspace`,
        { workspace_id: action.payload.workspaceId },
      ).pipe(
        switchMap(() => {
          return [
            SystemActions.setCurrentWorkspaceIdSuccess(action.payload),
            SystemActions.getProjectsRequest(action.payload),
          ]
        }),
        catchError((error: AxiosError) => {
          return of(SystemActions.setCurrentWorkspaceIdFailed({ error: error.message }))
        }),
      )
    }),
  )

const getProjectsEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(SystemActions.getProjectsRequest),
    switchMap((action: PayloadAction<{ workspaceId: string }>) =>
      HttpService.GetAsync<null, apiModels.api_get_applications_list_response>(`get_applications_list`).pipe(
        switchMap((projects) => {
          return [
            SystemActions.getProjectsSuccess({ projects: projects.data || [] }),
            SystemActions.setCurrentProjectId({
              projectId: getProjectIdFromName(projects.data, HEXA_LABEL.PROJECT_NAME),
            }),
          ]
        }),
        catchError((error: AxiosError) => {
          return of(SystemActions.getProjectsFailed({ error: error.message }))
        }),
      ),
    ),
  )

const setCurrentProjectIdEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(SystemActions.setCurrentProjectId),
    switchMap((action: PayloadAction<{ projectId: string }>) => {
      const project = SystemSelectors.getProjectById(state$.value)(action.payload.projectId)
      return of(DatastoreActions.getDatastoresSuccess({ datastores: project ? project.datastores : [] }))
    }),
  )

const navigateEpic = (action$) =>
  action$.pipe(
    ofType(SystemActions.navigate),
    tap((action: PayloadAction<{ path: string }>) => history.push(action.payload.path)),
    ignoreElements(),
  )

export default [
  initEpic,
  getWorkspacesEpic,
  setCurrentWorkspaceIdEpic,
  getProjectsEpic,
  setCurrentProjectIdEpic,
  navigateEpic,
]
