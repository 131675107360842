import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { rootSelector } from 'app/services/store/rootReducer'
import { UsersActions, UsersSelectors } from 'app/services/store'

import ForgotPasswordPage from 'app/components/pages/login/ForgotPasswordPage'

const ForgotPasswordContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const isLoading = UsersSelectors.isLoading(useSelector(rootSelector))

  const forgotPassword = React.useCallback(
    (email: string): void => {
      dispatch(UsersActions.forgotPasswordRequest({ email }))
    },
    [dispatch],
  )
  return <ForgotPasswordPage forgotPassword={forgotPassword} isLoading={isLoading} />
}

export default ForgotPasswordContainer
