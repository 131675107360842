import React from 'react'

import 'antd/dist/antd.css'
import 'app/utils/logger'
import { PersistGate } from 'redux-persist/integration/react'
import { createBrowserHistory } from 'history'

import rootStore from './app/services/store/rootStore'
// import Preloader from './app/components/shared/preloader/preloader'
import AppRouter from 'app/config/router/AppRouter'

export const history = createBrowserHistory()
const App: React.FC = () => {
  return (
    <div className="App">
      <PersistGate persistor={rootStore.persistor}>
        {/* <Preloader /> */}
        <AppRouter history={history} />
      </PersistGate>
    </div>
  )
}

export default App
