import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Button, Table } from 'antd'

import CreateAndSearchButtons from 'app/components/organisms/quotation/CreateAndSearchButtons'
import QuotationSearchContainer from 'app/containers/search/QuotationSearchContainer'

interface ResultProps<Columns, DataSource extends { key: string }> {
  columns: Columns[]
  dataSource: DataSource[]
  title: string
  routePathForList: string
  isCustomerSearchResult?: boolean
  reset: Function
}

const Result = <Columns, DataSource extends { key: string }>({
  columns,
  dataSource,
  title,
  routePathForList,
  isCustomerSearchResult,
  reset,
}: ResultProps<Columns, DataSource>): JSX.Element => {
  const history = useHistory()
  const [searchModalVisibility, setSearchModalVisibility] = useState<boolean>(false)

  return (
    <div style={{ padding: '20px 40px', backgroundColor: 'white' }}>
      <Row justify="space-between" style={{ marginBottom: '50px' }}>
        <Col span={6}>
          <h2>{title} 検索結果一覧</h2>
        </Col>
        <Col span={4}>
          <Button
            onClick={(): void => {
              history.push(routePathForList)
              reset()
            }}
            type="primary"
            size="large"
            style={{ backgroundColor: 'rgb(68, 106, 170)' }}
          >
            検索条件をクリア
          </Button>
        </Col>
        <Col span={6}>
          {isCustomerSearchResult && <CreateAndSearchButtons setSearchModalVisibility={setSearchModalVisibility} />}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table columns={columns} dataSource={dataSource} />
        </Col>
      </Row>
      <QuotationSearchContainer
        visible={searchModalVisibility}
        onCancel={(): void => setSearchModalVisibility(false)}
      />
    </div>
  )
}

export default Result
