import * as UsersSelectors from './user/selectors'
import { userSlice } from './user/slice'

import * as SystemSelectors from './system/selectors'
import { systemSlice } from './system/slice'

import * as DataStoreSelectors from './datastore/selectors'
import { datastoresSlice } from './datastore/slice'

import * as ItemsSelectors from './items/selectors'
import { itemsSlice } from './items/slice'

import * as ItemDetailsSelectors from './itemDetails/selectors'
import { itemDetailsSlice } from './itemDetails/slice'

import * as QuotationDetailsSelectors from './quotationDetails/selectors'
import { quotationDetailsSlice } from './quotationDetails/slice'

const UsersActions = {
  ...userSlice.actions,
}

const SystemActions = {
  ...systemSlice.actions,
}

const DatastoreActions = {
  ...datastoresSlice.actions,
}

const ItemsActions = {
  ...itemsSlice.actions,
}

const ItemsDetailsActions = {
  ...itemDetailsSlice.actions,
}

const QuotationDetailsActions = {
  ...quotationDetailsSlice.actions,
}

export {
  UsersSelectors,
  UsersActions,
  SystemSelectors,
  SystemActions,
  DatastoreActions,
  DataStoreSelectors,
  ItemsActions,
  ItemsSelectors,
  ItemsDetailsActions,
  ItemDetailsSelectors,
  QuotationDetailsActions,
  QuotationDetailsSelectors,
}
