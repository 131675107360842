import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rootSelector } from 'app/services/store/rootReducer'
import { QuotationDetailsActions, QuotationDetailsSelectors } from 'app/services/store'

import { DATAREPORTS_ID, DATASTORE_ID } from 'app/constants/hexabase'

import LowestPriceModal from 'app/components/organisms/quotation/quotationDetail/LowestPriceModal'

interface LowestPriceModalContainerProps {
  visible: boolean
  Id?: string
  iId?: string
  tabId: string
  constIdText?: string
  productCode: string
  targetItem: any
  customerCode: string
  siteName: string
  tabType?: string
  SetLowestPrice: Function
  onCancel: Function
}

const LowestPriceModalContainer = (props: LowestPriceModalContainerProps): JSX.Element => {
  const { visible, Id, iId, tabId, constIdText, productCode, customerCode, siteName, tabType, targetItem, SetLowestPrice, onCancel } = props
  const dispatch = useDispatch()
  
  const lowestPrices = QuotationDetailsSelectors.getLowestPrices(useSelector(rootSelector))
  const lowestSaless = QuotationDetailsSelectors.getLowestSaless(useSelector(rootSelector))
  const isLoading = QuotationDetailsSelectors.isLoading(useSelector(rootSelector))
  const handleOnOk = (lowestPriceOfSale: number, lowestPriceOPurchase: number,targetItems: any): void => {
    SetLowestPrice(lowestPriceOfSale, lowestPriceOPurchase,targetItems, iId, tabId, constIdText)
    onCancel()
  }
  const handleOnCancel = (): void => {
    onCancel()
  }

  const [reportIdForAll, reportIdForCustomer] =
    tabType === '平場'
      ? [DATAREPORTS_ID.REPORT_LOWEST_PRICE_FLAT_ALL, DATAREPORTS_ID.REPORT_LOWEST_PRICE_FLAT_CUSTOMER, DATASTORE_ID.FLAT]
      : tabType === '立上り'
      ? [DATAREPORTS_ID.REPORT_LOWEST_PRICE_RISE_ALL, DATAREPORTS_ID.REPORT_LOWEST_PRICE_RISE_CUSTOMER, DATASTORE_ID.RISE]
      : [DATAREPORTS_ID.REPORT_LOWEST_PRICE_ELSE_ALL, DATAREPORTS_ID.REPORT_LOWEST_PRICE_ELSE_CUSTOMER, DATASTORE_ID.ELSE]

  const getLowestPrice = useCallback(async () => {
    const conditionForAll = [
      {
        id: 'productCode',
        search_value: [productCode],
        exact_match: true,
      },
      {
        id: 'purchase',
        search_value: ["1" , null],
      },
      {
        id: 'sales',
        search_value: ["1" , null],
      },
    ]
    const conditionForCustomer = [
      {
        id: 'productCode',
        search_value: [productCode],
        exact_match: true,
      },
      {
        id: 'customerCode',
        search_value: [customerCode],
        exact_match: true,
      },
      {
        id: 'siteName',
        search_value: [siteName],
        exact_match: true,
      },
      {
        id: 'purchase',
        search_value: ["1" , null],
      },
      {
        id: 'sales',
        search_value: ["1" , null],
      },

    ]
    dispatch(
      QuotationDetailsActions.getLowestPriceRequest({
        target: 'lowestPriceDataOfAll',
        reportId: reportIdForAll,
        conditions: conditionForAll,
        type: tabType as '平場' | '立上り' | 'その他',
      }),
    )

    dispatch(
      QuotationDetailsActions.getLowestPriceRequest({
        target: 'lowestPriceDataOfCustomer',
        reportId: reportIdForCustomer,
        conditions: conditionForCustomer,
        type: tabType as '平場' | '立上り' | 'その他',
      }),
    )
  }, [dispatch, customerCode, productCode, reportIdForAll, reportIdForCustomer, siteName, tabType])

  const getLowestSales = useCallback(async () => {
    const conditionForAll = [
      {
        id: 'productCode',
        search_value: [productCode],
        exact_match: true,
      },
      {
        id: 'purchase',
        search_value: ["1" , null],
      },
      {
        id: 'sales',
        search_value: ["1" , null],
      },
    ]
    const conditionForCustomer = [
      {
        id: 'productCode',
        search_value: [productCode],
        exact_match: true,
      },
      {
        id: 'customerCode',
        search_value: [customerCode],
        exact_match: true,
      },
      {
        id: 'siteName',
        search_value: [siteName],
        exact_match: true,
      },
      {
        id: 'purchase',
        search_value: ["1" , null],
      },
      {
        id: 'sales',
        search_value: ["1" , null],
      },

    ]

    dispatch(
      QuotationDetailsActions.getLowestSalesRequest({
        target: 'lowestSalesDataOfAll',
        reportId: reportIdForAll,
        conditions: conditionForAll,
        type: tabType as '平場' | '立上り' | 'その他',
      }),
    )

    dispatch(
      QuotationDetailsActions.getLowestSalesRequest({
        target: 'lowestSalesDataOfCustomer',
        reportId: reportIdForCustomer,
        conditions: conditionForCustomer,
        type: tabType as '平場' | '立上り' | 'その他',
      }),
    )

  }, [dispatch, customerCode, productCode, reportIdForAll, reportIdForCustomer, siteName, tabType])
  
  useEffect(() => {
    if (visible) {
      getLowestSales()
      getLowestPrice()
    }
    return (): void => {
      dispatch(QuotationDetailsActions.resetLowestPrice())
      dispatch(QuotationDetailsActions.resetLowestSales())
    }
  }, [visible, getLowestPrice, getLowestSales, dispatch])
  return (
    <LowestPriceModal
      visible={visible}
      lowestPrices={lowestPrices}
      lowestSaless={lowestSaless}
      iId={iId}
      tabType={tabType}
      targetItem={targetItem}
      handleOnOk={handleOnOk}
      handleOnCancel={handleOnCancel}
      isLoading={isLoading}
    />
  )
}

export default LowestPriceModalContainer
