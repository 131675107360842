export const ERROR_MESSAGES = {
  INVALID_EMAIL: '入力されたメールアドレスは登録されていません。',
  INVALID_LOGIN: 'メールアドレスまたはパスワードが正しくありません。',
  USED_LINK: 'すでにパスワード変更を実行済みのリンクがクリックされました。',
  EXPIRED_LINK: 'パスワード変更の有効期限が切れています。再度リセットを行って下さい。',
  TOKEN_EXPIRED: 'ログインの有効期限が切れました。ログインし直してください。',
  INTERNAL_SERVERERROR:
    'システムに問題が発生しました。少し時間をおいてからリロードして再実行願います。問題が解消しない場合はサポートまでお問い合わせ下さい。',
}

export const MESSAGES = {
  SEND_EMAIL: 'パスワード変更用のメールを送信しました。',
  RESET_PASSWORD: 'パスワードを変更しました。新しいパスワードでログインを行なってください。',
}
