import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Card, Typography, Input, Spin } from 'antd'

import useTextInput from 'app/hooks/useTextInput'

interface ChangePasswordProps {
  isLoading: boolean
  changePassword: (newPassword: string, confirmPassword: string) => void
}

const { Text } = Typography

const ResetPasswordPage = (props: ChangePasswordProps): JSX.Element => {
  const [newPassword, setNewPassword] = useTextInput('')
  const [confirmPassword, setConfirmPassword] = useTextInput('')
  const [, forceUpdate] = useState()
  const [form] = Form.useForm()

  const onFinish = (): void => {
    props.changePassword(newPassword, confirmPassword)
  }

  useEffect(() => {
    // To disable submit button at the beginning.
    forceUpdate({})
    // eslint-disable-next-line
  }, [])

  return (
    <Spin size="large" spinning={props.isLoading}>
      <div style={{ background: '#F3F3F3' }}>
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <Row justify="center">
            <Col span={50}>
              <Card className="loginForm" style={{ width: '488.65px' }}>
                <Row
                  justify="center"
                  style={{
                    fontSize: '20px',
                    color: '#1589d6',
                    marginTop: '26px',
                    marginBottom: '25px',
                  }}
                >
                  パスワードのリセット
                </Row>
                <Row justify="center" style={{ margin: '20px 0', textAlign: 'center' }}>
                  <Text>新しいパスワードを入力してください</Text>
                </Row>
                <Form form={form} onFinish={onFinish} layout="vertical">
                  <Form.Item
                    label="新しいパスワード"
                    name="newPassword"
                    rules={[{ required: true, message: '入力必須項目です' }]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="新しいパスワード"
                      style={{ width: '400px' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setNewPassword(e)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="新しいパスワード（確認）"
                    name="confirmPassword"
                    rules={[{ required: true, message: '入力必須項目です' }]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="新しいパスワード（確認）"
                      style={{ width: '400px' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setConfirmPassword(e)}
                    />
                  </Form.Item>

                  <Form.Item shouldUpdate={true}>
                    {(): JSX.Element => (
                      <Row justify="center" style={{ marginTop: '15px' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          block
                          style={{ width: '300px' }}
                          disabled={
                            !form.isFieldsTouched(true) ||
                            newPassword !== confirmPassword ||
                            form.getFieldsError().filter(({ errors }) => errors.length).length
                              ? true
                              : false
                          }
                        >
                          適用
                        </Button>
                      </Row>
                    )}
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Row>
      </div>
    </Spin>
  )
}

export default ResetPasswordPage
