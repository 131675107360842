import { createSelector } from 'reselect'

import { RootState } from '../rootReducer'
import { Datastore } from './types'
import { DatastoresState } from './state'

export const datastoresState = (state: RootState): DatastoresState => state.datastores
const getDatastoreList = (state: RootState): Datastore[] => state.datastores.datastores_list || []

export const getDatastores = createSelector<RootState, Array<Datastore>, Array<Datastore>>(
  getDatastoreList,
  (datastores: Array<Datastore>) => datastores,
)

/**
 * @selector
 *
 * @description Return the id of current selected datastore
 *
 * @returns {string} the id of current selected datastore
 */
export const getCurrentDatastoreId = createSelector<RootState, DatastoresState, string>(
  datastoresState,
  (datastoresState: DatastoresState) => datastoresState.current_datastore_id,
)

export const getCurrentDatastore = createSelector<RootState, DatastoresState, string, Datastore | undefined>(
  datastoresState,
  getCurrentDatastoreId,
  (datastoresState: DatastoresState, currentId: string) =>
    datastoresState.datastores_list.find((ds) => ds.d_id === currentId),
)
