import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Row, Col, Table, Button, Spin } from 'antd'
import { Else } from 'app/services/store/quotationDetails/types'

interface SearchLowestPriceModalProps {
  visible: boolean
  dataSource: { [k: string]: string }[] | undefined
  elseResources: Else
  resetSearchData: Function
  addRow: Function
  onCancel: Function
}
const AddMerchandiseModal = ({
  visible,
  dataSource,
  elseResources,
  addRow,
  onCancel,
  resetSearchData,
}: SearchLowestPriceModalProps): JSX.Element => {
  const columns = [
    {
      title: 'メーカー名',
      dataIndex: 'makerName',
      key: 'makerName',
    },
    {
      title: '商品名',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: '規格・容量',
      dataIndex: 'standardCapacity',
      key: 'standardCapacity',
    },
    {
      title: '内容量',
      dataIndex: 'internalCapacity',
      key: 'internalCapacity',
    },
  ]

  const [selectedRow, setSelectedRow] = useState<{ [key: string]: any }[] | undefined>()

  const handleOnOk = (): void => {
    console.log("selectedRow:")
    console.log(selectedRow)
    if (selectedRow) {
      for(let addRow_cnt=0;selectedRow.length > addRow_cnt; addRow_cnt++){
        addRow(selectedRow[addRow_cnt])
      }
    }
    resetSearchData()
    onCancel()
  }
  const handleOnCancel = (): void => {
    resetSearchData()
    onCancel()
  }

  useEffect(() => {
    if (!visible) {
      setSelectedRow(undefined)
    }
  }, [visible])

  const canNotAdd = useMemo(() => {
    if (selectedRow) {
      for(let addRow_cnt=0;selectedRow.length > addRow_cnt; addRow_cnt++){
        const result = elseResources.items.some((item) => item.productCode === selectedRow[addRow_cnt].productCode)
        if(result){
          return true
        }
      }
    }
    return false
  }, [selectedRow, elseResources])

  return (
    <Modal
      title="商品検索結果"
      visible={visible}
      width="85vw"
      centered
      footer={[]}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Spin size="large" spinning={dataSource === undefined}>
        <div style={{ padding: '20px' }}>
          <Row>
            <Col span={24}>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  onChange: (_, selectedRow) => {
                    setSelectedRow(selectedRow)
                  },
                }}
                pagination={{ defaultPageSize: 100 }}
                columns={columns}
                dataSource={dataSource}
              />
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: '20px' }}>
            <Col offset={1} span={2}>
              <Button
                color="primary"
                type="primary"
                disabled={selectedRow === undefined || canNotAdd}
                onClick={(): void => handleOnOk()}
              >
                追加
              </Button>
            </Col>
            <Col span={2}>
              <Button color="danger" onClick={(): void => handleOnCancel()}>キャンセル</Button>
            </Col>

          </Row>
        </div>
      </Spin>
    </Modal>
  )
}

export default AddMerchandiseModal
