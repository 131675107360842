import { Datastore } from "./types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DatastoresState {
    datastores_list: Array<Datastore>;
    current_datastore_id: string;
    loading: boolean;
    error: string;
}

export const initialState: DatastoresState = {
    datastores_list : new Array<Datastore>(),
    current_datastore_id: '',
    loading: false,
    error: ''
};