import { User } from './types'

export interface UserState extends User {
  loading: boolean
  error: string
}

export const initialState: UserState = {
  auth_key: '',
  current_workspace_id: '',
  email: '',
  profile_pic: {
    mediaLink: '',
    selfLink: '',
  },
  u_id: '',
  username: '',
  is_ws_admin: false,
  tel: '',
  fax: '',
  deps: '',
  user_roles: [
    {
      r_id: '',
      role_name: '',
      p_id: '',
      application_id: '',
      application_name: '',
      application_display_order: 0,
    },
  ],
  loading: false,
  error: '',
}
