import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ItemsActions, QuotationDetailsSelectors } from 'app/services/store/'
import { createConditions } from 'app/utils/components/search'
import { DATASTORE_ID } from 'app/constants/hexabase'
import { ROUTE_PATH } from 'app/constants/router'
import SearchModal from 'app/components/organisms/search/SearchModal'
import { SEARCH_INFO } from 'app/constants/components/search'
import { rootSelector } from 'app/services/store/rootReducer'

interface QuotationSearchContainerProps {
  visible: boolean
  onCancel: Function
}

const QuotationSearchContainer = ({ visible, onCancel }: QuotationSearchContainerProps): JSX.Element => {
  const dispatch = useDispatch()
  const statusesData = QuotationDetailsSelectors.getStatusesData(useSelector(rootSelector))

  const onOk = useCallback(
    (searchData) => {
      dispatch(
        ItemsActions.itemsSearchRequest({
          datastoreId: DATASTORE_ID.QUOTATION,
          conditions: createConditions(searchData),
          resultPagePath: ROUTE_PATH.QUOTATION_SEARCH_RESULT,
        }),
      )
    },
    [dispatch],
  )

  return (
    <SearchModal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      searchInfo={SEARCH_INFO.QUOTATION_SEARCH(statusesData)}
    />
  )
}

export default QuotationSearchContainer
