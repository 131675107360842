import React, { useCallback } from 'react'

import { createConditions } from 'app/utils/components/search'
import { DATASTORE_ID, HEXA_ID } from 'app/constants/hexabase'
import SearchModal from 'app/components/organisms/search/SearchModal'
import { SEARCH_INFO } from 'app/constants/components/search'
import { itemList } from 'app/services/store/utils/linkerApis'

interface Props {
  visible: boolean
  onCancel: Function
  setSearchData: Function
  setAddMerchandiseModalVisibility: Function
}

const CustomerSearchContainer = ({
  visible,
  onCancel,
  setSearchData,
  setAddMerchandiseModalVisibility,
}: Props): JSX.Element => {
  const handleSearchMerchandise = useCallback(
    async (searchData) => {
      setAddMerchandiseModalVisibility(true)

      const searchDataValues: string[] = []
      searchData.forEach((item) => {
        if (item.value) {
          searchDataValues.push(item.value)
        }
      })

      if (searchDataValues.length > 0) {
        const applicationId = HEXA_ID.APPLICATION
        const datastoreId = DATASTORE_ID.PRODUCT_MASTER

        const conditions = createConditions(searchData)
        const responseOfSearchingMerchandise = await itemList({ applicationId, datastoreId, conditions }).toPromise()
        const result = responseOfSearchingMerchandise.data.items.map((item, index) => {
          return {
            key: index.toString(),
            tabType: 'その他',
            ...item,
          }
        })
        setSearchData(result)
      } else {
        setSearchData([])
      }
    },
    [setAddMerchandiseModalVisibility, setSearchData],
  )

  return (
    <SearchModal
      visible={visible}
      onCancel={onCancel}
      onOk={handleSearchMerchandise}
      searchInfo={SEARCH_INFO.MERCHANDISE_SEARCH}
    />
  )
}

export default CustomerSearchContainer
