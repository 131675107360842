import { DATASTORE_ID } from 'app/constants/hexabase'
import { QUOTATION_ID_LABEL } from 'app/constants/components/quotation'

export const getIdTextByDatastoreId = (datastoreId: string): string => {
  const result =
    datastoreId === DATASTORE_ID.QUOTATION
      ? QUOTATION_ID_LABEL.QUOTATION_ID_TEXT
      : datastoreId === DATASTORE_ID.CONSTRUCTION_METHOD
      ? QUOTATION_ID_LABEL.CONSTRUCTION_METHOD_ID_TEXT
      : datastoreId === DATASTORE_ID.FLAT
      ? QUOTATION_ID_LABEL.FLAT_ID_TEXT
      : datastoreId === DATASTORE_ID.RISE
      ? QUOTATION_ID_LABEL.RISE_ID_TEXT
      : QUOTATION_ID_LABEL.ELSE_ID_TEXT
  return result
}

export const getTargetStateByDatatoreId = (datastoreId: string): string => {
  const result =
    datastoreId === DATASTORE_ID.QUOTATION
      ? 'quotation'
      : datastoreId === DATASTORE_ID.CONSTRUCTION_METHOD
      ? 'constructionMethod'
      : datastoreId === DATASTORE_ID.FLAT
      ? 'flat'
      : datastoreId === DATASTORE_ID.RISE
      ? 'rise'
      : 'else'
  return result
}
