import { ItemsState, initialState } from './state'
import { PayloadAction } from '@reduxjs/toolkit'
import { Items, ItemEntry, SearchConditions, SearchItems } from './types'

export const setCurrentItemId = (state: ItemsState, action: PayloadAction<{ itemId: string }>): ItemsState => {
  return {
    ...state,
    current_item_id: action.payload.itemId,
  }
}

export const getItemsListRequest = (
  state: ItemsState,
  action: PayloadAction<{ projectId: string; datastoreId: string }>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getItemsListSuccess = (state: ItemsState, action: PayloadAction<Items>): ItemsState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: '',
  }
}

export const getItemsListFailed = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const deleteItemRequest = (
  state: ItemsState,
  action: PayloadAction<{
    itemId: string
    datastoreId: string
    deleteLinkedItems?: boolean
    targetDatastores?: string[]
  }>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deleteItemSuccess = (state: ItemsState, action: PayloadAction<{ itemId: string }>): ItemsState => {
  const filterdItems = state.entries.filter((item) => item.i_id !== action.payload.itemId)
  return { ...state, entries: filterdItems, loading: false }
}

export const deleteItemFailed = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
    loading: false,
  }
}

export const addEntry = (state: ItemsState, action: PayloadAction<{ entry: ItemEntry }>): ItemsState => {
  state.entries.unshift(action.payload.entry)
  return state
}

export const updateEntry = (
  state: ItemsState,
  action: PayloadAction<{ itemId: string; changes: Partial<ItemEntry>; newStatusId?: string }>,
): ItemsState => {
  const entry = state.entries.find((entry) => entry.i_id === action.payload.itemId)
  const entryIndex = state.entries.findIndex((entry) => entry.i_id === action.payload.itemId)

  if (entry) {
    const updatedEntry = {
      ...entry,
      ...action.payload.changes,
    }
    state.entries[entryIndex] = updatedEntry
    state.entries[entryIndex].status_id = action.payload.newStatusId
  }
  return state
}

export const reset = (state: ItemsState): ItemsState => {
  return {
    ...initialState,
  }
}

export const itemsSearchRequest = (
  state: ItemsState,
  action: PayloadAction<{ datastoreId: string; conditions: SearchConditions; resultPagePath: string }>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const itemsSearchSuccess = (state: ItemsState, action: PayloadAction<SearchItems>): ItemsState => {
  return {
    ...state,
    searchItems: action.payload,
    loading: false,
  }
}

export const itemsSearchFailed = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const itemsSearchReset = (state: ItemsState): ItemsState => {
  return {
    ...state,
    searchItems: {
      items: [],
      fields: {},
      totalItems: 0,
      searchConditions: [],
    },
  }
}

export const deleteItemFromSearchResultRequest = (
  state: ItemsState,
  action: PayloadAction<{
    itemId: string
    datastoreId: string
    deleteLinkedItems?: boolean
    targetDatastores?: string[]
  }>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deleteItemFromSearchResultSuccess = (
  state: ItemsState,
  action: PayloadAction<{ itemId: string }>,
): ItemsState => {
  const filterdItems = state.searchItems.items.filter((item) => item.i_id !== action.payload.itemId)
  const searchItems = {
    ...state.searchItems,
    items: filterdItems,
  }
  return {
    ...state,
    searchItems,
    loading: false,
  }
}

export const deleteItemFromSearchResultFailed = (
  state: ItemsState,
  action: PayloadAction<{ error: string }>,
): ItemsState => {
  return {
    ...state,
  }
}

export const executeBulkActionRequest = (
  state: ItemsState,
  action: PayloadAction<{ searchValue: string[]; datastoreId: string; actionId: string }>,
): ItemsState => {
  return {
    ...state,
    approveItemsLoading: true,
  }
}

export const executeBulkActionSuccess = (
  state: ItemsState,
  action: PayloadAction<{ searchValue: string[] }>,
): ItemsState => {
  return {
    ...state,
    approveItemsLoading: false,
    error: '',
  }
}

export const executeBulkActionFailed = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
    approveItemsLoading: false,
  }
}
