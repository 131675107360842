import { createSlice } from "@reduxjs/toolkit";
import * as reducers from './reducers';
import { initialState } from "./state";

/**
 * @namespace
 * @property {Function} setCurrentDatastoreId     - Set the current Datastore Id
 * @property {Function} getDatastoresRequest      - Request to get the list of datastores
 * @property {Function} getDatastoresSuccess      - Above on success (Do not call outside of epic)
 * @property {Function} getDatastoresFailed       - Above on failure (Do not call outside of epic)
 * @property {Function} reset                     - Reset the store to initial state
 */

export const datastoresSlice = createSlice({
  name: "datastores",
  initialState: initialState,
  reducers: {
    setCurrentDatastoreId: reducers.setCurrentDatastoreId,
    getDatastoresRequest: reducers.getDatastoresRequest,
    getDatastoresSuccess: reducers.getDatastoresSuccess,
    getDatastoresFailed: reducers.getDatastoresFailed,
    reset: reducers.reset
  }
});

export const {
  setCurrentDatastoreId,
  getDatastoresRequest,
  getDatastoresSuccess,
  getDatastoresFailed,
  reset
} = datastoresSlice.actions;