import React, { ReactNode } from 'react'
import { Layout } from 'antd'

import CommonHeader from 'app/components/organisms/shared/CommonHeader'

const { Header, Content } = Layout

interface AppContainerProps {
  children: ReactNode
}
const AppLayout = ({ children }: AppContainerProps): JSX.Element => {
  return (
    <div>
      <Layout>
        <Header style={{ background: '#fff', paddingLeft: '20px' }}>
          <CommonHeader />
        </Header>
        <Layout>
          <Content
            style={{
              background: '#fff',
              padding: 0,
              margin: 0,
              height: '94vh',
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default AppLayout
