import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { UsersSelectors, QuotationDetailsSelectors, QuotationDetailsActions } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'

import AppLayout from 'app/components/templates/AppLayout'
import QuotationDetailPage from 'app/components/pages/quotation/QuotationDetailPage'
import { quotatonStatusDeterminer } from 'app/utils/components/quotation/quotation'

const EditQuotationDetailContainer: React.FC = (): JSX.Element => {
  const location = useLocation()
  const dispatch = useDispatch()
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const constructionMethods = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))
  const quotationNumber = QuotationDetailsSelectors.getQuotationNumber(useSelector(rootSelector))
  const userName = UsersSelectors.getUsername(useSelector(rootSelector))
  const userEmail = UsersSelectors.getUserEmail(useSelector(rootSelector))
  const isAdmin = UsersSelectors.isAdmin(useSelector(rootSelector))
  const userId = UsersSelectors.getUserId(useSelector(rootSelector))
  const isDisabled = quotation.created_by !== userId

  const { iId } = location.state as { iId: string }
  useEffect(() => {
    dispatch(QuotationDetailsActions.genInitStateForEditContainer({ itemId: iId }))
  }, [dispatch, iId])

  return (
    <AppLayout>
      <QuotationDetailPage
        mode="update"
        isDisabled={isDisabled}
        quotationStatus={quotatonStatusDeterminer(quotation)}
        quotationNumber={quotationNumber}
        userName={quotation.personInChargeName || userName}
        userEmail={quotation.personInChargeEmail || userEmail}
        isCustomerInfoSaved={!!quotation['i_id']}
        constructionDataResources={constructionMethods}
      />
    </AppLayout>
  )
}

export default EditQuotationDetailContainer
