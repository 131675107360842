import { QuotationDetails, CustomerSearchResult } from './types';

export interface QuotationDetailsState extends QuotationDetails {
  loading: boolean
  constructionLoading: boolean
  customerSearchLoading: boolean
  error: string
}

export const initialState: QuotationDetailsState = {
  quotation: {},
  constructionMethod: [],
  constructionMethodFields: {},
  flat: [],
  rise: [],
  else: [],
  customerSearchResult: new Array<CustomerSearchResult>(),
  statusesData: { id: '', statues: [] },
  lowestPrices: {
    lowestPriceDataOfAll: {
      columns: [],
      price: [],
    },
    lowestPriceDataOfCustomer: {
      columns: [],
      price: [],
    },
  },
  lowestSaless: {
    lowestSalesDataOfAll: {
      columns: [],
      price: [],
    },
    lowestSalesDataOfCustomer: {
      columns: [],
      price: [],
    },
  },
  loading: false,
  constructionLoading: false,
  customerSearchLoading: false,
  error: '',
}
