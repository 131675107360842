import { createSelector } from 'reselect'

import { RootState } from '../rootReducer'
import { ItemsState } from './state'
import {
  ColumnSettings,
  DatastoreField,
  ItemEntry,
  ItemDisplayableEntries,
  DatastoreColumns,
  ItemDisplayableEntriesColumn,
  SearchItems,
  SearchResultFields,
} from './types'
import { UserState } from '../user/state'
import StringUtils from 'app/utils/stringUtils'
import FieldsHelper from 'app/utils/fieldsHelper'

const itemState = (state: RootState): ItemsState => state.items
const itemsEntries = (state: RootState): Array<ItemEntry> => state.items.entries
export const itemsFields = (state: RootState): Array<DatastoreField> => state.items.fields
const searchResultItems = (state: RootState): SearchItems['items'] => state.items.searchItems.items
const searchResultFields = (state: RootState): SearchResultFields => state.items.searchItems.fields
const userId = (state: RootState): UserState['u_id'] => state.user.u_id

/**
 * @selector
 *
 * @description Return the current items loading status
 *
 * @returns {boolean} true = items loading, false = items loaded
 */
export const getItemsIsLoading = createSelector<RootState, ItemsState, boolean>(
  itemState,
  (itemsState: ItemsState) => itemsState.loading,
)

/**
 * @selector
 *
 * @description Return the column of the item list
 *
 * @returns {DatastoreColumns} the column of the item list
 */
export const getItemsColumns = createSelector<RootState, ItemsState, DatastoreColumns>(
  itemState,
  (itemsState: ItemsState) => itemsState.columns,
)

/**
 * @selector
 *
 * @description Return the current itemId selected in the list
 *
 * @returns {string} the current itemId selected in the list
 */
export const getCurrentItemId = createSelector<RootState, ItemsState, string>(
  itemState,
  (itemsState: ItemsState) => itemsState.current_item_id,
)

/**
 * @selector
 *
 * @description Return the settings of the items list column
 *
 * @returns {ColumnSettings} the settings of the items list column
 */
export const getItemsColumnsSettings = createSelector<RootState, ItemsState, Map<string, ColumnSettings>>(
  itemState,
  (itemsState: ItemsState) =>
    itemsState.columns && itemsState.columns.column_settings
      ? new Map(Object.entries(itemsState.columns.column_settings))
      : new Map<string, ColumnSettings>(),
)

/**
 * @selector
 *
 * @description This selector will create a set of displayable item that are easy to manipulate
 * using the current entries and fields set in the item list store. This way all the information is in one place
 * and no need to manipulate to many different field to get what we want. It should provide you with enough data to display anything
 *
 * @returns {ItemDisplayableEntries} the created displayable entries from this list
 */
export const getDisplayableEntries = createSelector<
  RootState,
  Array<ItemEntry>,
  Array<DatastoreField>,
  ItemDisplayableEntries
>(itemsEntries, itemsFields, (entries: Array<ItemEntry>, fields: Array<DatastoreField>) => {
  const dispColumns: Array<ItemDisplayableEntriesColumn> = fields.map((field: DatastoreField) => {
    return {
      title: field.displayName,
      dataIndex: field.displayID,
      key: field.displayID,
      id: field.field,
      type: field.dataType,
    }
  })
  const dispItems = entries.map((item: ItemEntry) => {
    const targetObj: { [k: string]: any } = {}

    dispColumns.forEach((column: ItemDisplayableEntriesColumn) => {
      if (column.type === FieldsHelper.fields.date.value) {
        return (targetObj[column.key] = FieldsHelper.formatDate(item.fields[column.id]))
      }
      if (column.type === FieldsHelper.fields.time.value) {
        return (targetObj[column.key] = FieldsHelper.formatTime(item.fields[column.id]))
      }
      return (targetObj[column.key] = item.fields[column.id])
    })

    targetObj['key'] = `${StringUtils.GenUUID()}-${item.i_id}`
    targetObj['i_id'] = item.i_id

    return targetObj
  })
  return { columns: dispColumns, items: dispItems }
})

export const getDisplayableOwnEntries = createSelector<
  RootState,
  Array<ItemEntry>,
  Array<DatastoreField>,
  UserState['u_id'],
  ItemDisplayableEntries
>(
  itemsEntries,
  itemsFields,
  userId,
  (entries: Array<ItemEntry>, fields: Array<DatastoreField>, userId: UserState['u_id']) => {
    const dispColumns: Array<ItemDisplayableEntriesColumn> = fields.map((field: DatastoreField) => {
      return {
        title: field.displayName,
        dataIndex: field.displayID,
        key: field.displayID,
        id: field.field,
        type: field.dataType,
      }
    })
    const dispItems = entries.map((item: ItemEntry) => {
      const targetObj: { [k: string]: any } = {}

      dispColumns.forEach((column: ItemDisplayableEntriesColumn) => {
        if (column.type === FieldsHelper.fields.date.value) {
          return (targetObj[column.key] = FieldsHelper.formatDate(item.fields[column.id]))
        }
        if (column.type === FieldsHelper.fields.time.value) {
          return (targetObj[column.key] = FieldsHelper.formatTime(item.fields[column.id]))
        }
        return (targetObj[column.key] = item.fields[column.id])
      })

      targetObj['key'] = `${StringUtils.GenUUID()}-${item.i_id}`
      targetObj['i_id'] = item.i_id

      return targetObj
    })
    const ownItems = dispItems.filter((item) => item['userId'] === userId)
    return { columns: dispColumns, items: ownItems }
  },
)

export const getSearchResultDisplayableEntries = createSelector(
  searchResultFields,
  searchResultItems,
  (fields: SearchResultFields, items: SearchItems['items']) => {
    const dispColumns: Array<ItemDisplayableEntriesColumn> = Object.entries(fields).map((field) => {
      return {
        title: field[1].name,
        dataIndex: field[1].display_id,
        key: field[1].display_id,
        id: field[1].field_id,
        type: field[1].dataType,
      }
    })

    const dispItems = items.map((item) => {
      const targetObj: { [k: string]: any } = {}

      dispColumns.forEach((column: ItemDisplayableEntriesColumn) => {
        if (column.type === FieldsHelper.fields.date.value && item[column.key]) {
          return (targetObj[column.key] = FieldsHelper.formatDate(item[column.key]))
        }
        if (column.type === FieldsHelper.fields.time.value && item[column.key]) {
          return (targetObj[column.key] = FieldsHelper.formatTime(item[column.key]))
        }
        return (targetObj[column.key] = item[column.key])
      })
      return {
        ...item,
        ...targetObj,
        key: `${StringUtils.GenUUID()}-${item.i_id}`,
      }
    })

    return { columns: dispColumns, items: dispItems }
  },
)

export const getApproveItemsIsLoading = createSelector<RootState, ItemsState, boolean>(
  itemState,
  (itemsState: ItemsState) => itemsState.approveItemsLoading,
)

export default [
  getItemsIsLoading,
  getItemsColumns,
  getItemsColumnsSettings,
  getDisplayableEntries,
  getDisplayableOwnEntries,
  getApproveItemsIsLoading,
]
