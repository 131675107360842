import React, { useState, useCallback, useEffect, useMemo, memo } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Row, Col, Input, Button, Modal, Typography } from 'antd'
import { CheckCircleFilled, CloudDownloadOutlined, CheckOutlined } from '@ant-design/icons'

import { QUOTATION_FORM_NAME, ACTION_ID } from 'app/constants/components/quotation'
import { QuotationDetailsSelectors, QuotationDetailsActions } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'
import { DATASTORE_ID } from 'app/constants/hexabase'
import { quotatonStatusDeterminer } from 'app/utils/components/quotation/quotation'
import { ROUTE_PATH } from 'app/constants/router'

const { TextArea } = Input
const { Text } = Typography

interface ConstructionRemarksProps {
  isDisabled: boolean
}
const ConstructionRemarks = ({ isDisabled }: ConstructionRemarksProps): JSX.Element => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isDelModalOpen, handleDelModalOpen] = useState(false)
  const [isSendModalOpen, handleSendModalOpen] = useState(false)
  const [isCopyModalOpen, handleCopyModalOpen] = useState(false)
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const constructionMethod = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))

  const canCopy = useMemo(() => {
    if (constructionMethod.some((method) => !!method.items.i_id) && quotation.i_id) {
      return true
    }
    return false
  }, [constructionMethod, quotation.i_id])
  const onFinish = (values): void => {
    if (quotation.i_id) {
      const remarkstext = form.getFieldValue(QUOTATION_FORM_NAME.REMARKS)

      dispatch(
        QuotationDetailsActions.updateQuotationAndConstruction({
          datastoreId: DATASTORE_ID.QUOTATION,
          itemId: quotation.i_id,
          item: { [QUOTATION_FORM_NAME.REMARKS]: remarkstext },
        }),
      )
    } else {
      // TODO: 仕様上の例外、アラートメッセージを出力する
    }
  }

  const deleteQuotation = useCallback(() => {
    if (quotation.i_id) {
      dispatch(QuotationDetailsActions.deleteQuotation({ itemId: quotation.i_id }))
      handleDelModalOpen(false)
    } else {
      // TODO: 仕様上の例外、アラートメッセージを出力する
    }
  }, [dispatch, quotation.i_id])

  const copyQuotation = useCallback(() => {
    // TODO: コピーアクションの実行
    handleDelModalOpen(false)
    history.push(ROUTE_PATH.QUOTATION_COPY)
    dispatch(QuotationDetailsActions.copyQuotation())

    // TODO: 仕様上の例外、アラートメッセージを出力する
  }, [history, dispatch])
  /*
  const downloadPdf = useCallback(() => {
    if (quotation.i_id) {
      dispatch(QuotationDetailsActions.downloadPdf({ itemId: quotation.i_id }))
    } else {
      // TODO: 仕様上の例外、アラートメッセージを出力する
    }
  }, [dispatch, quotation.i_id])
*/
  const downloadCsvRequest = useCallback(() => {
    if (quotation.i_id) {
      dispatch(QuotationDetailsActions.downloadCsvRequest({ itemId: quotation.i_id }))
    } else {
      // TODO: 仕様上の例外、アラートメッセージを出力する
    }
  }, [dispatch, quotation.i_id])

  const applyQuotation = useCallback(() => {
    if (quotation.i_id) {
      dispatch(
        QuotationDetailsActions.executeAction({
          datastoreId: DATASTORE_ID.QUOTATION,
          itemId: quotation.i_id,
          actionId: ACTION_ID.NEXT_STATUS,
        }),
      )
    } else {
      // TODO: 仕様上の例外、アラートメッセージを出力する
    }
  }, [dispatch, quotation.i_id])

  const sendEmail = useCallback(() => {
    if (quotation.i_id) {
      handleSendModalOpen(false)
      dispatch(
        QuotationDetailsActions.executeAction({
          datastoreId: DATASTORE_ID.QUOTATION,
          itemId: quotation.i_id,
          actionId: ACTION_ID.SEND_EMAIL,
        }),
      )
    } else {
      // TODO: 仕様上の例外、アラートメッセージを出力する
    }
  }, [dispatch, quotation.i_id])

  const openDelModal = useCallback(() => {
    handleDelModalOpen(true)
  }, [])

  const closeDelModal = useCallback(() => {
    handleDelModalOpen(false)
  }, [])

  const openSendModal = useCallback(() => {
    handleSendModalOpen(true)
  }, [])

  const closeSendModal = useCallback(() => {
    handleSendModalOpen(false)
  }, [])

  const openCopyModal = useCallback(() => {
    handleCopyModalOpen(true)
  }, [])

  const closeCopyModal = useCallback(() => {
    handleCopyModalOpen(false)
  }, [])

  // initialValuesがセットされない問題の対処
  // @see: https://github.com/ant-design/ant-design/issues/22372#issuecomment-602102164
  useEffect(() => form.resetFields(), [form, quotation])

  // TODO: 各ボタンを正しいコンポーネントの配置に分離する
  return (
    <div>
      <Form onFinish={onFinish} form={form} initialValues={quotation}>
        <Row justify="start" style={{ marginTop: '30px', marginBottom: '10px' }}>
          <h3>備考欄</h3>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item colon={false} name={QUOTATION_FORM_NAME.REMARKS}>
              <TextArea disabled={isDisabled} rows={4} onBlur={onFinish} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Button
              icon={<CheckCircleFilled />}
              type="primary"
              style={{ marginRight: '30px' }}
              onClick={applyQuotation}
              disabled={isDisabled || !quotation.i_id || quotatonStatusDeterminer(quotation) !== 0} // 見積書が保存されてない、またはステータスがs作成済みではない場合は非活性
            >
              申請
            </Button>
            <Button
              icon={<CloudDownloadOutlined />}
              type="primary"
              onClick={downloadCsvRequest}
              disabled={!quotation.i_id || !constructionMethod.some((constMethod) => !!constMethod.items.i_id)}
            >
              CSV出力
            </Button>
          </Col>
          <Col>
            <Button type="primary" disabled={!canCopy} onClick={openCopyModal} style={{ marginRight: '30px' }}>
              複製
            </Button>
            <Button
              style={{ color: 'white', backgroundColor: 'black' }}
              onClick={openDelModal}
              disabled={isDisabled || !quotation.i_id}
            >
              削除
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal visible={isDelModalOpen} onOk={deleteQuotation} onCancel={closeDelModal} centered>
        <p>見積書を削除します。よろしいですか。</p>
      </Modal>

      <Modal visible={isSendModalOpen} onOk={sendEmail} onCancel={closeSendModal} centered>
        <p>見積書を送信します。よろしいですか。</p>
      </Modal>

      <Modal visible={isCopyModalOpen} onOk={copyQuotation} onCancel={closeCopyModal} centered>
        <Text strong>見積書を複製します</Text>
        <br />
        <br />
        <Text> 一度も開いてない工法タブに紐づく</Text>
        <br />
        <Text>平場・立上り・その他のデータは複製されません。</Text>
        <br />
        <Text>複製を実行してよろしいですか。</Text>
      </Modal>
    </div>
  )
}

export default memo(ConstructionRemarks)
