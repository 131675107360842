import { api_get_all_workspace_response } from 'app/services/store/system/epic.types'
import { Project } from 'app/services/store/system/types'
import { Datastore } from 'app/services/store/datastore/types'

// TODO: change the code to clean and simple.
export const getWorkspaceIdFromName = (response: api_get_all_workspace_response, workspaceName: string): string => {
  const currentWorkspace = response.workspaces.find((workspace) => workspace.name === workspaceName)
  if (currentWorkspace) {
    return currentWorkspace.w_id
  } else {
    return ''
  }
}

export const getProjectIdFromName = (projects: Array<Project>, projectName: string): string => {
  const currentProject = projects.find((project) => project.name === projectName)
  if (currentProject) {
    return currentProject.application_id
  } else {
    return ''
  }
}

export const getDatastoreIdFromName = (datastores: Array<Datastore>, datastoreName: string): string => {
  const currentDatastore = datastores.find((datastore) => datastore.name === datastoreName)
  if (currentDatastore) {
    return currentDatastore.d_id
  } else {
    return ''
  }
}

export const getDatastoreNameFromId = (datastores: Array<Datastore>, datastoreId: string): string => {
  const currentDatastore = datastores.find((datastore) => datastore.d_id === datastoreId)
  if (currentDatastore) {
    return currentDatastore.name
  } else {
    return ''
  }
}
