import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'

import { RootState } from 'app/services/store/rootReducer'
import { SystemState } from 'app/services/store/system/state'
import { Workspace, Project } from 'app/services/store/system/types'
import { Datastore } from 'app/services/store/datastore/types'

const systemState = (state: RootState): SystemState => state.system
const projectList = (state: RootState): Array<Project> => state.system.project_list || []
const projectCurrentId = (state: RootState): string => state.system.current_project_id

/**
 * @selector
 *
 * @description Return the current workspace selected (based on current_workspace_id)
 *
 * @returns {Workspace | undefined} the workspace selected (undefined is nothing is selected)
 */
export const getCurrentWorkspace = createSelector<RootState, SystemState, Workspace | undefined>(
  systemState,
  (state: SystemState) => {
    if (state.current_workspace_id) {
      return state.workspaces_list.find((ws) => ws.w_id === state.current_workspace_id)
    } else {
      return undefined
    }
  },
)

/**
 * @selector
 *
 * @description Return the current loading status of the workspace store
 *
 * @returns {boolean} true = loading , false = not loading
 */
export const getWorkspaceIsLoading = createSelector<RootState, SystemState, boolean>(
  systemState,
  (state: SystemState) => {
    return state.loading
  },
)

/**
 * @selector
 *
 * @description Return the current workspace id selected in the store
 *
 * @returns {string} the workspace id in the store
 */
export const getCurrentWorkspaceId = createSelector<RootState, SystemState, string>(
  systemState,
  (state: SystemState) => {
    return state.current_workspace_id
  },
)

/**
 * @selector
 *
 * @description Return the workspace list in store
 *
 * @returns {Array<Workspace>} the list of workspace
 */
export const getWorkspaceList = createSelector<RootState, SystemState, Array<Workspace>>(
  systemState,
  (state: SystemState) => {
    return state.workspaces_list || []
  },
)

/**
 * @selector
 *
 * @description Return the project list from the store
 *
 * @returns {Array<Project>} the list of project in the store
 */
export const getProjectList = createSelector<RootState, Array<Project>, Array<Project>>(
  projectList,
  (projectList: Array<Project>) => projectList,
)

/**
 * @selector
 *
 * @description Return the current loading status of the project store
 *
 * @returns {boolean} true = project loading, false = project not loading
 */
export const getProjectIsLoading = createSelector<RootState, SystemState, boolean>(
  systemState,
  (projectId: SystemState) => projectId.loading,
)

/**
 * @selector
 *
 * @description Return the current selected project id
 *
 * @returns {string} the current selected project id
 */
export const getCurrentProjectId = createSelector<RootState, string, string>(
  projectCurrentId,
  (projectId: string) => projectId,
)

/**
 * @selector memoized
 * @param {string} projectId the id of the project we want to select
 *
 * @description Return the project from a provided id
 *
 * @returns {Project} the project found using the id provided
 */
export const getProjectById = createSelector<RootState, Array<Project>, (pj_id: string) => Project>(
  projectList,
  (projectList: Array<Project>) => memoize<Project>((pj_id) => projectList.find((pj) => pj.application_id === pj_id)),
)

/**
 * @selector
 *
 * @description Return the current selected project  (based on current_project_id)
 *
 * @returns {Project | undefined} the project that is currently selected (undefined if nothing is selected)
 */
export const getCurrentProject = createSelector<RootState, Array<Project>, string, Project | undefined>(
  projectList,
  projectCurrentId,
  (projects: Array<Project>, projectId: string) => projects.find((pj) => pj.application_id === projectId),
)

/**
 * @selector
 *
 * @description Return the datastores that are contained in the project
 *
 * @returns {Array<Datastore>} the list of datastore in the project
 */
export const getCurrentProjectDatastores = createSelector<RootState, Project | undefined, Array<Datastore>>(
  getCurrentProject,
  (project: Project | undefined) => {
    return project ? project.datastores : []
  },
)
