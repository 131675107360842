import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { rootSelector } from 'app/services/store/rootReducer'
import { UsersSelectors, QuotationDetailsSelectors, QuotationDetailsActions } from 'app/services/store'

import AppLayout from 'app/components/templates/AppLayout'
import QuotationDetailPage from 'app/components/pages/quotation/QuotationDetailPage'
import { quotatonStatusDeterminer } from 'app/utils/components/quotation/quotation'

const CreateQuotationDetailContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const quotationNumber = QuotationDetailsSelectors.getQuotationNumber(useSelector(rootSelector))
  const userName = UsersSelectors.getUsername(useSelector(rootSelector))
  const userEmail = UsersSelectors.getUserEmail(useSelector(rootSelector))

  useEffect(() => {
    dispatch(QuotationDetailsActions.genInitStateForCreateContainer())
  }, [dispatch])

  return (
    <AppLayout>
      <QuotationDetailPage
        mode="create"
        isDisabled={false}
        quotationStatus={quotatonStatusDeterminer(quotation)}
        quotationNumber={quotationNumber || ''}
        userName={quotation.personInChargeName || userName}
        userEmail={quotation.personInChargeEmail || userEmail}
        isCustomerInfoSaved={!!quotation.i_id}
      />
    </AppLayout>
  )
}

export default CreateQuotationDetailContainer
