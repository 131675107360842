import React, { useState } from 'react'
import { Spin, Row, Col, Table, Button, Tabs } from 'antd'

import { QuotationListItems, QuotationListColumn } from 'app/types/components/quotation'

import QuotationListTable from 'app/components/organisms/quotation/quotationDetail/quotationTable/QuotationListTable'
import CreateAndSearchButtons from 'app/components/organisms/quotation/CreateAndSearchButtons'
import QuotationSearchContainer from 'app/containers/search/QuotationSearchContainer'

const { TabPane } = Tabs

interface QuotationListPageProps {
  isQuotationItemsLoading: boolean
  isApproveItemsLoading: boolean
  isAdmin: boolean
  columns: QuotationListColumn[]
  quotationItems: QuotationListItems[]
  unapprovedItems?: QuotationListItems[]
  approveItems: Function
}
const QuotationListPage = ({
  isQuotationItemsLoading,
  isApproveItemsLoading,
  isAdmin,
  columns,
  quotationItems,
  unapprovedItems,
  approveItems,
}: QuotationListPageProps): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<QuotationListItems[]>([])
  const [searchModalVisibility, setSearchModalVisibility] = useState<boolean>(false)

  const handleApproveItems = (): void => {
    if (selectedRows && selectedRows.length > 0) {
      const targetIIds = selectedRows.map((item) => item.i_id)
      approveItems(targetIIds)
    }
  }

  return (
    <div style={{ padding: '20px 40px' }}>
      <Row justify="space-between" style={{ marginBottom: '50px' }}>
        <Col span={20}>
          <h2>見積一覧</h2>
        </Col>
        <Col span={4}>
          <CreateAndSearchButtons setSearchModalVisibility={setSearchModalVisibility} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {isAdmin ? (
            <Tabs type="card">
              <TabPane tab="見積" key="1" style={{ marginTop: '-17px' }}>
                <Spin size="large" spinning={isQuotationItemsLoading}>
                  <Row>
                    <Col span={24}>
                      <QuotationListTable columns={columns} dataSource={quotationItems} />
                    </Col>
                  </Row>
                </Spin>
              </TabPane>
              <TabPane tab="申請" key="2">
                <Spin size="large" spinning={isApproveItemsLoading}>
                  <Row>
                    <Col span={24}>
                      <Table
                        rowSelection={{
                          onSelect: (record, selected, selectedRows) => {
                            setSelectedRows(selectedRows as QuotationListItems[])
                          },
                          onSelectAll: (selected, selectedRows) => {
                            setSelectedRows(selectedRows as QuotationListItems[])
                          },
                        }}
                        columns={columns}
                        dataSource={unapprovedItems}
                      />
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={2}>
                      <Button
                        onClick={handleApproveItems}
                        type="primary"
                        size="middle"
                        disabled={!(selectedRows.length > 0)}
                        style={{ width: '100px' }}
                      >
                        承認
                      </Button>
                    </Col>
                  </Row>
                </Spin>
              </TabPane>
            </Tabs>
          ) : (
            <Spin size="large" spinning={isQuotationItemsLoading}>
              <QuotationListTable columns={columns} dataSource={quotationItems} />
            </Spin>
          )}
        </Col>
      </Row>
      <QuotationSearchContainer
        visible={searchModalVisibility}
        onCancel={(): void => setSearchModalVisibility(false)}
      />
    </div>
  )
}

export default QuotationListPage
