import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

export const quotationDetailsSlice = createSlice({
  name: 'quotationDetails',
  initialState: initialState,
  reducers: {
    addConstructionMethod: reducers.addConstructionMethod,
    deleteConstructionMethod: reducers.deleteConstructionMethod,
    updateQuotationAndConstruction: reducers.updateQuotationAndConstruction,
    updateQuotationAndConstructionSuccess: reducers.updateQuotationAndConstructionSuccess,
    updateQuotationAndConstructionFailed: reducers.updateQuotationAndConstructionFailed,
    createQuotation: reducers.createQuotation,
    createQuotationSuccess: reducers.createQuotationSuccess,
    createQuotationFailed: reducers.createFailed,
    createRequest: reducers.createRequest,
    createSuccess: reducers.createSuccess,
    createFailed: reducers.createFailed,
    deleteConstructionMethodFromDB: reducers.deleteConstructionMethodFromDB,
    deleteConstructionMethodFromDBSuccess: reducers.deleteConstructionMethodFromDBSuccess,
    deleteConstructionMethodFromDBFailed: reducers.deleteConstructionMethodFromDBFailed,
    deleteQuotation: reducers.deleteQuotation,
    deleteQuotationSuccess: reducers.deleteQuotationSuccess,
    deleteQuotationFailed: reducers.deleteConstructionMethodFromDBFailed,
    getFlatRiseMasterData: reducers.getFlatRiseMasterData,
    getFlatRiseMasterDataSuccess: reducers.getFlatRiseMasterDataSuccess,
    getFlatRiseMasterDataFailed: reducers.getFlatRiseMasterDataFailed,
    customerSearch: reducers.customerSearch,
    customerSearchSuccess: reducers.customerSearchSuccess,
    customerSearchFailed: reducers.customerSearchFailed,
    genQuotationNumber: reducers.genQuitationId,
    genQuotationNumberSuccess: reducers.genQuitationIdSuccess,
    genQuitationNumberFailed: reducers.updateQuotationAndConstructionFailed,
    calculateConstMethodSummaries: reducers.calculateConstMethodSummaries,
    editFlatRiseElse: reducers.editFlatRiseElse,
    editEntireFlatRiseElse: reducers.editEntireFlatRiseElse,
    getLowestPriceRequest: reducers.getLowestPriceRequest,
    getLowestPriceSuccess: reducers.getLowestPriceSuccess,
    getLowestPriceFailed: reducers.getLowestPriceFailed,
    getLowestSalesRequest: reducers.getLowestSalesRequest,
    getLowestSalesSuccess: reducers.getLowestSalesSuccess,
    getLowestSalesFailed: reducers.getLowestSalesFailed,
    addElseRow: reducers.addElseRow,
    deleteElseRow: reducers.deleteElseRow,
    deleteElseRowSuccess: reducers.deleteElseRowSuccess,
    deleteElseRowFailed: reducers.createFailed,
    saveFlatRiseElse: reducers.saveFlatRiseElse,
    saveFlatRiseElseSuccess: reducers.saveFlatRiseElseSuccess,
    saveFlatRiseElseFailed: reducers.saveFlatRiseElseFailed,
    saveOneItem: reducers.saveOneItem,
    saveOneItemSuccess: reducers.saveOneItemSuccess,
    saveOneItemFailed: reducers.saveOneItemFailed,
    saveAddItem: reducers.saveAddItem,
    saveAddItemSuccess: reducers.saveAddItemSuccess,
    saveAddItemFailed: reducers.saveAddItemFailed,
    savelowestpriceFlatRiseElse: reducers.savelowestpriceFlatRiseElse,
    savelowestpriceFlatRiseElseSuccess: reducers.savelowestpriceFlatRiseElseSuccess,
    savelowestpriceFlatRiseElseFailed: reducers.savelowestpriceFlatRiseElseFailed,
    executeAction: reducers.executeAction,
    executeActionSuccess: reducers.executeActionSuccess,
    executeActionFailed: reducers.executeActionFailed,
    getConstructionMethodFields: reducers.getConstructionMethodFields,
    getConstructionMethodFieldsSuccess: reducers.getConstructionMethodFieldsSuccess,
    getConstructionMethodFieldsFailed: reducers.updateQuotationAndConstructionFailed,
    genInitStateForCreateContainer: reducers.genInitStateForCreateContainer,
    genInitStateForEditContainer: reducers.genInitStateForEditContainer,
    genInitStateForEditContainerSuccess: reducers.genInitStateForEditContainerSuccess,
    genInitStateForEditContainerFailed: reducers.updateQuotationAndConstructionFailed,
    getFlatRiseElse: reducers.getFlatRiseElse,
    getFlatRiseElseSuccess: reducers.getFlatRiseElseSuccess,
    getFlatRiseElseFailed: reducers.getFlatRiseElseFailed,
    copyQuotation: reducers.copyQuotation,
    reset: reducers.reset,
    resetLowestPrice: reducers.resetLowestPrice,
    resetLowestSales: reducers.resetLowestSales,
    downloadPdf: reducers.downloadPdf,
    downloadPdfSuccess: reducers.downloadPdfSuccess,
    downloadPdfFailed: reducers.downloadPdfFailed,
    downloadCsvRequest: reducers.downloadCsvRequest,
    downloadCsvSuccess: reducers.downloadCsvSuccess,
    downloadCsvFailed: reducers.downloadCsvFailed,
    setStatusesData: reducers.setStatusesData,
  },
})

export const {
  addConstructionMethod,
  deleteConstructionMethod,
  updateQuotationAndConstruction,
  updateQuotationAndConstructionSuccess,
  updateQuotationAndConstructionFailed,
  createRequest,
  createSuccess,
  createFailed,
  createQuotation,
  createQuotationSuccess,
  createQuotationFailed,
  deleteConstructionMethodFromDB,
  deleteConstructionMethodFromDBSuccess,
  deleteConstructionMethodFromDBFailed,
  deleteQuotation,
  deleteQuotationSuccess,
  deleteQuotationFailed,
  getFlatRiseMasterData,
  getFlatRiseMasterDataSuccess,
  getFlatRiseMasterDataFailed,
  customerSearch,
  customerSearchSuccess,
  customerSearchFailed,
  genQuotationNumber,
  genQuotationNumberSuccess,
  genQuitationNumberFailed,
  calculateConstMethodSummaries,
  editFlatRiseElse,
  editEntireFlatRiseElse,
  getLowestPriceRequest,
  getLowestPriceSuccess,
  getLowestPriceFailed,
  getLowestSalesRequest,
  getLowestSalesSuccess,
  getLowestSalesFailed,
  addElseRow,
  deleteElseRow,
  deleteElseRowSuccess,
  deleteElseRowFailed,
  saveFlatRiseElse,
  saveFlatRiseElseSuccess,
  saveFlatRiseElseFailed,
  saveOneItem,
  saveOneItemSuccess,
  saveOneItemFailed,
  saveAddItem,
  saveAddItemSuccess,
  saveAddItemFailed,
  savelowestpriceFlatRiseElse,
  savelowestpriceFlatRiseElseSuccess,
  savelowestpriceFlatRiseElseFailed,
  executeAction,
  executeActionSuccess,
  executeActionFailed,
  getConstructionMethodFields,
  getConstructionMethodFieldsSuccess,
  getConstructionMethodFieldsFailed,
  genInitStateForCreateContainer,
  genInitStateForEditContainer,
  genInitStateForEditContainerSuccess,
  genInitStateForEditContainerFailed,
  getFlatRiseElse,
  getFlatRiseElseSuccess,
  getFlatRiseElseFailed,
  copyQuotation,
  reset,
  resetLowestPrice,
  resetLowestSales,
  downloadPdf,
  downloadPdfSuccess,
  downloadPdfFailed,
  downloadCsvRequest,
  downloadCsvSuccess,
  downloadCsvFailed,
  setStatusesData,
} = quotationDetailsSlice.actions
