import { ofType } from 'redux-observable'
import { concatMap } from 'rxjs/operators'
import { DatastoreActions, ItemsActions, SystemSelectors } from '..'
import { Observable, Action } from 'redux'
import { PayloadAction } from '@reduxjs/toolkit'
import { EMPTY, of } from 'rxjs'

/**
 * @action LINEAR
 * @in [Datastore] setCurrentDatastoreId
 *
 * @description Add the project datastore to the store
 * and request to load the items. Or unselect if
 * empty is provided
 *
 * @outSuccess [Items] getItemsListRequest
 * @outFailed  [EMPTY] EMPTY
 */
const setCurrentDatastoreIdEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(DatastoreActions.setCurrentDatastoreId),
    concatMap((action: PayloadAction<{ datastoreId: string }>) => {
      const projectId = SystemSelectors.getCurrentProjectId(state$.value)
      if (action.payload.datastoreId) {
        return of(
          ItemsActions.getItemsListRequest({
            projectId,
            datastoreId: action.payload.datastoreId,
          }),
        )
      } else {
        return EMPTY
      }
    }),
  )

export default [setCurrentDatastoreIdEpic]
