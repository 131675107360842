import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { UserState } from './state'
import { UserProfilePic, User } from './types'
import { DATASTORE_ID, HEXA_ID } from 'app/constants/hexabase'
import { itemList } from 'app/services/store/utils/linkerApis'

const userState = (state: RootState): UserState => state.user

/**
 * @selector
 *
 * @description Return the token whith which the user logged
 *
 * @returns {string} the token whith which the user logged
 */
export const getUserToken = createSelector<RootState, UserState, string>(
  userState,
  (state: UserState) => state.auth_key,
)

/**
 * @selector
 *
 * @description Return the id of the user that is currently logged
 *
 * @returns {string} the id of the user currently logged
 */
export const getUserId = createSelector<RootState, UserState, string>(userState, (state: UserState) => state.u_id)
export const getUserEmail = createSelector<RootState, UserState, string>(userState, (state: UserState) => state.email)


/**
 * @selector
 *
 * @description Return the username of the current user
 *
 * @returns {string} the username of the user currently logged
 */
export const getUsername = createSelector<RootState, UserState, string>(userState, (state: UserState) => state.username)

/**
 * @selector
 *
 * @description Return the profile pic object of an user
 *
 * @returns {string} the username of the user currently logged
 */
export const getUserAvatar = createSelector<RootState, UserState, UserProfilePic>(
  userState,
  (state: UserState) => state.profile_pic,
)

/**
 * @selector
 *
 * @description Return the current user loading status
 *
 * @returns {boolean} true = user loading, false = user not loading
 */
export const getUserLoading = createSelector<RootState, UserState, boolean>(
  userState,
  (state: UserState) => state.loading,
)

/**
 * @selector
 *
 * @description Return the current user loggin status
 *
 * @returns {boolean} true = user logged, false = user not logged
 */
export const isUserLogged = createSelector<RootState, UserState, boolean>(
  userState,
  (state: UserState) => state.auth_key !== '',
)

/**
 * @selector
 *
 * @description Return the current error on the user store
 *
 * @returns {string} the error if any
 */
export const getUserError = createSelector<RootState, UserState, string>(userState, (state: UserState) => state.error)

export const isLoading = createSelector<RootState, UserState, boolean>(userState, (state: UserState) => state.loading)

export const getUserRoles = createSelector<RootState, UserState, User['user_roles']>(
  userState,
  (state: UserState) => state.user_roles,
)

export const isAdmin = createSelector<RootState, UserState, boolean>(userState, (state: UserState) =>
  state.user_roles.some((role) => role.role_name === 'approver'),
)


export const getUserData = async (searchEmail: string) => {
  const conditions = [
    {
      id: 'employeeMail',
      search_value: [searchEmail],
      exact_match: true,
      include_null: false,
    },
  ]
  const applicationId = HEXA_ID.APPLICATION
  const datastoreId = DATASTORE_ID.EMPLOYEE
  const user_data = await itemList({ applicationId, datastoreId, conditions }).toPromise().then((result) => result.data.items[0])
  return user_data
}

