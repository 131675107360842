//見積一覧修正用の準備

import React from 'react'
import { Table } from 'antd'

import './overrideStyle.css'
import { QuotationListItems } from 'app/types/components/quotation'

interface QuotationListTableProps {
  columns: {
    title: string
    dataIndex: string
    key: string
  }[]
  dataSource: QuotationListItems[]
}
const QuotationListTable = ({ columns, dataSource }: QuotationListTableProps): JSX.Element => {
  return <Table columns={columns} dataSource={dataSource} />
}

export default QuotationListTable
