import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

/**
 * @property {Function} setCurrentWorkspaceIdRequest  - Request to set the current user workspace id and save it in the database
 * @property {Function} setCurrentWorkspaceIdSuccess  - Above on success (Do not call outside of epic)
 * @property {Function} setCurrentWorkspaceIdFailed   - Above on failure (Do not call outside of epic)
 * @property {Function} getWorkspacesRequest          - Request all available workspace for the user
 * @property {Function} getWorkspacesSuccess          - Above on success (Do not call outside of epic)
 * @property {Function} getWorkspacesFailed           - Above on failure (Do not call outside of epic)
 * @property {Function} reset                         - Reset the store to initial state
 */

export const systemSlice = createSlice({
  name: 'system',
  initialState: initialState,
  reducers: {
    setCurrentWorkspaceIdRequest: reducers.setCurrentWorkspaceIdRequest,
    setCurrentWorkspaceIdSuccess: reducers.setCurrentWorkspaceIdSuccess,
    setCurrentWorkspaceIdFailed: reducers.setCurrentWorkspaceIdFailed,
    getWorkspacesRequest: reducers.getWorkspacesRequest,
    getWorkspacesSuccess: reducers.getWorkspacesSuccess,
    getWorkspacesFailed: reducers.getWorkspacesFailed,
    setCurrentProjectId: reducers.setCurrentProjectId,
    getProjectsRequest: reducers.getProjectsRequest,
    getProjectsSuccess: reducers.getProjectsSuccess,
    getProjectsFailed: reducers.getProjectsFailed,
    navigate: reducers.navigate,
    reset: reducers.reset,
  },
})

export const {
  setCurrentWorkspaceIdRequest,
  setCurrentWorkspaceIdSuccess,
  setCurrentWorkspaceIdFailed,
  getWorkspacesRequest,
  getWorkspacesSuccess,
  getWorkspacesFailed,
  setCurrentProjectId,
  getProjectsRequest,
  getProjectsSuccess,
  getProjectsFailed,
  navigate,
  reset,
} = systemSlice.actions
