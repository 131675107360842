import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'

import StringUtils from 'app/utils/stringUtils'
import { RootState } from '../rootReducer'
import { ItemDetailsState } from './state'
import {
  ItemDetailsField,
  ItemDetailsTitle,
  ItemDetailsAction,
  ItemDetailsCurrentAction,
  ItemDetailMode,
  ItemDetailsStatus,
  LinkedAllDbsItemsEntries,
  LinkedAllDbsItems,
  LinkedItem,
  Status,
} from './types'
import { ItemEntry } from '../items/types'
import { Datastore } from 'app/services/store/datastore/types'
import FieldsHelper from 'app/utils/fieldsHelper'

import { getDatastoreNameFromId } from 'app/utils/storeHelper'

const itemDetailsState = (state: RootState): ItemDetailsState => state.itemDetails
const itemDetailsEntry = (state: RootState): ItemEntry => state.itemDetails.entry || {}
const itemDetailsTitles = (state: RootState): Array<ItemDetailsTitle> => state.itemDetails.titles || []
const linkedAllDbsItems = (state: RootState): LinkedAllDbsItems => state.itemDetails.linkedAllDbsItems || null
const datastores = (state: RootState): Array<Datastore> => state.datastores.datastores_list

/**
 * @selector
 *
 * @description Return The current loading status of the itemDetails
 *
 * @returns {boolean} the current loading status of the itemDetails
 */
export const getIsLoading = createSelector<RootState, ItemDetailsState, boolean>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.loading,
)

/**
 * @selector
 *
 * @description Return The current loading status of the itemDetails
 *
 * @returns {boolean} the current loading status of the itemDetails
 */
export const getStatuses = createSelector<RootState, ItemDetailsState, ItemDetailsStatus>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.statuses,
)
/**
 * @selector
 *
 * @description Return The entry list from the itemdetails
 *
 * @returns {ItemEntry} the entry list from the itemdetails
 */
export const getEntry = createSelector<RootState, ItemDetailsState, ItemEntry>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry,
)

/**
 * @selector
 *
 * @description Return the item revision number
 *
 * @returns {number} the item revision number
 */
export const getRevisionNumber = createSelector<RootState, ItemDetailsState, number>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry.rev_no,
)

/**
 * @selector
 *
 * @description Return the fields of the item
 *
 * @returns {{[k: string]: ItemDetailsField}} the fields of the item sorted by field_id
 */
export const getFields = createSelector<RootState, ItemDetailsState, { [k: string]: ItemDetailsField }>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.fields,
)

/**
 * @selector
 *
 * @description Return the actions of the item
 *
 * @returns {Array<ItemDetailsAction>}  the actions of the item
 */
export const getActions = createSelector<RootState, ItemDetailsState, Array<ItemDetailsAction>>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.actions,
)

/**
 * @selector
 *
 * @description Return the status action of the item
 *
 * @returns {Array<ItemDetailsAction>} the status actions of the item
 */
export const getStateActions = createSelector<RootState, ItemDetailsState, Array<ItemDetailsAction>>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.statusActions || [],
)

/**
 * @selector
 *
 * @description return the current selected action
 *
 * @returns {ItemDetailsCurrentAction | undefined} the current selected action (undefined if none)
 */
export const getCurrentAction = createSelector<RootState, ItemDetailsState, ItemDetailsCurrentAction | undefined>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.currentAction,
)

/**
 * @selector
 *
 * @description return the id of the item
 *
 * @returns {string} the id of the item
 */
export const getEntryId = createSelector<RootState, ItemDetailsState, string>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry.i_id,
)

/**
 * @selector memoized
 *
 * @param {string} fieldId the id of the field we wish to get
 *
 * @description return an item field based on the provided field id
 *
 * @returns {ItemDetailsField} the field based on the id provided
 */
export const getFieldById = createSelector<RootState, ItemDetailsState, (f_id: string) => ItemDetailsField>(
  itemDetailsState,
  (itemDetailsState) => memoize<ItemDetailsField>((f_id) => itemDetailsState.fields[f_id]),
)

/**
 * @selector memoized
 *
 * @param {string} fieldId the id of the field we wish to get
 *
 * @description return an item field value based on the provided field id
 *
 * @returns {any} the field value based on the id provided
 */
export const getEntryValueById = createSelector<RootState, ItemDetailsState, (f_id: string) => any>(
  itemDetailsState,
  (itemDetailsState) => memoize<any>((f_id) => itemDetailsState.entry.fields[f_id]),
)

/**
 * @selector
 *
 * @description Return the fieldId of the status field if provided in the itemDetails
 *
 * @returns {string} the status field Id
 */
export const getStatusField = createSelector<
  RootState,
  ItemDetailsState,
  { [k: string]: ItemDetailsField },
  ItemDetailsField | undefined
>(itemDetailsState, getFields, (itemsDetailsState: ItemDetailsState, fields: { [k: string]: ItemDetailsField }) =>
  Object.values(fields).find((f) => f.dataType === FieldsHelper.fields.status.value),
)

/**
 * @selector memoized
 *
 * @param {string} actionId the id of the action we wish to get
 *
 * @description search and return the actionId wether it's a status action or a item action
 *
 * @returns {ItemDetailsAction} the action based on the id provided
 */
export const getAnyActionById = createSelector<RootState, ItemDetailsState, (a_id: string) => ItemDetailsAction>(
  itemDetailsState,
  (itemDetailsState) =>
    memoize<ItemDetailsAction>((a_id) => {
      const sAction = (itemDetailsState.statusActions || []).find((a) => a.a_id === a_id)
      const iAction = (itemDetailsState.actions || []).find((a) => a.a_id === a_id)
      return sAction || iAction
    }),
)

/**
 * @selector memoized
 *
 * @param {string} actionId the id of the action we wish to get
 *
 * @description return an item action based on the provided action id
 *
 * @returns {ItemDetailsAction} the action based on the id provided
 */
export const getActionById = createSelector<RootState, ItemDetailsState, (a_id: string) => ItemDetailsAction>(
  itemDetailsState,
  (itemDetailsState) => memoize<ItemDetailsAction>((a_id) => itemDetailsState.actions.find((a) => a.a_id === a_id)),
)

/**
 * @selector memoized
 *
 * @param {string} actionId the id of the action we wish to get
 *
 * @description return an item status action based on the provided action id
 *
 * @returns {ItemDetailsAction} the action based on the id provided
 */
export const getStatusActionById = createSelector<RootState, ItemDetailsState, (a_id: string) => ItemDetailsAction>(
  itemDetailsState,
  (itemDetailsState) =>
    memoize<ItemDetailsAction>((a_id) => itemDetailsState.statusActions.find((a) => a.a_id === a_id)),
)

/**
 * @selector memoized
 *
 * @param {string} actionId the id of the action we wish to get
 *
 * @description return an item status action based on the provided action id
 *
 * @returns {ItemDetailsAction} the action based on the id provided
 */
export const getStatusById = createSelector<RootState, ItemDetailsState, (s_id: string) => Status | undefined>(
  itemDetailsState,
  (itemDetailsState) =>
    memoize<ItemDetailsAction>((s_id) => itemDetailsState.statuses.statuses.find((s) => s.data.id === s_id)),
)

/**
 * @selector
 *
 * @description return the current itemDetails display mode
 *
 * @returns {ItemDetailMode} the current display mode
 */
export const getDisplayMode = createSelector<RootState, ItemDetailsState, ItemDetailMode>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.mode,
)

/**
 * @selector
 *
 * @description Return the item list of title
 *
 * @returns {Array<string>} the item list of title
 */
export const getItemsTitlesIds = createSelector<RootState, Array<ItemDetailsTitle>, ItemEntry, Array<string>>(
  itemDetailsTitles,
  itemDetailsEntry,
  (itemDetailsTitle: Array<ItemDetailsTitle>, itemEntry: ItemEntry) => {
    const titles = Array<string>()
    itemDetailsTitle.forEach((title) => {
      titles.push(itemEntry.fields[title.columnID])
    })
    return titles
  },
)

export const getLinkedAllDbsItemsDisplayableEntries = createSelector<
  RootState,
  LinkedAllDbsItems,
  Array<Datastore>,
  Array<LinkedAllDbsItemsEntries>
>(linkedAllDbsItems, datastores, (linkedAllDbsItems: LinkedAllDbsItems, datastores: Array<Datastore>) => {
  const linkedItemData = new Array<LinkedAllDbsItemsEntries>()

  if (linkedAllDbsItems) {
    linkedAllDbsItems.forEach((linkedItems) => {
      if (linkedItems && linkedItems.items && linkedItems.columnsSettings) {
        const dispColumns = Object.values(linkedItems.columnsSettings).map((setting) => {
          return {
            title: setting.name,
            dataIndex: setting.display_id,
            key: setting.display_id,
            id: setting.id,
          }
        })
        const dispItems = linkedItems.items.map((item: LinkedItem) => {
          const targetObj: { [key: string]: any } = {}

          dispColumns.forEach((column: any) => {
            targetObj[column.key] = item[column.id]
          })

          targetObj['key'] = `${StringUtils.GenUUID()}-${item.i_id}`
          targetObj['i_id'] = item.i_id
          targetObj['d_id'] = item.d_id

          return targetObj
        })
        linkedItemData.push({
          columns: dispColumns,
          items: dispItems,
          dName: getDatastoreNameFromId(datastores, linkedItems.d_id),
        })
      }
    })
  }

  return linkedItemData
})

export default [
  getIsLoading,
  getEntry,
  getFields,
  getActions,
  getStateActions,
  getFieldById,
  getCurrentAction,
  getEntryValueById,
  getDisplayMode,
  getItemsTitlesIds,
]
