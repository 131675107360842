import React from 'react'
import styled from 'styled-components'

interface StatusBlockProps {
  label: string
  isCurrent: boolean
  noStatus: boolean
  isStart: boolean
  isEnd: boolean
}
export const StatusBlock = ({ label, isCurrent, noStatus, isStart, isEnd }: StatusBlockProps): JSX.Element => {
  return (
    <span style={{ position: 'relative' }}>
      <Block isCurrent={isCurrent} noStatus={noStatus} isStart={isStart} isEnd={isEnd}>
        {label}
      </Block>
      {!isEnd && <Arrow isCurrent={isCurrent} noStatus={noStatus} />}
      {!isEnd && !isCurrent && <ArrowFrame noStatus={noStatus} />}
    </span>
  )
}

export default StatusBlock

const Block = styled.p<{ isCurrent: boolean; noStatus: boolean; isStart: boolean; isEnd: boolean }>`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 35px;
  text-align: center;
  padding: 6px 0 0 10px;
  vertical-align: middle;
  border-radius: ${(props) => {
    if (props.isStart) return '10px 0 0 10px'
    if (props.isEnd) return '0 10px 10px 0'
    return ''
  }};
  background-color: ${(props) => (props.noStatus ? 'gray' : props.isCurrent ? 'rgb(70, 107, 169)' : 'gray')};
  color: white;
`

const Arrow = styled.div<{ isCurrent?: boolean; noStatus?: boolean }>`
  position: absolute;
  z-index: 2;
  display: inline-block;
  width: 0;
  height: 0;
  right: -18px;
  top: -16px;
  border-style: solid;
  border-width: 16px 0 19px 18px;
  border-color: ${(props) =>
    props.noStatus
      ? 'transparent transparent transparent gray'
      : props.isCurrent
      ? 'transparent transparent transparent rgb(70, 107, 169)'
      : 'transparent transparent transparent gray'};
`

const ArrowFrame = styled.div<{ noStatus: boolean }>`
  position: absolute;
  z-index: 1;
  display: inline-block;
  width: 0;
  height: 0;
  right: -20px;
  top: -16px;
  border-style: solid;
  border-width: 16px 0 19px 19px;
  border-color: transparent transparent transparent white;
`
