import { ItemDetailsState, initialState } from './state'
import { PayloadAction } from '@reduxjs/toolkit'
import { ItemDetails, ItemDetailMode, ItemDetailsCurrentAction, LinkedAllDbsItems } from './types'

export const getItemDetailsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ itemId: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getItemDetailsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<ItemDetails>,
): ItemDetailsState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: '',
  }
}

export const getItemDetailsFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const setMode = (state: ItemDetailsState, action: PayloadAction<{ mode: ItemDetailMode }>): ItemDetailsState => {
  return {
    ...state,
    mode: action.payload.mode,
  }
}

export const selectAction = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const getActionSettingsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getActionSettingsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ action: ItemDetailsCurrentAction }>,
): ItemDetailsState => {
  return {
    ...state,
    currentAction: action.payload.action,
    loading: false,
    error: '',
  }
}

export const getActionSettingsFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const cancelAction = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    currentAction: undefined,
  }
}

export const goToItem = (
  state: ItemDetailsState,
  action: PayloadAction<{ projectId: string; datastoreId: string; itemId: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const updateItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string; changes: { [k: string]: any }; newStatusId?: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const updateItemSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ changes: Array<{ id: string; value: string }>; newStatusId?: string }>,
): ItemDetailsState => {
  action.payload.changes.forEach((change) => {
    state.entry.fields[change.id] = change.value
  })
  state.entry.status_id = action.payload.newStatusId
  return state
}

export const newItemFailed = (state: ItemDetailsState, action: PayloadAction<{ error: string }>): ItemDetailsState => {
  return {
    ...state,
  }
}

export const newItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string; fields: { [k: string]: any } }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const newItemSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ changes: Array<{ id: string; value: string }> }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const updateItemFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const setRevisionNumber = (
  state: ItemDetailsState,
  action: PayloadAction<{ revisionNumber: number }>,
): ItemDetailsState => {
  return {
    ...state,
    entry: {
      ...state.entry,
      rev_no: action.payload.revisionNumber,
    },
  }
}

export const newItemModeRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ keepFieldsValue: boolean }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const newItemModeSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ fields: { [k: string]: any }; newItemId: string }>,
): ItemDetailsState => {
  return {
    ...state,
    entry: {
      ...state.entry,
      i_id: action.payload.newItemId,
      fields: action.payload.fields,
    },
    loading: false,
    error: '',
  }
}

export const newItemModeFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getLinkedAllDbsItemsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ iId: string; relations: ItemDetailsState['relations'] }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getLinkedAllDbsItemsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ linkedAllDbsItems: LinkedAllDbsItems }>,
): ItemDetailsState => {
  return {
    ...state,
    linkedAllDbsItems: action.payload.linkedAllDbsItems,
    loading: false,
  }
}

export const createItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ dId: string; item: { [key: string]: any } }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}
export const deleteItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ navigatePath: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deleteItemSuccess = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    error: '',
    loading: false,
  }
}

export const deleteItemFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const reset = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...initialState,
  }
}
