import React from 'react'
import { Spin } from 'antd'

import { ROUTE_PATH } from 'app/constants/router'
import { QuotationListItems, QuotationListColumn } from 'app/types/components/quotation'
import Result from 'app/components/organisms/search/Result'

interface SearchResultPageProps {
  columns: QuotationListColumn[]
  dataSource: QuotationListItems[]
  reset: Function
  isLoading: boolean
}
const SearchResultPage = ({ columns, dataSource, reset, isLoading }: SearchResultPageProps): JSX.Element => {
  return (
    <Spin size="large" spinning={isLoading}>
      <Result
        columns={columns}
        dataSource={dataSource}
        title="見積"
        routePathForList={ROUTE_PATH.QUOTATION_LIST}
        isCustomerSearchResult={true}
        reset={reset}
      />
    </Spin>
  )
}

export default SearchResultPage
