import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Form, Input, Select, DatePicker, Button, Modal, Typography } from 'antd'
import moment from 'moment'

import { DATASTORE_ID } from 'app/constants/hexabase'
import {
  QUOTATION_FORM_NAME,
  PUBLIC_PRIVATE_OPTIONS,
  NEW_CONSTRUCTION_RENOVATION_OPTIONS,
  QUOTATION_DISP_ID as DISP_ID,
} from 'app/constants/components/quotation'
import { getTotalSummaryResource, extractQuotationSummary } from 'app/utils/components/quotation/quotation'
import { QuotationDetailsActions, QuotationDetailsSelectors } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'

import StatusBar from 'app/components/organisms/quotation/quotationDetail/StatusBar'
import Summary from 'app/components/organisms/quotation/quotationDetail/Summary'
import { calculateTotalSummary } from 'app/utils/store/quotationDetails'

const { Option } = Select
const { Text } = Typography

interface CustomerInfoProps {
  isDisabled: boolean
  quotationStatus?: number
  isCustomerInfoSaved: boolean
  personInChargeName: string
  personInChargeEmail: string
}
const CustomerInfo = ({
  isDisabled,
  quotationStatus,
  isCustomerInfoSaved,
  personInChargeName,
  personInChargeEmail,
}: CustomerInfoProps): JSX.Element => {
  const [form] = Form.useForm()
  const [isModalOpen, handleModalOpen] = useState(false)

  const dispatch = useDispatch()
  const customerSearchResult = QuotationDetailsSelectors.getCustomerSearchResult(useSelector(rootSelector))
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const constructionMethod = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))
  const itemId = quotation['i_id'] || undefined

  const openModal = useCallback(() => {
    handleModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    handleModalOpen(false)
  }, [])

  const handleSearchWithCustomerName = useCallback(
    (customerName) => {
      dispatch(QuotationDetailsActions.customerSearch({ customerName }))
    },
    [dispatch],
  )

  const summariesOfSales = useMemo(() => {
    const summariesOfSales: number[] = []
    constructionMethod.forEach((item) => {
      const flatValue = item.items.flatTabTotalSales ? item.items.flatTabTotalSales : 0
      const riseValue = item.items.riseTabTotalSales ? item.items.riseTabTotalSales : 0
      const elseValue = item.items.elseTabTotalSales ? item.items.elseTabTotalSales : 0
      summariesOfSales.push(Number(flatValue) + Number(riseValue) + Number(elseValue))
    })
    return summariesOfSales
  }, [constructionMethod])

  const summariesOfPurchase = useMemo(() => {
    const summariesOfPurchase: number[] = []
    constructionMethod.forEach((item) => {
      const flatValue = item.items.flatTabTotalPurchase ? item.items.flatTabTotalPurchase : 0
      const riseValue = item.items.riseTabTotalPurchase ? item.items.riseTabTotalPurchase : 0
      const elseValue = item.items.elseTabTotalPurchase ? item.items.elseTabTotalPurchase : 0
      summariesOfPurchase.push(Number(flatValue) + Number(riseValue) + Number(elseValue))
    })
    return summariesOfPurchase
  }, [constructionMethod])

  const { siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin } = useMemo(() => {
    return calculateTotalSummary(summariesOfSales, summariesOfPurchase)
  }, [summariesOfSales, summariesOfPurchase])

  const handleCalculateTotalSummary = useCallback((): void => {
    closeModal()
    if (quotation.i_id) {
      dispatch(
        QuotationDetailsActions.updateQuotationAndConstruction({
          datastoreId: DATASTORE_ID.QUOTATION,
          itemId: quotation.i_id,
          item: { siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin },
        }),
      )
    } else {
      // TODO: itemIdがない場合の実装
      console.log('見積DBのitemIdが取得できません')
    }
  }, [siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin, dispatch, quotation.i_id, closeModal])

  const totalValues = {
    siteTotalOfSales: quotation.siteTotalOfSales ? quotation.siteTotalOfSales : 0,
    siteTotalOfPurchase: quotation.siteTotalOfPurchase ? quotation.siteTotalOfPurchase : 0,
    siteTotalOfGrossProfitMargin: quotation.siteTotalOfGrossProfitMargin ? quotation.siteTotalOfGrossProfitMargin : 0,
  }
  const totalSummaryResources = getTotalSummaryResource(
    totalValues.siteTotalOfSales,
    totalValues.siteTotalOfPurchase,
    totalValues.siteTotalOfGrossProfitMargin,
  )

  const onFinish = (values) => {
    const quotationSummary = extractQuotationSummary(quotation)
    const item = {
      ...values,
      ...quotationSummary,
    }
    const datastoreId = DATASTORE_ID.QUOTATION
    item['siteTotalOfSales'] = Number(item['siteTotalOfSales'])
    item['siteTotalOfPurchase'] = Number(item['siteTotalOfPurchase'])
    item['siteTotalOfGrossProfitMargin'] = Number(item['siteTotalOfGrossProfitMargin'])
    item['personInChargeName'] = personInChargeName

    if (isCustomerInfoSaved && itemId) {
      // update
      dispatch(QuotationDetailsActions.updateQuotationAndConstruction({ datastoreId, itemId, item }))
    } else {
      // create
      item[DISP_ID.DATE_OF_CREATION] = new Date().toISOString()
      dispatch(QuotationDetailsActions.createQuotation({ item }))
    }
  }
  // TODO: リファクタ
  const dateFormat = (quotation) => {
    Object.entries(quotation).forEach(([key, value]) => {
      if (key === QUOTATION_FORM_NAME.EXPIRATION_DATE && typeof value === 'string') {
        quotation[key] = moment(value).isValid() ? moment(value) : undefined
      }
      if (key === QUOTATION_FORM_NAME.DATE_OF_CREATION && typeof value === 'string') {
        quotation[key] = moment(value).isValid() ? moment(value) : undefined
      }
    })
    return quotation
  }

  // initialValuesがセットされない問題の対処
  // @see: https://github.com/ant-design/ant-design/issues/22372#issuecomment-602102164
  useEffect(() => form.resetFields(), [form, quotation])

  return (
    <div style={{ backgroundColor: 'rgb(222, 237, 248)', padding: '25px 20px 10px', borderRadius: '10px' }}>
      <Row>
        <Col span={10}>
          <StatusBar currentStatus={quotationStatus} />
        </Col>
      </Row>

      <Form initialValues={dateFormat(quotation)} onFinish={onFinish} form={form}>
        <Row style={{ marginTop: '50px' }}>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・得意先選択"
              name={QUOTATION_FORM_NAME.CUSTOMER_NAME}
              rules={[{ required: true, message: '入力必須' }]}
            >
              <Select
                showSearch
                disabled={isDisabled}
                defaultActiveFirstOption={false}
                showArrow={false}
                notFoundContent={null}
                onSearch={(customerName: string): void => handleSearchWithCustomerName(customerName)}
                onChange={(customerName) => {
                  const customer = customerSearchResult.filter((result) => result.customerName === customerName)[0]
                  form.setFieldsValue({
                    destinationEmail: customer['customerMail'],
                  })
                  form.setFieldsValue({
                    customerCode: customer['customerCode'],
                  })
                }}
              >
                {customerSearchResult.map((result, index) => (
                  <Option key={index} value={result.customerName}>
                    {result.customerName}({result.customerCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item labelCol={{ span: 5 }} colon={false} label="・表示得意先" name={QUOTATION_FORM_NAME.CUSTOMER_NAME2}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・得意先担当"
              name={QUOTATION_FORM_NAME.DESTINATION}
              style={{whiteSpace:'nowrap'}}
            >
              <Input addonAfter="様"/>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              rules={[{ type: 'email', message: '入力形式に誤りがあります' }]}
              colon={false}
              label="・E-mail"
              name={QUOTATION_FORM_NAME.DESTINATION_EMAIL}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・現場名"
              name={QUOTATION_FORM_NAME.SITE_NAME}
              rules={[{ required: true, message: '入力必須' }]}
            >
              <Input disabled={isDisabled} autoComplete={'off'} />
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item labelCol={{ span: 5 }} colon={false} label="・住所" name={QUOTATION_FORM_NAME.ADDRESS}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item labelCol={{ span: 5 }} colon={false} label="・現場No" name={QUOTATION_FORM_NAME.SITE_NUM}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={4}>
            <Form.Item labelCol={{ span: 10 }} colon={false} label="・官/民" name={QUOTATION_FORM_NAME.PUBLIC_PRIVATE}>
              <Select disabled={isDisabled}>
                {PUBLIC_PRIVATE_OPTIONS.map((option, index) => (
                  <Option value={option} key={index}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              labelCol={{ span: 10 }}
              colon={false}
              label="・新築/改修"
              name={QUOTATION_FORM_NAME.NEW_CONSTRUCTION_RENOVATION}
            >
              <Select disabled={isDisabled}>
                {NEW_CONSTRUCTION_RENOVATION_OPTIONS.map((option, index) => (
                  <Option value={option} key={index}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・有効期限"
              name={QUOTATION_FORM_NAME.EXPIRATION_DATE}
            >
              <DatePicker disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              labelCol={{ span: 5 }}
              colon={false}
              label="・表示日時"
              name={QUOTATION_FORM_NAME.DATE_OF_CREATION}
            >
              <DatePicker disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col offset={1} span={7}>
            <Form.Item
              style={{ display: 'none' }}
              labelCol={{ span: 5 }}
              colon={false}
              label="・顧客コード"
              name={QUOTATION_FORM_NAME.CUSTOMER_CODE}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button type="primary" htmlType="submit" disabled={isDisabled}>
              {isCustomerInfoSaved ? '保存' : '登録して見積入力へ進む'}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal visible={isModalOpen} onOk={handleCalculateTotalSummary} onCancel={closeModal} centered>
        <Text strong>見積書全体の集計処理を行います</Text>
        <br />
        <br />
        <Text>事前に全工法の計算処理を行う必要があります。</Text>
        <br />
        <Text>見積書全体の集計処理を実行してよろしいですか。</Text>
      </Modal>
    </div>
  )
}

export default CustomerInfo
