import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'

import { HEXA_ID } from 'app/constants/hexabase'

import HttpService from 'app/services/httpService/httpService'
import * as Types from './linkerApis.types'

const applicationId = HEXA_ID.APPLICATION
interface ItemList {
  applicationId: string
  datastoreId: string
  conditions: Types.SearchConditions
}

export const itemList = ({
  applicationId,
  datastoreId,
  conditions,
}: ItemList): AxiosObservable<Types.items_list_response> => {
  return HttpService.PostAsync<Types.items_list_request, Types.items_list_response>(
    `applications/${applicationId}/datastores/${datastoreId}/items/search`,
    {
      conditions,
      use_field_display_id: true,
      use_display_id: true,
      include_links: false,
      per_page: 0,
      page: 1,
    },
    HttpService.LinkerAPIBasePath,
  )
}

interface CreateItem {
  applicationId: string
  datastoreId: string
  item: Types.Item
  relatedDsItems?
}

export const createItem = ({
  applicationId,
  datastoreId,
  item,
  relatedDsItems,
}: CreateItem): AxiosObservable<Types.create_item_response> => {
  return HttpService.PostAsync<Types.create_item_request, Types.create_item_response>(
    `/applications/${applicationId}/datastores/${datastoreId}/items/new`,
    {
      item,
      use_display_id: true,
      related_ds_items: relatedDsItems,
    },
    HttpService.LinkerAPIBasePath,
  )
}

interface UpdateItem {
  applicationId?: string
  datastoreId: string
  itemId: string
  changes?: Types.Changes
  revNo?: number
  relatedDsItems?
  item?: Types.Item
}

export const updateItem = ({
  datastoreId,
  itemId,
  changes,
  revNo,
  relatedDsItems,
  item,
}: UpdateItem): AxiosObservable<null> => {
  return HttpService.PostAsync<Types.update_item_request, null>(
    `applications/${applicationId}/datastores/${datastoreId}/items/edit/${itemId}`,
    {
      changes,
      use_display_id: true,
      is_force_update: revNo ? false : true,
      rev_no: revNo,
      related_ds_items: relatedDsItems,
      item,
    },
    HttpService.LinkerAPIBasePath,
  )
}

interface getReportData {
  dataReportsId: string
}

export const getReportData = ({ dataReportsId }: getReportData): AxiosObservable<Types.get_report_data_response> => {
  const applicationId = HEXA_ID.APPLICATION
  return HttpService.GetAsync<null, Types.get_report_data_response>(
    `applications/${applicationId}/reports/${dataReportsId}`,
    null,
    HttpService.LinkerAPIBasePath,
  )
}

interface DeleteItem {
  datastoreId: string
  itemId: string
  deleteLinkedItems?: boolean
  targetDatastores?: string[]
}

export const deleteItem = ({
  datastoreId,
  itemId,
  deleteLinkedItems,
  targetDatastores,
}: DeleteItem): AxiosObservable<Types.delete_item_response> => {
  const applicationId = HEXA_ID.APPLICATION

  return HttpService.DeleteAsync<Types.delete_item_request, Types.delete_item_response>(
    `applications/${applicationId}/datastores/${datastoreId}/items/delete/${itemId}`,
    { delete_linked_items: deleteLinkedItems, target_datastores: targetDatastores || [] },
    HttpService.LinkerAPIBasePath,
  )
}

interface GetAutoNumber {
  datastoreId: string
  fieldId: string
  zeroPadding: boolean
  digit: number
  branchKey?: string
}

export const getAutoNumber = ({
  datastoreId,
  fieldId,
  zeroPadding,
  digit,
  branchKey,
}: GetAutoNumber): AxiosObservable<Types.get_auto_number_response> => {
  const applicationId = HEXA_ID.APPLICATION
  return HttpService.GetAsync<Types.get_auto_number_request, Types.get_auto_number_response>(
    `applications/${applicationId}/datastores/${datastoreId}/fields/${fieldId}/autonum`,
    {
      branch_key: branchKey,
      zero_padding: zeroPadding,
      digit,
    },
    HttpService.LinkerAPIBasePath,
  )
}

interface GetReportDataByConditions {
  reportId: string
  conditions: Types.DatareportConditions
}

export const getReportDataByConditions = ({
  reportId,
  conditions,
}: GetReportDataByConditions): AxiosObservable<Types.get_reportData_by_conditions_response> => {
  const applicationId = HEXA_ID.APPLICATION
  return HttpService.PostAsync<Types.get_reportData_by_conditions_request, Types.get_reportData_by_conditions_response>(
    `applications/${applicationId}/reports/${reportId}/filter`,
    {
      conditions,
    },
    HttpService.LinkerAPIBasePath,
  )
}

interface bulkUpdateItem {
  applicationId?: string
  datastoreId: string
  actionId: string
  conditions: Types.SearchConditions
  changes?: Types.Changes
}

export const bulkUpdateItem = ({
  applicationId,
  datastoreId,
  actionId,
  conditions,
  changes,
}: bulkUpdateItem): AxiosObservable<null> => {
  return HttpService.PostAsync<Types.bulk_update_item_request, null>(
    `applications/${applicationId}/datastores/${datastoreId}/items/bulkaction/${actionId}`,
    {
      conditions,
      changes,
      max_items : 300,
      is_force_update: true,
      use_display_id: true,
    },
    HttpService.LinkerAPIBasePath,
  )
}

interface ExecuteAction {
  datastoreId: string
  itemId: string
  actionId: string
}

export const executeAction = ({ datastoreId, itemId, actionId }: ExecuteAction): AxiosObservable<null> => {
  const applicationId = HEXA_ID.APPLICATION
  return HttpService.PostAsync<Types.execute_action_request, null>(
    `applications/${applicationId}/datastores/${datastoreId}/items/action/${itemId}/${actionId}`,
    { is_force_update: true }, //TODO: 必要な場合に拡張する
    HttpService.LinkerAPIBasePath,
  )
}

export const getDatastoreFields = (datastoreId: string): AxiosObservable<Types.get_datastore_fields_response> => {
  return HttpService.GetAsync<null, Types.get_datastore_fields_response>(
    `applications/${applicationId}/datastores/${datastoreId}/fields`,
    null,
    HttpService.LinkerAPIBasePath,
  )
}
