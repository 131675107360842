import React, { useRef } from 'react'
import moment from 'moment'
import { Select, DatePicker, Input, Row, Col, Button, Spin } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { SearchOption, DataKey, DataValue } from 'app/types/search'
import './overrideStyle.css'

const InputGroup = Input.Group
const { Option } = Select
const { RangePicker } = DatePicker
const today = moment()

type SearchRowProps = {
  rowData: {
    index: number
    itemId: string
    value: string
  }
  searchOptions: SearchOption[]
  changeData: (id: number, key: DataKey, value: DataValue) => void
  addRow: Function
  deleteRow: Function
}

const SearchRow = ({
  rowData: { index, itemId, value },
  searchOptions,
  changeData,
  addRow,
  deleteRow,
}: SearchRowProps): JSX.Element => {
  const selectedOption = useRef<SearchOption>()

  // selectedOptionの初期化
  if (index === 0 && value === '' && itemId === '') {
    selectedOption.current = {} as SearchOption
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Col span={22}>
          <InputGroup>
            <Row>
              <Col span={7}>
                <Select
                  defaultValue={itemId}
                  value={itemId}
                  style={{ width: 180, marginRight: '30px' }}
                  notFoundContent={searchOptions.length === 0 ? <Spin size="small" /> : null}
                  onChange={(value): void => {
                    changeData(index, 'itemId', value)
                    const optionIndex = searchOptions.findIndex((option) => option['id'] === value)
                    selectedOption.current = searchOptions[optionIndex]
                  }}
                >
                  {searchOptions.map((item, idx) => {
                    return (
                      <Option key={idx} value={item.id}>
                        {item.label}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
              {selectedOption.current && selectedOption.current.dataType === 'date' ? (
                <RangePicker
                  onChange={(_, dateStrings: [string, string]): void => changeData(index, 'value', dateStrings)}
                  style={{ width: '65%' }}
                  defaultValue={[today, today]}
                />
              ) : selectedOption.current && selectedOption.current.dataType === 'select' ? (
                <Select
                  style={{ width: '65%' }}
                  onChange={(value) => {
                    changeData(index, 'value', value as string)
                  }}
                >
                  {selectedOption.current &&
                    selectedOption.current.options.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                </Select>
              ) : (
                <Input
                  style={{ width: '65%' }}
                  value={value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    changeData(index, 'value', e.target.value)
                  }
                />
              )}
            </Row>
          </InputGroup>
        </Col>
        <Col span={1}>
          <Button
            icon={<PlusCircleOutlined style={{ fontSize: '20px' }} />}
            size="small"
            shape="circle"
            style={{ color: '#52c41a', border: 'none' }}
            onClick={(): void => addRow()}
          />
        </Col>
        <Col span={1}>
          <Button
            icon={<MinusCircleOutlined style={{ fontSize: '20px' }} />}
            size="small"
            shape="circle"
            style={{ color: 'red', border: 'none' }}
            onClick={(): void => deleteRow()}
          />
        </Col>
      </Row>
    </div>
  )
}

export default SearchRow
