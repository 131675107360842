export const STATUS_LABELS = ['作成済', '未承認', '承認済']

export const FIELD_ID = {
  STATUS: 'ステータス',
  CUSTOMER_NAME: 'customerName',
  SITE_NAME: 'siteName',
  ADDRESS: 'address',
  TOTAL_SALES: 'siteTotalOfSales',
  DATE_OF_CREATION: 'dateOfCreation',
}

export const ELSE_ID = {
  PRODUCT_NAME: 'productName',
  PRODUCT_NAME_KANA: 'productNameFurigana',
  MAKER_NAME: 'makerName',
  STANDARD_CAPACITY: 'standardCapacity',
}

export const QUOTATION_FORM_NAME = {
  CUSTOMER_NAME: 'customerName',
  CUSTOMER_NAME2: 'customerName2',
  CUSTOMER_CODE: 'customerCode',
  CUSTOMER_MAIL: 'customerMail',
  DESTINATION: 'destination',
  DESTINATION_EMAIL: 'destinationEmail',
  SITE_NAME: 'siteName',
  ADDRESS: 'address',
  SITE_NUM: 'siteNum',
  PUBLIC_PRIVATE: 'publicPrivate',
  NEW_CONSTRUCTION_RENOVATION: 'newConstructionRenovation',
  EXPIRATION_DATE: 'expirationDate',
  DATE_OF_CREATION: 'dateOfCreation',
  CONSTRUCTION_TYPE: 'constructionType',
  CONSTRUCTION_LOCATION: 'constructionLocation',
  GROUND_WORK: 'groundwork',
  EXISTING_WATERPROOF: 'existingWaterproof',
  MAKER: 'maker',
  SYSTEM: 'system',
  CONSTRUCTION_METHOD: 'constructionMethod',
  CATALOG_URL: 'catalog',
  SPECIFICATION_CODE_FLAT: 'specificationCodeFlat',
  CONSTRUCTION_VOLUME_FLAT: 'constructionVolumeFlat',
  SPECIFICATION_CODE_RISE: 'specificationCodeRise',
  CONSTRUCTION_VOLUME_RISE: 'constructionVolumeRise',
  RESERVE1: 'reserve1',
  SEPARATE_OR_TOTAL: 'separateOrTotal',
  REMARKS: 'remarks',
  QUOTATION_NUM: 'quotationNum',
  QUOTATION_ID: 'quotationId',
  QUOTATION_ID_TEXT: 'quotationIdText',
  CONST_TOTAL_SALES: 'constTotalOfSales',
  CONST_TOTAL_PURCHASE: 'constTotalOfPurchase',
  CONST_TOTAL_GROSS_PROFIT_MARGIN: 'constTotalOfGrossProfitMargin',
  FLAT_TAB_TOTAL_SALES: 'flatTabTotalSales',
  FLAT_TAB_TOTAL_PURCHASE: 'flatTabTotalPurchase',
  FLAT_M2_UNIT_PRICE: 'flatM2UnitPrice',
  FLAT_TAB_TOTAL_GROSS_PROFIT_MARGIN: 'flatTabTotalGrossProfitMargin',
  RISE_TAB_TOTAL_SALES: 'riseTabTotalSales',
  RISE_TAB_TOTAL_PURCHASE: 'riseTabTotalPurchase',
  RISE_M2_UNIT_PRICE: 'riseM2UnitPrice',
  RISE_TAB_TOTAL_GROSS_PROFIT_MARGIN: 'riseTabTotalGrossProfitMargin',
  ELSE_TAB_TOTAL_SALES: 'elseTabTotalSales',
  ELSE_TAB_TOTAL_PURCHASE: 'elseTabTotalPurchase',
  ELSE_TAB_TOTAL_GROSS_PROFIT_MARGIN: 'elseTabTotalGrossProfitMargin',
}

export const CASCADING_SELECT_FORMS = [
  QUOTATION_FORM_NAME.CONSTRUCTION_TYPE,
  QUOTATION_FORM_NAME.MAKER,
  QUOTATION_FORM_NAME.SYSTEM,
  QUOTATION_FORM_NAME.CONSTRUCTION_METHOD,
  QUOTATION_FORM_NAME.SPECIFICATION_CODE_FLAT,
  QUOTATION_FORM_NAME.SPECIFICATION_CODE_RISE,
]

export const QUOTATION_DISP_ID = {
  DATE_OF_CREATION: 'dateOfCreation',
  LAST_UPDATE_DATE: 'lastUpdateDate',
}

export const FLAT_GROUND_TABLE_NAME = {
  PRODUCT_NAME: 'productName',
  STANDARD_CAPACITY: 'standardCapacity',
  QUANTITY: 'quantity',
  QUANTITY_UNIT: 'productUnit',
  CONSTRUCTION_VOLUME: 'constructionVolume',
  CONSTRUCTION_VOLUME_UNIT: 'constructionVolumeUnit',
  AMOUNT_USED: 'amountUsed',
  AMOUNT_USED_UNIT: 'amountUsedUnit',
  INTERNAL_CAPACITY: 'internalCapacity',
  INTERNAL_CAPACITY_UNIT: 'internalCapacityUnit',
  SALES: 'sales',
  TOTAL_SALES: 'totalSales',
  PURCHASE: 'purchase',
  TOTAL_PURCHASE: 'totalPurchase',
  GROSS_PROFIT_MARGIN: 'grossProfitMargin',
  STANDARD_PURCHASE: 'standardPurchase',
  REMARKS: 'remarks',
}

export const QUOTATION_ID_LABEL = {
  I_ID: 'i_id',
  QUOTATION_ID_TEXT: 'quotationIdText',
  CONSTRUCTION_METHOD: 'constructionMethod',
  CONSTRUCTION_METHOD_ID_TEXT: 'constIdText',
  SYSTEM: 'system',
  FLAT_ID_TEXT: 'flatIdText',
  RISE_ID_TEXT: 'riseIdText',
  ELSE_ID_TEXT: 'elseIdText',
  SPECIFICATION_CODE: 'specificationCode',
}

export const FLAT_AND_RISE_EDITABLE_COLUMNS = [
  FLAT_GROUND_TABLE_NAME.PRODUCT_NAME,
  FLAT_GROUND_TABLE_NAME.STANDARD_CAPACITY,
  FLAT_GROUND_TABLE_NAME.AMOUNT_USED,
  FLAT_GROUND_TABLE_NAME.AMOUNT_USED_UNIT,
  FLAT_GROUND_TABLE_NAME.SALES,
  FLAT_GROUND_TABLE_NAME.PURCHASE,
  FLAT_GROUND_TABLE_NAME.REMARKS,
]
/*
export const OTHERS_EDITABLE_COLUMNS = [
  FLAT_GROUND_TABLE_NAME.PRODUCT_NAME,
  FLAT_GROUND_TABLE_NAME.STANDARD_CAPACITY,
  FLAT_GROUND_TABLE_NAME.AMOUNT_USED,
  FLAT_GROUND_TABLE_NAME.AMOUNT_USED_UNIT,
  FLAT_GROUND_TABLE_NAME.QUANTITY,
  FLAT_GROUND_TABLE_NAME.QUANTITY_UNIT,
  FLAT_GROUND_TABLE_NAME.CONSTRUCTION_VOLUME,
  FLAT_GROUND_TABLE_NAME.INTERNAL_CAPACITY,
  FLAT_GROUND_TABLE_NAME.INTERNAL_CAPACITY_UNIT,
  FLAT_GROUND_TABLE_NAME.SALES,
  FLAT_GROUND_TABLE_NAME.PURCHASE,
  FLAT_GROUND_TABLE_NAME.REMARKS,
]
*/
export const OTHERS_EDITABLE_COLUMNS = [
  FLAT_GROUND_TABLE_NAME.PRODUCT_NAME,
  FLAT_GROUND_TABLE_NAME.STANDARD_CAPACITY,
  FLAT_GROUND_TABLE_NAME.AMOUNT_USED_UNIT,
  FLAT_GROUND_TABLE_NAME.CONSTRUCTION_VOLUME,
  FLAT_GROUND_TABLE_NAME.SALES,
  FLAT_GROUND_TABLE_NAME.PURCHASE,
  FLAT_GROUND_TABLE_NAME.REMARKS,
]

export const EMPTY_FLAT_AND_RISE_RESOURCE = {
  i_id: '',
  productName: '',
  standardCapacity: '',
  quantity: 0,
  productUnit: '',
  constructionVolume: 0,
  constructionVolumeUnit: '',
  amountUsed: 0,
  amountUsedUnit: '',
  internalCapacity: 0,
  internalCapacityUnit: '',
  sales: 0,
  purchase: 0,
  grossProfitMargin: 0,
  standardPurchase: 0,
  remarks: '',
  constIdText: '',
  flatId: '',
  flatIdText: '',
  productCode: '',
  totalSales: 0,
  totalPurchase: 0,
}

export const EMPTY_SUMMARY_RESOURCES = {
  FLAT_GROUND: {
    title: '平場計',
    color: 'rgb(70, 107, 169)',
    backgroundColor: 'rgb(244, 246, 250)',
    isEmphasizeSales: true,
    withIcon: true,
  },
  RISING: {
    title: '立上計',
    color: 'rgb(70, 107, 169)',
    backgroundColor: 'rgb(244, 246, 250)',
    isEmphasizeSales: true,
    withIcon: true,
  },
  OTHERS: {
    title: 'その他計',
    color: 'rgb(70, 107, 169)',
    backgroundColor: 'rgb(244, 246, 250)',
    isEmphasizeSales: true,
    withIcon: true,
  },
  METHOD: {
    title: '工法計',
    color: 'rgb(79, 157, 138)',
    backgroundColor: 'rgb(248, 255, 253)',
    withIcon: true,
  },
  TOTAL: {
    title: '現場計',
    color: 'rgb(70, 107, 169)',
  },
}

export const PUBLIC_PRIVATE_OPTIONS = ['民間工事', '官庁工事']

export const NEW_CONSTRUCTION_RENOVATION_OPTIONS = ['改修', '新築']

export const CONSTRUCTION_LOCATION_OPTIONS = [
  '屋上',
  '塔屋',
  'ﾙｰﾌﾊﾞﾙｺﾆｰ',
  'ﾊﾞﾙｺﾆｰ',
  '大庇',
  '小庇',
  '開放廊下',
  '階段',
  '側溝・巾木',
  '笠木',
  '架台',
  'ﾊﾄ小屋',
  '地下ﾋﾟｯﾄ',
  '駐車場',
]

export const GROUND_WORK_OPTIONS = [
  'RC造',
  'PC造',
  'PCa造',
  'S造(ALC)',
  'S造(ﾎﾞｰﾄﾞ)',
  '木造',
  '木造(ﾎﾞｰﾄﾞ)',
  '金属',
  'S造(折板屋根)',
  'S造(瓦棒屋根)',
  'S造(ﾌﾗｯﾄﾃﾞｯｷ)',
  'ﾓﾙﾀﾙ(無筋)',
  'ﾀｲﾙ張り',
]

export const EXISTING_WATERPROOF_OPTIONS = [
  'ｱｽﾌｧﾙﾄ露出',
  '保護ｺﾝｸﾘｰﾄ',
  'ｳﾚﾀﾝ塗膜',
  'ﾎﾟﾘﾏｰｾﾒﾝﾄ系塗膜',
  'FRP',
  '塩ﾋﾞｼｰﾄ',
  'ｺﾞﾑｼｰﾄ',
  'ｽﾃﾝﾚｽｼｰﾄ',
  'ｱｽﾌｧﾙﾄｼﾝｸﾞﾙ',
  'ｽﾚｰﾄ屋根',
  '金属板屋根',
  'ｹｲ酸質系塗布',
  'その他',
]

export const LOWEST_PRICE_MODAL_TABLE_LABEL = ['１', '得意先名', '現場名', '商品名', '売上', '仕入','粗利率','作成日付', '担当者','２']

export const ACTION_ID = {
  NEXT_STATUS: 'proceedNext',
  PDF_DOWNLOAD: 'pdfdownload',
  SEND_EMAIL: 'sendpdf',
}
