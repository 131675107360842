import React, { useCallback, useMemo,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Tabs, Button, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { rootSelector } from 'app/services/store/rootReducer'
import { UsersSelectors } from 'app/services/store'

import { ConstructionDetailsResource } from 'app/types/components/quotationDetail'

import { QuotationDetailsSelectors, QuotationDetailsActions } from 'app/services/store'

import CustomerInfo from 'app/components/organisms/quotation/quotationDetail/CustomerInfo'
import ConstructionData from 'app/components/organisms/quotation/quotationDetail/ConstructionData'
import ConstructionRemarks from 'app/components/organisms/quotation/quotationDetail/ConstructionRemarks'
import { ConstructionMethod } from 'app/services/store/quotationDetails/types'

const { TabPane } = Tabs

interface QuotationDetailPageProps {
  mode: 'create' | 'update' | 'copy'
  isDisabled: boolean
  quotationStatus: number
  quotationNumber: string
  userName: string
  userEmail: string
  isCustomerInfoSaved: boolean
  constructionDataResources?: ConstructionMethod[]
  constructionDetailResources?: ConstructionDetailsResource[]
}

const QuotationDetailPage = (props: QuotationDetailPageProps): JSX.Element => {
  const { mode, isDisabled, quotationStatus, quotationNumber, userName, userEmail, isCustomerInfoSaved } = props
  const loading = QuotationDetailsSelectors.isLoading(useSelector(rootSelector))
  const dispatch = useDispatch()

  const [UserData, setUserData] =  useState({});

  const constructionMethods = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))
  const constructionDetailTabs = QuotationDetailsSelectors.getFlatRiseElse(useSelector(rootSelector))

  if(Object.keys(UserData).length === 0 ){
    const Userresult = UsersSelectors.getUserData(userEmail)
    Userresult.then(function(result_data) {
      let logeddata = {}
      logeddata["name"] = result_data["employeeName"]
      logeddata["tel"] = result_data["employeeTel"]
      logeddata["fax"] = result_data["employeeFax"]
      logeddata["deps"] = result_data["employeeDepartment"]
      setUserData({ ...UserData, logeddata });
        }, function(e) {
      // not called
    });
  }

  const addConstructionDataTabPane = useCallback((): void => {
    dispatch(QuotationDetailsActions.addConstructionMethod())
  }, [dispatch])
  const removeConstructionDataTabPane = useCallback(
    (tabId: string): void => {
      dispatch(QuotationDetailsActions.deleteConstructionMethod({ tabId }))
    },
    [dispatch],
  )

  const title = useMemo(() => {
    if (mode === 'create') {
      return '見積 新規作成'
    } else if (mode === 'update') {
      return '見積 編集'
    } else {
      return '見積 複製'
    }
  }, [mode])

  return (
    <div style={{ padding: '20px 40px' }}>
      <Row style={{ marginBottom: '50px' }}>
        <Col span={14}>
          <h2>{title}</h2>
        </Col>
        <Col span={5}>
          <span>見積番号:{quotationNumber}</span>
        </Col>
        <Col>
          <span>ユーザー名:{userName}</span>
        </Col>
      </Row>
      <Spin
        size="large"
        spinning={loading}
        style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <CustomerInfo
          isDisabled={isDisabled}
          isCustomerInfoSaved={isCustomerInfoSaved}
          quotationStatus={quotationStatus}
          personInChargeName={userName}
          personInChargeEmail={userEmail}
        />
        <Row style={{ marginTop: '30px' }}>
          <Col>
            <Button
              icon={<PlusOutlined />}
              onClick={addConstructionDataTabPane}
              disabled={isDisabled || !isCustomerInfoSaved || mode === 'copy'}
            />
          </Col>
        </Row>
        <Tabs type="card">
          {constructionMethods &&
            constructionMethods.map(
              (resource, index: number) =>
                index < 30 && (
                  <TabPane
                    tab={
                      resource.items.constructionMethod ? resource.items.constructionMethod : `工法${Number(index) + 1}`
                    }
                    key={resource.tabId}
                  >
                    <ConstructionData
                      mode={mode}
                      isDisabled={isDisabled}
                      tabId={resource.tabId}
                      resources={{
                        constructionMethod: resource,
                        flatResources: constructionDetailTabs[0][index],
                        riseResources: constructionDetailTabs[1][index],
                        elseResources: constructionDetailTabs[2][index],
                      }}
                      isCustomerInfoSaved={isCustomerInfoSaved}
                      removeConstructionDataTabPane={removeConstructionDataTabPane}
                    />
                  </TabPane>
                ),
            )}
        </Tabs>
        <ConstructionRemarks isDisabled={isDisabled} />
      </Spin>
    </div>
  )
}

export default QuotationDetailPage
