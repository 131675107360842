import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rootSelector } from 'app/services/store/rootReducer'
import { Form, Row, Col, Tabs, Button, Spin, Modal, Table, Typography } from 'antd'

import { PlusOutlined } from '@ant-design/icons'
import { QuotationDetailsActions, QuotationDetailsSelectors } from 'app/services/store'

import { TabLabels, QuotationTableItems, SummaryResourcesItems } from 'app/types/components/quotationDetail'
import { DATASTORE_ID } from 'app/constants/hexabase'

import {
  addEditableSettings,
  createConstMethodRelatedDsItems,
  getSummariesResources,
  calcFraction,
} from 'app/utils/components/quotation/quotation'

import { getTotalSummaryResource, extractQuotationSummary } from 'app/utils/components/quotation/quotation'

import DeleteRowButton from 'app/components/molecules/DeleteRowButton'
import SearchLowestPriceButton from 'app/components/organisms/quotation/quotationDetail/SearchLowestPriceButton'
import EditableTable from 'app/components/organisms/quotation/quotationDetail/quotationTable/EditableTable'
import Summary from 'app/components/organisms/quotation/quotationDetail/Summary'
import { calculateTotalSummary } from 'app/utils/store/quotationDetails'
import MerchandiseSearchContainer from 'app/containers/search/MerchandiseSearchContainer'
import AddMerchandiseModal from 'app/components/organisms/quotation/quotationDetail/AddMerchandiseModal'
import { ConstructionMethod, Flat, Rise, Else } from 'app/services/store/quotationDetails/types'

const { TabPane } = Tabs
const { Text } = Typography

interface ConstructionDetailTabsProps {
  tabId: string
  isDisabled: boolean
  resource: {
    constructionMethod: ConstructionMethod
    flatResources: Flat
    riseResources: Rise
    elseResources: Else
  }
  handleCalculateSummaryResources: () => void
}
const ConstructionDetailTabs = ({
  tabId,
  isDisabled,
  resource: { constructionMethod, flatResources, riseResources, elseResources },
  handleCalculateSummaryResources,
}: ConstructionDetailTabsProps): JSX.Element => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const customerCode = QuotationDetailsSelectors.getCustomerCode(useSelector(rootSelector))
  const siteName = QuotationDetailsSelectors.getSiteName(useSelector(rootSelector))
  const isConstuctionLoading = QuotationDetailsSelectors.isConstructionLoading(useSelector(rootSelector))
  const quotation = QuotationDetailsSelectors.getQuotation(useSelector(rootSelector))
  const constructionMethods = QuotationDetailsSelectors.getConstructionMethods(useSelector(rootSelector))

  const [isModalOpen, handleModalOpen] = useState(false)
  const columns = [
    {
      title: '商品',
      dataIndex: 'productName',
      key: 'productName',
      id: '',
      type: '',
    },
    {
      title: '規格・容量',
      dataIndex: 'standardCapacity',
      key: 'standardCapacity',
      id: '',
      type: '',
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      key: 'quantity',
      id: '',
      type: '',
    },
    {
      title: '=',
      dataIndex: 'productUnit',
      key: 'productUnit',
      id: '',
      type: '',
    },
    {
      title: '施工量',
      dataIndex: 'constructionVolume',
      key: 'constructionVolume',
      id: '',
      type: '',
    },
    {
      title: '×',
      dataIndex: 'constructionVolumeUnit',
      key: 'constructionVolumeUnit',
      id: '',
      type: '',
    },
    {
      title: '使用量',
      dataIndex: 'amountUsed',
      key: 'amountUsed',
      id: '',
      type: '',
    },
    {
      title: '÷',
      dataIndex: 'amountUsedUnit',
      key: 'amountUsedUnit',
      id: '',
      type: '',
    },
    {
      title: '内容量',
      dataIndex: 'internalCapacity',
      key: 'internalCapacity',
      id: '',
      type: '',
    },
    {
      title: '',
      dataIndex: 'internalCapacityUnit',
      key: 'internalCapacityUnit',
      id: '',
      type: '',
    },
    {
      title: '売値',
      dataIndex: 'sales',
      key: 'sales',
      id: '',
      type: '',
    },
    {
      title: '売上計',
      dataIndex: 'totalSales',
      key: 'totalSales',
      id: '',
      type: '',
    },
    {
      title: '仕入',
      dataIndex: 'purchase',
      key: 'purchase',
      id: '',
      type: '',
    },
    {
      title: '仕入計',
      dataIndex: 'totalPurchase',
      key: 'totalPurchase',
      id: '',
      type: '',
    },
    {
      title: '粗利率',
      dataIndex: 'grossProfitMargin',
      key: 'grossProfitMargin',
      id: '',
      type: '',
    },
    {
      title: '標準仕入',
      dataIndex: 'standardPurchase',
      key: 'standardPurchase',
      id: '',
      type: '',
    },
    {
      title: '備考',
      dataIndex: 'remarks',
      key: 'remarks',
      id: '',
      type: '',
    },
    {
      title: '価格（数量）設定',
      dataIndex: 'lowestPrice',
      key: 'lowestPrice',
      id: '',
      type: '',
    },
    {
      title: '',
      dataIndex: 'deleteRow',
      key: 'deleteRow',
      id: '',
      type: '',
    },
  ]

  const elsecolumns = [
    {
      title: '商品',
      dataIndex: 'productName',
      key: 'productName',
      id: '',
      type: '',
    },
    {
      title: '規格・容量',
      dataIndex: 'standardCapacity',
      key: 'standardCapacity',
      id: '',
      type: '',
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      key: 'quantity',
      id: '',
      type: '',
    },
    {
      title: '=',
      dataIndex: 'productUnit',
      key: 'productUnit',
      id: '',
      type: '',
    },
    {
      title: '施工量',
      dataIndex: 'constructionVolume',
      key: 'constructionVolume',
      id: '',
      type: '',
    },
    {
      title: '×',
      dataIndex: '',
      key: '',
      id: '',
      type: '',
    },
    {
      title: '使用量',
      dataIndex: 'amountUsed',
      key: 'amountUsed',
      id: '',
      type: '',
    },
    {
      title: '÷',
      dataIndex: 'amountUsedUnit',
      key: 'amountUsedUnit',
      id: '',
      type: '',
    },
    {
      title: '内容量',
      dataIndex: 'internalCapacity',
      key: 'internalCapacity',
      id: '',
      type: '',
    },
    {
      title: '',
      dataIndex: 'internalCapacityUnit',
      key: 'internalCapacityUnit',
      id: '',
      type: '',
    },
    {
      title: '売値',
      dataIndex: 'sales',
      key: 'sales',
      id: '',
      type: '',
    },
    {
      title: '売上計',
      dataIndex: 'totalSales',
      key: 'totalSales',
      id: '',
      type: '',
    },
    {
      title: '仕入',
      dataIndex: 'purchase',
      key: 'purchase',
      id: '',
      type: '',
    },
    {
      title: '仕入計',
      dataIndex: 'totalPurchase',
      key: 'totalPurchase',
      id: '',
      type: '',
    },
    {
      title: '粗利率',
      dataIndex: 'grossProfitMargin',
      key: 'grossProfitMargin',
      id: '',
      type: '',
    },
    {
      title: '標準仕入',
      dataIndex: 'standardPurchase',
      key: 'standardPurchase',
      id: '',
      type: '',
    },
    {
      title: '備考',
      dataIndex: 'remarks',
      key: 'remarks',
      id: '',
      type: '',
    },
    {
      title: '価格（数量）設定',
      dataIndex: 'lowestPrice',
      key: 'lowestPrice',
      id: '',
      type: '',
    },
    {
      title: '',
      dataIndex: 'deleteRow',
      key: 'deleteRow',
      id: '',
      type: '',
    },
  ]

  const [flatAndRiseColumns, othersColumns] = addEditableSettings(columns , elsecolumns)

  const [searchModalVisibility, setSearchModalVisibility] = useState<boolean>(false)
  const [addMerchandiseModalVisibility, setAddMerchandiseModalVisibility] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<TabLabels>('flat')
  const constIdText = useMemo(() => constructionMethod.items['constIdText'], [constructionMethod.items])
  console.log("test02")
  console.log(constructionMethod)
  console.log(constructionMethods)
  
  const SetLowestPriceButtonAndDeleteButton = (dataSource: QuotationTableItems[]): QuotationTableItems[] => {
    const originalValue = useSelector(rootSelector)
    const SetLowestPrice = (lowestSales: number, lowestPurchase: number, targetItems:any, iId: string, tabId: string, constIdText?: string) => {
      const targetRow = dataSource.find((item) => item.i_id === iId)

      dataSource.forEach((key) => {
        if (key.i_id === iId && targetRow) {
          console.log("targetItems")
          console.log(targetItems)
          const tabType = targetRow.tabType ? targetRow.tabType : '平場'
          const savedValues : any = []
          let datastoreId: string
          if(tabType==='平場'){
            datastoreId=DATASTORE_ID.FLAT
          } else if (tabType === '立上り') {
            datastoreId=DATASTORE_ID.RISE
          }else{
            datastoreId=DATASTORE_ID.ELSE
          }
          const targetIid = iId
          const updatedValues = [
            {
              dataIndex: 'sales',
              value: targetItems.sales,
            },
            {
              dataIndex: 'purchase',
              value: targetItems.purchase,
            },
            {
              dataIndex: 'remarks',
              value: targetItems.remarks,
            },
            {
              dataIndex: 'standardCapacity',
              value: targetItems.standardCapacity,
            },
            {
              dataIndex: 'productUnit',
              value: targetItems.productUnit,
            },
            {
              dataIndex: 'quantity',
              value: targetItems.quantity,
            },
            {
              dataIndex: 'productName',
              value: targetItems.productName,
            },
            {
              dataIndex: 'productCode',
              value: targetItems.productCode,
            },
            {
              dataIndex: 'amountUsed',
              value: targetItems.amountUsed,
            },
          ]
          dispatch(QuotationDetailsActions.editFlatRiseElse({ tabType, tabId, targetIid, updatedValues }))
        }else{
          if(targetRow && key.productCode === targetRow.productCode){
            const tabType = key.tabType ? key.tabType : '平場'
            const targetIid = key.i_id
            const updatedValues = [
             {
               dataIndex: 'sales',
               value: targetItems.sales,
             },
             {
               dataIndex: 'purchase',
               value: targetItems.purchase,
             },
           ]
           dispatch(QuotationDetailsActions.editFlatRiseElse({ tabType, tabId, targetIid, updatedValues }))
          }
        }
      });

      if (targetRow) {
        const productCode = targetItems.productCode
          originalValue.quotationDetails.flat.forEach((key) => {
            key.items.forEach((key2) => {
              if(key2.productCode==productCode && key2.i_id !== iId){
                let updatedValues = [
                  {
                    dataIndex: 'sales',
                    value: targetItems.sales,
                  },
                  {
                    dataIndex: 'purchase',
                    value: targetItems.purchase,
                  },
                  {
                    dataIndex: 'remarks',
                    value: key2.remarks,
                  },
                  {
                    dataIndex: 'standardCapacity',
                    value: key2.standardCapacity,
                  },
                  {
                    dataIndex: 'productUnit',
                    value: key2.productUnit,
                  },
                  {
                    dataIndex: 'quantity',
                    value: key2.quantity,
                  },
                  {
                    dataIndex: 'productName',
                    value: key2.productName,
                  },
                  {
                    dataIndex: 'productCode',
                    value: key2.productCode,
                  },
                  {
                    dataIndex: 'amountUsed',
                    value: key2.amountUsed,
                  },
                ]
                let tabType = key2.tabType ? key2.tabType : '平場'
                let tabId = key.tabId
                let targetIid = key2.i_id
                dispatch(QuotationDetailsActions.editFlatRiseElse({ tabType, tabId, targetIid, updatedValues }))
              }
            })
          })

          originalValue.quotationDetails.rise.forEach((key) => {
            key.items.forEach((key2) => {
              if(key2.productCode==productCode && key2.i_id !== iId){
                let updatedValues = [
                  {
                    dataIndex: 'sales',
                    value: targetItems.sales,
                  },
                  {
                    dataIndex: 'purchase',
                    value: targetItems.purchase,
                  },
                  {
                    dataIndex: 'remarks',
                    value: key2.remarks,
                  },
                  {
                    dataIndex: 'standardCapacity',
                    value: key2.standardCapacity,
                  },
                  {
                    dataIndex: 'productUnit',
                    value: key2.productUnit,
                  },
                  {
                    dataIndex: 'quantity',
                    value: key2.quantity,
                  },
                  {
                    dataIndex: 'productName',
                    value: key2.productName,
                  },
                  {
                    dataIndex: 'productCode',
                    value: key2.productCode,
                  },
                  {
                    dataIndex: 'amountUsed',
                    value: key2.amountUsed,
                  },
                ]
                let tabType = key2.tabType ? key2.tabType : '平場'
                let tabId = key.tabId
                let targetIid = key2.i_id
                dispatch(QuotationDetailsActions.editFlatRiseElse({ tabType, tabId, targetIid, updatedValues }))
              }
            })
          })
          
          originalValue.quotationDetails.else.forEach((key) => {
            key.items.forEach((key2) => {
              if(key2.productCode==productCode && key2.i_id !== iId){
                let updatedValues = [
                  {
                    dataIndex: 'sales',
                    value: targetItems.sales,
                  },
                  {
                    dataIndex: 'purchase',
                    value: targetItems.purchase,
                  },
                  {
                    dataIndex: 'remarks',
                    value: key2.remarks,
                  },
                  {
                    dataIndex: 'standardCapacity',
                    value: key2.standardCapacity,
                  },
                  {
                    dataIndex: 'productUnit',
                    value: key2.productUnit,
                  },
                  {
                    dataIndex: 'quantity',
                    value: key2.quantity,
                  },
                  {
                    dataIndex: 'productName',
                    value: key2.productName,
                  },
                  {
                    dataIndex: 'productCode',
                    value: key2.productCode,
                  },
                  {
                    dataIndex: 'amountUsed',
                    value: key2.amountUsed,
                  },
                ]
                let tabType = key2.tabType ? key2.tabType : '平場'
                let tabId = key.tabId
                let targetIid = key2.i_id
                dispatch(QuotationDetailsActions.editFlatRiseElse({ tabType, tabId, targetIid, updatedValues }))
              }
            })
          })
 
          const updatedValues : any = []
          let conditions : any = []
          updatedValues['sales']  = Number(lowestSales)
          updatedValues['purchase'] = Number(lowestPurchase)
          updatedValues['grossProfitMargin'] = Number(targetItems.grossProfitMargin)
          if(quotationIdText) {
            console.log("testend_s")
            const QuotationResult = QuotationDetailsSelectors.getallConstructionMethods(quotationIdText)
            QuotationResult.then(function(result_data) {
              {Object.values(result_data).map(key2 => (
                console.log("key2"),
                console.log(key2),
                conditions = [
                  {
                    id: 'productCode',
                    search_value: [productCode],
                    exact_match: true,
                  },
                  {
                    id: 'constIdText',
                    search_value: [key2.constIdText],
                    exact_match: true,
                  },
                ],
                dispatch(
                  QuotationDetailsActions.savelowestpriceFlatRiseElse({
                    productCode: productCode,
                    conditions: conditions,
                    lowestSales: lowestSales,
                    lowestPurchase: lowestPurchase,
                    updatedValues: updatedValues,
                  }),
                )
              ))}
            }, function(e) {
            // not called
            })
          }
        }
    }
    const deleteRow = (iId: string, isSaved: boolean): void => {
      dispatch(QuotationDetailsActions.deleteElseRow({ tabId, iId, isSaved }))
    }

    const result = dataSource.map((item, index) => {
      const resultForFlatAndRise = {
        ...item,
        key: (Number(index) + 1).toString(),
        lowestPrice: (
          <SearchLowestPriceButton
            SetLowestPrice={SetLowestPrice}
            tabId={tabId}
            Id={item.i_id}
            iId={item.i_id}
            tabType={item.tabType}
            productCode={item.productCode || ''}
            targetItem={item}
            customerCode={customerCode}
            siteName={siteName}
          />
        ),
      }
      if (item.tabType === 'その他') {
        return {
          ...resultForFlatAndRise,
          deleteRow: <DeleteRowButton iId={item.i_id} deleteRow={deleteRow} isSaved={item.isSaved} />,
        }
      } else {
        return resultForFlatAndRise
      }
    })
    return result
  }

  const flatTableItems = SetLowestPriceButtonAndDeleteButton(flatResources.items)
  const riseTableItems = SetLowestPriceButtonAndDeleteButton(riseResources.items)
  const elseTableItems = SetLowestPriceButtonAndDeleteButton(elseResources.items)

  const quotationIdText = useMemo(() => constructionMethod.items['quotationIdText'], [constructionMethod.items])

  const saveItems = useCallback(() => {
    console.log("通過")
    if (constructionMethod['items'] && constructionMethod['items']['i_id'] && constructionMethod['items']['constId']) {
      console.log("1通過")
      const flatItems = createConstMethodRelatedDsItems(calcFraction(flatResources), constructionMethod, constructionMethod['items']['constId'])
      const riseItems = createConstMethodRelatedDsItems(calcFraction(riseResources), constructionMethod, constructionMethod['items']['constId'])
      const elseItems = createConstMethodRelatedDsItems(calcFraction(elseResources), constructionMethod, constructionMethod['items']['constId'])
      console.log("2通過")
      console.log(elseResources)
      return dispatch(
        QuotationDetailsActions.saveFlatRiseElse({
          tabId,
          itemId: constructionMethod['items']['i_id'],
          constIdText: constructionMethod['items']['constId'],
          relatedDsItems: {
            [DATASTORE_ID.FLAT]: flatItems || [],
            [DATASTORE_ID.RISE]: riseItems || [],
            [DATASTORE_ID.ELSE]: elseItems || [],
          },
        }),
      )
    }
  }, [dispatch, constructionMethod, tabId, flatResources, riseResources, elseResources])

  const addRow = (selectedRow): void => {
    const modifiedSelectedRow = {
      ...selectedRow,
      standardPurchase: selectedRow.standardPurchasePrice,
    }
    dispatch(QuotationDetailsActions.addElseRow({ tabId, elseResources: modifiedSelectedRow }))

    console.log("addRow")
    console.log(modifiedSelectedRow)
    modifiedSelectedRow["amountUsed"]= 1
    modifiedSelectedRow["internalCapacity"]= 1
    modifiedSelectedRow["quantity"]= 0
    modifiedSelectedRow["sales"]= 0
    modifiedSelectedRow["constructionVolumeUnit"]='㎡'
    modifiedSelectedRow["purchase"]= selectedRow.standardPurchasePrice
    modifiedSelectedRow["isSaved"]= true
    modifiedSelectedRow["isEdited"]= true
    
    const insertValues = modifiedSelectedRow
//    const insertValues : any = []
    let datastoreId=DATASTORE_ID.ELSE

    insertValues['sales']=Number(modifiedSelectedRow.sales)
    insertValues['purchase']=Number(modifiedSelectedRow.purchase)
    insertValues['remarks']=modifiedSelectedRow.remarks
    insertValues['standardCapacity']=modifiedSelectedRow.standardCapacity
    insertValues['productUnit']=modifiedSelectedRow.productUnit
    insertValues['quantity']=Number(modifiedSelectedRow.quantity)
    insertValues['productName']=modifiedSelectedRow.productName
    insertValues['productCode']=modifiedSelectedRow.productCode
    insertValues['amountUsed']=Number(modifiedSelectedRow.amountUsed)
    insertValues['constructionVolumeUnit']=modifiedSelectedRow.constructionVolumeUnit
    insertValues['internalCapacity']=Number(modifiedSelectedRow.internalCapacity)
    insertValues['standardPurchase']=Number(selectedRow.standardPurchasePrice)
    insertValues['constIdText']=constIdText


//    dispatch(QuotationDetailsActions.saveAddItem({ datastoreId, insertValues }))

  }
 
const [searchedData, setSearchData] = useState<{ [k: string]: string }[]>()

var {
  flatSummaryResource,
  riseSummaryResource,
  elseSummaryResource,
  constMethodSummaryResource,
} = getSummariesResources(constructionMethod)

const getResourceByCurrentTab = (currentTab: TabLabels): SummaryResourcesItems => {

  if(constructionMethod.items.constructionType === 'その他'){
//      setCurrentTab('else')
    return elseSummaryResource
  }else if (currentTab === 'flat') {
    return flatSummaryResource
  } else if (currentTab === 'rise') {
    return riseSummaryResource
  } else {
    return elseSummaryResource
  }
}


//総計用

const summariesOfSales = useMemo(() => {
  const summariesOfSales: number[] = []
  constructionMethods.forEach((item) => {
    const flatValue = item.items.flatTabTotalSales ? item.items.flatTabTotalSales : 0
    const riseValue = item.items.riseTabTotalSales ? item.items.riseTabTotalSales : 0
    const elseValue = item.items.elseTabTotalSales ? item.items.elseTabTotalSales : 0
    summariesOfSales.push(Number(flatValue) + Number(riseValue) + Number(elseValue))
  })
  return summariesOfSales
}, [constructionMethod])

const summariesOfPurchase = useMemo(() => {
  const summariesOfPurchase: number[] = []
  constructionMethods.forEach((item) => {
    const flatValue = item.items.flatTabTotalPurchase ? item.items.flatTabTotalPurchase : 0
    const riseValue = item.items.riseTabTotalPurchase ? item.items.riseTabTotalPurchase : 0
    const elseValue = item.items.elseTabTotalPurchase ? item.items.elseTabTotalPurchase : 0
    summariesOfPurchase.push(Number(flatValue) + Number(riseValue) + Number(elseValue))
  })
  return summariesOfPurchase
}, [constructionMethod])

const { siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin } = useMemo(() => {
  return calculateTotalSummary(summariesOfSales, summariesOfPurchase)
}, [summariesOfSales, summariesOfPurchase])

const handleCalculateTotalSummary = useCallback((): void => {
  closeModal()
  if (quotation.i_id) {
    dispatch(
      QuotationDetailsActions.updateQuotationAndConstruction({
        datastoreId: DATASTORE_ID.QUOTATION,
        itemId: quotation.i_id,
        item: { siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin },
      }),
    )
  } else {
    // TODO: itemIdがない場合の実装
    console.log('見積DBのitemIdが取得できません')
  }
}, [siteTotalOfSales, siteTotalOfPurchase, siteTotalOfGrossProfitMargin, dispatch, quotation.i_id])

const totalValues = {
  siteTotalOfSales: quotation.siteTotalOfSales ? quotation.siteTotalOfSales : 0,
  siteTotalOfPurchase: quotation.siteTotalOfPurchase ? quotation.siteTotalOfPurchase : 0,
  siteTotalOfGrossProfitMargin: quotation.siteTotalOfGrossProfitMargin ? quotation.siteTotalOfGrossProfitMargin : 0,
}
const totalSummaryResources = getTotalSummaryResource(
  totalValues.siteTotalOfSales,
  totalValues.siteTotalOfPurchase,
  totalValues.siteTotalOfGrossProfitMargin,
)

const openModal = useCallback(() => {
  handleModalOpen(true)
}, [])

const closeModal = useCallback(() => {
  handleModalOpen(false)
}, [])

//総計ここまで
useEffect(() => {
  if (constIdText) {
    constructionMethods.forEach((item) => {
      var tabId = item.tabId
      var constIdText = item.items.constIdText
      dispatch(QuotationDetailsActions.getFlatRiseElse({ tabId, constIdText }))
    })
  }
}, [dispatch, tabId, constIdText])
  return (
    <Spin size="large" spinning={isConstuctionLoading}>
      <div>
      <style>{"table, th, td {border:1px solid black;}"}</style>
      <style>{".fontbold {font-Weight: bold;background-color:#deedf8;}"}</style>
      <style>{".fontred {color:red;}"}</style>
        <Row style={{ margin: '30px 0 20px', alignSelf : 'left' }}>
          <Col span={13} >
            <Summary resources={totalSummaryResources} />
          </Col>
          <Col style={{ margin: '3px 0 0 10px' }}>
          <Button
            type="primary"
            onClick={openModal}
            disabled={
              isDisabled || !constructionMethods[0] || !constructionMethods[0].items || !constructionMethods[0].items.i_id
            } // 工法を保存していなければdisable
          >
            計算
          </Button>
        </Col>
        </Row>
        <Row style={{ margin: '30px 0 20px' }}>
          <Col span={13}>
            <Summary resources={getResourceByCurrentTab(currentTab)} />
          </Col>
          <Col offset={1} span={10}>
            <Summary resources={constMethodSummaryResource} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs type="card" onChange={(activeKey) => setCurrentTab(activeKey as TabLabels)}>
              {constructionMethod.items.constructionType!=='その他' &&
              <TabPane tab="平場" key="flat">
                <Row>
                  <Col span={24} style={{ overflowX: 'auto' }}>
                    <EditableTable form={form} columns={flatAndRiseColumns} origindata={flatTableItems} tabId={tabId} />
                  </Col>
                </Row>
                <Row justify="end" style={{ marginTop: '10px' }}>
                  <Col>
                    <Button
                      type="primary"
                      style={{display:'none' }}
                      onClick={saveItems}
                      disabled={
                        isDisabled ||
                        !quotation.quotationId ||
                        !constructionMethod.items.i_id ||
                        (flatResources.items.length <= 1)
                      }
                    >
                      {flatResources.isSaved ? '保存' : '保存'}
                    </Button>
                  </Col>
                  <Col offset={1}>
                    <Button
                      type="primary"
                      onClick={handleCalculateSummaryResources}
                      disabled={
                        isDisabled ||
                        !quotation.quotationId ||
                        !constructionMethod.items.i_id ||
                        (flatResources.items.length <= 1)
                      }
                    >
                      保存する
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              }
              {constructionMethod.items.constructionType==='防水工事' &&
              <TabPane tab="立上り" key="rise">
                <Row>
                  <Col span={24} style={{ overflowX: 'auto' }}>
                    <EditableTable form={form} columns={flatAndRiseColumns} origindata={riseTableItems} tabId={tabId} />
                  </Col>
                </Row>
                <Row justify="end" style={{ marginTop: '10px' }}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={saveItems}
                      style={{display:'none' }}
                      disabled={
                        isDisabled ||
                        !quotation.quotationId ||
                        !constructionMethod.items.i_id ||
                        (flatResources.items.length <= 1 || riseResources.items.length <= 1)
                      }
                    >
                      {riseResources.isSaved ? '保存' : '保存'}
                    </Button>
                  </Col>
                  <Col offset={1}>
                    <Button
                      type="primary"
                      onClick={handleCalculateSummaryResources}
                      disabled={
                        isDisabled ||
                        !quotation.quotationId ||
                        !constructionMethod.items.i_id ||
                        (flatResources.items.length <= 1 || riseResources.items.length <= 1)
                      }
                    >
                      保存する
                    </Button>
                  </Col>
                </Row>
              </TabPane>
              }
              <TabPane tab="その他" key="else">
                <Row>
                  <Col span={24} style={{ overflowX: 'auto' }}>
                    <EditableTable form={form} columns={othersColumns} origindata={elseTableItems} tabId={tabId} />
                    <Button
                      icon={<PlusOutlined />}
                      onClick={(): void => setSearchModalVisibility(true)}
                      style={{ backgroundColor: 'rgb(70, 107, 169)', color: 'white' }}
                      disabled={isDisabled || !quotation.quotationId}
                    />
                  </Col>
                </Row>
                <Row justify="end" style={{ marginTop: '10px' }}>
                  <Col>
                    <Button
                      type="primary"
                      style={{display:'none' }}
                      onClick={saveItems}
                      disabled={
                        isDisabled ||
                        !quotation.quotationId ||
                        !constructionMethod.items.i_id ||
                        elseResources.items.length === 0
                      }
                    >
                      {elseResources.isSaved ? '保存' : '保存'}
                    </Button>
                  </Col>
                  <Col offset={1}>
                    <Button
                      type="primary"
                      onClick={handleCalculateSummaryResources}
                      disabled={
                        isDisabled ||
                        !quotation.quotationId ||
                        !constructionMethod.items.i_id
                      }
                    >
                      保存する
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <MerchandiseSearchContainer
          visible={searchModalVisibility}
          onCancel={(): void => setSearchModalVisibility(false)}
          setSearchData={setSearchData}
          setAddMerchandiseModalVisibility={setAddMerchandiseModalVisibility}
        />
        <AddMerchandiseModal
          visible={addMerchandiseModalVisibility}
          dataSource={searchedData}
          resetSearchData={setSearchData}
          addRow={addRow}
          elseResources={elseResources}
          onCancel={(): void => setAddMerchandiseModalVisibility(false)}
        />

      <Modal visible={isModalOpen} onOk={handleCalculateTotalSummary} onCancel={closeModal} centered>
        <Text strong>見積書全体の集計処理を行います</Text>
        <br />
        <br />
        <Text>事前に全工法の計算処理を行う必要があります。</Text>
        <br />
        <Text>見積書全体の集計処理を実行してよろしいですか。</Text>
      </Modal>

      </div>
    </Spin>
  )
}

export default ConstructionDetailTabs